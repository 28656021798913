<template>
    <div class="account__coupons-payments">
        <template v-if="rules.portfolio_coupons && (rules.portfolio_coupons.limit === 0 || rules.portfolio_coupons.limit >= portfolio.ordering)">
            <h2 class="mb-4">
                Выбор периода для анализа
                <Helper
                    id="popover-period"
                    helper="account_coupons_history_period"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <div class="account__filters d-flex mb-4">
                <div class="d-flex flex-wrap">
                    <b-button
                        v-for="item in periodFilters"
                        :key="item.name"
                        :variant="(item.name === currentPeriodFilter) ? 'success' : 'light'"
                        :class="['text-nowrap me-3 mb-2 shadow', (item.name === currentPeriodFilter) && 'active']"
                        :disabled="showLoaderSending"
                        pill
                        @click.stop="changePeriodFilter(item.name)"
                    >
                        {{ item.title }}
                    </b-button>
                </div>
            </div>
            <template v-if="!showLoaderSending">
                <template v-if="list.payments_month_income?.length">
                    <div class="account__coupons-block row mb-5">
                        <BlockInfo
                            title="Доход 1. Купоны за период"
                            valueLabel="Получено"
                            :value="list.received_coupon_amount"
                            valueToPrice
                            subvalueLabel="Не получено"
                            :subvalue="list.coupon_amount - list.received_coupon_amount"
                            subvalueToPrice
                            classMain="account__coupons-block-item"
                            helper="account_coupons_history"
                        />

                        <BlockInfo
                            title="Амортизации // Погашения"
                            valueLabel="Получено"
                            :value="list.received_amortization_amount"
                            valueToPrice
                            subvalueLabel="Не получено"
                            :subvalue="list.amortization_amount - list.received_amortization_amount"
                            subvalueToPrice
                            classMain="account__coupons-block-item"
                            helper="account_coupons_history_amortization_amount"
                        />

                        <BlockInfo
                            :title="portfolio.trust_comment || portfolio.comment"
                            :value="`${(portfolio.last_update) ? $helpers.formatDate(new Date(portfolio.last_update), 'DD.MM.YYYY') : '—'}`"
                            valueLabel="Дата последней загрузки / синхронизации:"
                            :subvalue="`${(portfolio.last_deal_date) ? $helpers.formatDate(new Date(portfolio.last_deal_date), 'DD.MM.YYYY') : '—'}`"
                            subvalueLabel="Дата последней загруженной сделки:"
                            reverse
                            rows
                            classMain="account__coupons-block-item"
                            classModifier="col-md-8 col-lg-6 col-xl-4 col-xxl-4 mb-4"
                            :classColorValue="checkLastUpdate ? 'text-danger' : ''"
                            :classColorSubvalue="checkLastDealDate ? 'text-danger' : ''"
                            classTitle="small"
                            classColor=""
                            helper="account_coupons_history_info"
                        />

                    </div>
                    <h2 class="mb-4">
                        График выплаты купонов и амортизации
                        <Helper
                            id="popover-account-coupons-history-chart"
                            helper="account_coupons_history_chart"
                            classModifier="m--dark"
                            sup
                        />
                    </h2>
                    <b-card class="shadow mb-5 py-4">
                        <highcharts
                            :id="`chart-coupons-${portfolioId}`"
                            :options="chartOptions || {}"
                        >
                        </highcharts>
                    </b-card>
                    <b-card class="account__coupons-block-table col-12 shadow mb-5">
                        <b-table-simple
                            hover
                            responsive
                        >
                            <b-thead>
                                <b-tr>
                                    <b-td class="border-bottom">Месяц</b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`th-${index}`"
                                    >
                                        <b-td class="border-bottom text-end text-nowrap">
                                            {{ new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' }) }}<br>
                                            {{ new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>Полученные купоны</b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-coupon_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap">
                                            {{ $helpers.toPrice(item.received_coupon_amount, { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr>
                                    <b-td>Полученные Амортизации / Погашения</b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-amortization_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap">
                                            {{ $helpers.toPrice(item.received_amortization_amount, { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr>
                                    <b-td>Неполученные купоны</b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-coupon_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap">
                                            {{ $helpers.toPrice(item.coupon_amount - item.received_coupon_amount, { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr>
                                    <b-td>Неполученные Амортизации / Погашения</b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-amortization_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap">
                                            {{ $helpers.toPrice(item.amortization_amount - item.received_amortization_amount, { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr class="table-success">
                                    <b-td class="border-bottom">
                                        Итого получено
                                        <!--Helper
                                            id="popover-account-coupons-history-total-amount"
                                            helper="account_coupons_history_total_amount"
                                            classModifier="m--dark"
                                        /-->
                                    </b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-total_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap border-bottom">
                                            {{ $helpers.toPrice(item.received_coupon_amount + item.received_amortization_amount, { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                                <b-tr class="table-danger">
                                    <b-td class="border-bottom">
                                        Итого не получено
                                        <!--Helper
                                            id="popover-account-coupons-history-total-amount"
                                            helper="account_coupons_history_total_amount"
                                            classModifier="m--dark"
                                        /-->
                                    </b-td>
                                    <template
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`td-total_amount-${index}`"
                                    >
                                        <b-td class="text-end text-nowrap border-bottom">
                                            {{ $helpers.toPrice((item.coupon_amount - item.received_coupon_amount) + (item.amortization_amount - item.received_amortization_amount), { pointer: ',' }) }}
                                        </b-td>
                                    </template>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-card>
                    <h2 class="mb-4">Календарь купонов, амортизаций, погашений</h2>
                    <b-card class="account__coupons-block-calendar col-12 shadow mb-5">
                        <ScheduleXCalendar
                            v-if="this.calendarApp"
                            :calendar-app="calendarApp"
                            class="sx"
                        >
                            <template #headerContentLeftPrepend>
                                <b-dropdown id="dropdown-calendar" variant="outline-primary" size="" class="navigate__select-button sx__today-button p-0">
                                    <template #button-content>
                                        {{ new Date(list.payments_month_income?.[currentFilterCalendar].date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(list.payments_month_income?.[currentFilterCalendar]?.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                    </template>
                                    <b-dropdown-item-button
                                        v-for="(item, index) in list.payments_month_income"
                                        :key="`filter-month-${item.date}`"
                                        buttonClass="navigate__select-item"
                                        :active="currentFilterCalendar === index ? true : false"
                                        @click="changeFilterCalendar(index)"
                                    >
                                        {{ new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </template>
                            <template #monthGridEvent="{ calendarEvent }">
                                <div
                                    class="sx__month-grid-event-custom"
                                    :style="`border-left: 4px solid var(--sx-color-${calendarEvent.calendarId}); color: var(--sx-color-on-${calendarEvent.calendarId}-container); background-color: var(--sx-color-${calendarEvent.calendarId}-container);`"
                                >
                                    <div class="sx__month-grid-event-custom-amount">
                                        {{ calendarEvent.amount > 0 ? '+' : '' }}{{ $helpers.toPrice(calendarEvent.amount, { pointer: ',', sign: calendarEvent.currency_symbol }) }}
                                        <span>{{ calendarEvent.payment_status }}</span>
                                    </div>
                                    <div class="sx__month-grid-event-custom-title">
                                        {{ calendarEvent.title }}
                                        <span>{{ calendarEvent.payment_type }}</span>
                                    </div>
                                </div>
                            </template>
                            <template #monthAgendaEvent="{ calendarEvent }">
                                <div
                                    class="sx__month-agenda-event-custom"
                                    :style="`border-left: 4px solid var(--sx-color-${calendarEvent.calendarId}); color: var(--sx-color-on-${calendarEvent.calendarId}-container); background-color: var(--sx-color-${calendarEvent.calendarId}-container);`"
                                >
                                    <div class="sx__month-agenda-event-custom-amount">
                                        {{ calendarEvent.amount > 0 ? '+' : '' }}{{ $helpers.toPrice(calendarEvent.amount, { pointer: ',', sign: calendarEvent.currency_symbol }) }}
                                        <span>{{ calendarEvent.payment_status }}</span>
                                    </div>
                                    <div class="sx__month-agenda-event-custom-title">
                                        {{ calendarEvent.title }}
                                        <span>{{ calendarEvent.payment_type }}</span>
                                    </div>
                                </div>
                            </template>
                        </ScheduleXCalendar>
                    </b-card>
                    <h2 class="mb-4">
                        Детализация купонов, амортизаций, погашений
                        <Helper
                            id="popover-account-coupons-payout-history-detail"
                            helper="account_coupons_payout_history_detail"
                            classModifier="m--dark"
                            sup
                        />
                    </h2>
                    <div class="account__coupons-block-buttons row mb-3">
                        <div class="col-12 col-xl d-flex flex-wrap mb-2">
                            <b-dropdown id="dropdown-onpage" variant="outline-primary" size="" class="navigate__select-button shadow">
                                <template #button-content>
                                    {{ new Date(list.payments_month_income?.[currentFilter]?.date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(list.payments_month_income?.[currentFilter]?.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                </template>
                                <b-dropdown-item-button
                                    v-for="(item, index) in list.payments_month_income"
                                    :key="`filter-month-${item.date}`"
                                    buttonClass="navigate__select-item"
                                    :active="currentFilter === index ? true : false"
                                    @click="changeFilter(index)"
                                >
                                    {{ new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </div>
                    <b-card
                        class="account__coupons-block-table col-12 shadow mb-5"
                        bodyClass="p-1"
                    >
                        <b-table
                            :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                            :items="prepareTableData()"
                            sticky-header
                            responsive
                            hover
                            class="table__data b-table-sticky-header m--table-flow"
                        >
                            <template #head()="data">
                                <TableHeaderSlot
                                    :data="data"
                                    :currentSorting="currentSorting"
                                    @changeSorting="changeSorting"
                                />
                            </template>
                            <template #cell(name)="data">
                                <router-link
                                    v-if="data.item.slug && portfolioType !== 'public'"
                                    :to="{ name: `${portfolioType}-assets`, params: { ticker: data.item.slug } }"
                                    class="d-block"
                                >
                                    <strong>{{ data.value }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.item.ticker }}</small>
                                </router-link>
                                <template
                                    v-else
                                >
                                    <strong>{{ data.item.name }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.value }}</small>
                                </template>
                            </template>
                            <template #cell(coupon_rate)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(payment_date)="data">
                                {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}
                            </template>
                            <template #cell(deal_date)="data">
                                <template v-if="data.item.payments.length">
                                    <div
                                        v-for="item in data.item.payments"
                                        :key="`quantity-${item.deal_id}`"
                                    >
                                        {{ $helpers.formatDate(new Date(item.deal_date), 'DD.MM.YYYY') }}
                                    </div>
                                </template>
                                <template v-else>—</template>
                            </template>
                            <template #cell(quantity)="data">
                                <template v-if="data.item.payments.length">
                                    <div
                                        v-for="item in data.item.payments"
                                        :key="`quantity-${item.deal_id}`"
                                    >
                                        {{ item.quantity }}
                                    </div>
                                </template>
                                <template v-else>—</template>
                            </template>
                            <template #cell(payment_type_detail)="data">
                                {{ data.value }}
                            </template>
                            <template #cell(payment_status_detail)="data">
                                <div :class="data.item.payment_status === 'not_received' ? 'text-danger' : data.item.payment_status === 'partially' ? 'text-warning' : ''">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(amount)="data">
                                <div :class="['text-end text-nowrap', data.item.payment_status === 'not_received' ? 'text-danger' : data.item.payment_status === 'partially' ? 'text-warning' : '']">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(received_amount)="data">
                                <template v-if="data.item.payments.length">
                                    <div
                                        v-for="item in data.item.payments"
                                        :key="`deal_date-${item.deal_id}`"
                                        class="text-end text-nowrap"
                                    >
                                        {{ item.amount ? $helpers.toPrice(item.amount, { pointer: ',' }) : '—' }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-end text-nowrap">—</div>
                                </template>
                            </template>
                        </b-table>
                        <BlockNavigateTable
                            :totalRows="currentTotalRows"
                            :currentPage="currentPage"
                            :currentPerPage="currentPerPage"
                            @changeNavigateTable="changeNavigateTable"
                        />
                        <b-overlay
                            :show="showLoaderSending"
                            variant="transparent"
                            no-wrap
                            spinner-variant="success"
                        />
                    </b-card>
                </template>
                <b-alert
                    v-else
                    show
                    variant="warning"
                >
                    За указанный период нет данных
                </b-alert>

            </template>
            <template v-else>
                <div class="app__loader">
                    <b-spinner
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
            <ModalCalendarEvent
                :event="currentEvent"
                eventType="history"
                :eventColotStyle="`color: var(--sx-color-on-${currentEvent.calendarId}-container) !important`"
                :show="showModalCalendarEvent"
                @hideModal="hideCalendarEvent"
            />
            <ModalCalendarDate
                :events="currentDateEvents"
                tableHeaderName="calendarHistoryItem"
                :show="showModalCalendarDate"
                @hideModal="hideCalendarDate"
            />
            <ModalCalendarDealAdd
                :event="currentEvent"
                :show="showModalCalendarDealAdd"
                :portfolioId="portfolioId"
                @hideModal="hideCalendarDealAdd"
            />

        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import { accountCouponHistory } from "@/settings/charts";

    import { app } from "@/services";
    import BlockInfo from "@/components/block-info";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    import Helper from "@/components/helper";
    import ModalCalendarEvent from "@/components/modals/modal-calendar-event";
    import ModalCalendarDate from "@/components/modals/modal-calendar-date";
    import ModalCalendarDealAdd from "@/components/modals/modal-calendar-deal-add";
    import { ScheduleXCalendar } from '@schedule-x/vue';
    import { createCalendar, viewMonthGrid, viewMonthAgenda } from '@schedule-x/calendar';
    import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls';
    import '@schedule-x/theme-default/dist/index.css';
    import BlockTariffLimited from "@/components/block-tariff-limited";

    export default {
        name: 'accountCouponsPayments',
        components: {
            BlockInfo,
            BlockNavigateTable,
            TableHeaderSlot,
            Helper,
            ModalCalendarEvent,
            ModalCalendarDate,
            ModalCalendarDealAdd,
            ScheduleXCalendar,
            BlockTariffLimited
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            checkLastUpdate() {
                if (!this.portfolio.last_update) return false;
                let date = new Date();
                date.setDate(date.getDate() - 1);
                date.setHours(0,0,0,0);
                let dateDeal = new Date(this.portfolio.last_update);
                dateDeal.setHours(0,0,0,0);
                return (dateDeal < date) ? true : false;
            },
            checkLastDealDate() {
                if (!this.portfolio.last_deal_date) return false;
                let date = new Date();
                date.setDate(date.getDate() - 1);
                date.setHours(0,0,0,0);
                let dateDeal = new Date(this.portfolio.last_deal_date);
                dateDeal.setHours(0,0,0,0);
                return (dateDeal < date) ? true : false;
            }
        },
        data() {
            return {
                periodFilters: [{
                    name: 'month3',
                    title: '3 месяца',
                    month_count: 3
                }, {
                    name: 'month6',
                    title: '6 месяцев',
                    month_count: 6
                }, {
                    name: 'year',
                    title: '1 год',
                    month_count: 12
                }, {
                    name: 'year2',
                    title: '2 года',
                    month_count: 24
                }],
                currentPeriodFilter: 'month3',
                currentFilter: 0,
                currentSorting: {
                    key: 'payment_date',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {},
                tableHeaderName: 'couponHistoryItem',
                tableHeader: fieldNames.couponHistoryItem,
                //fields: [],
                bonds: [],
                item: {},
                list: {},
                events: [],
                currentEvent: {},
                currentDateEvents: [],
                calendarDateMin: null,
                calendarDateMax: null,
                calendarOrdering: {
                    coupon_not_received: 1,
                    amortization_not_received: 2,
                    repayment_not_received: 3,
                    coupon_partially: 4,
                    amortization_partially: 5,
                    repayment_partially: 6,
                    coupon_full: 7,
                    amortization_full: 8,
                    repayment_full: 9
                },
                currentPerPage: 25,
                currentPage: 1,
                //calendarApp: null,
                currentFilterCalendar: 0,
                calendarControls: null,
                chartOptions: {},
                showModalCalendarEvent: false,
                showModalCalendarDate: false,
                showModalCalendarDealAdd: false,
                showLoaderSending: false
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
            Object.defineProperty(this, 'calendarApp', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);

            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                this.tableFilters.push(key);
            });
            this.getPortfolioHistoryCouponsIncome();
        },
        methods: {
            getPortfolioHistoryCouponsIncome() {
                this.chartOptions = Object.assign({}, accountCouponHistory);
                this.showLoaderSending = true;
                let params = {
                    month_count: this.periodFilters.find((item) => {
                        return item.name === this.currentPeriodFilter;
                    })?.month_count
                };
                app.getPortfolioHistoryCouponsIncome(this.portfolioId, params, this.portfolioType).then(async (res) => {
                    if (!res.error) {
                        this.list = res.result;
                        this.bonds = this.list.payments_month_income[this.currentFilter]?.month_instruments || [];

                        this.chartOptions.tooltip.valueSuffix = ` ${this.currencyCurrent.symbol}`;
                        this.chartOptions.yAxis.labels.format = this.currencyCurrent.symbol + ' {text}';
                        this.chartOptions.xAxis.categories = [];
                        this.chartOptions.series[0].name = this.chartOptions.series[0].nameTemplate + this.currencyCurrent.symbol;
                        this.chartOptions.series[1].name = this.chartOptions.series[1].nameTemplate + this.currencyCurrent.symbol;
                        this.chartOptions.series[2].name = this.chartOptions.series[2].nameTemplate + this.currencyCurrent.symbol;
                        this.chartOptions.series[3].name = this.chartOptions.series[3].nameTemplate + this.currencyCurrent.symbol;

                        this.chartOptions.series[0].data = [];
                        this.chartOptions.series[1].data = [];
                        this.chartOptions.series[2].data = [];
                        this.chartOptions.series[3].data = [];

                        this.list.payments_month_income.forEach(item => {
                            const date = `${new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' })} ${new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' })}`;
                            this.chartOptions.series[0].data.push([
                                date,
                                item.amortization_amount - item.received_amortization_amount,
                            ]);
                            this.chartOptions.series[1].data.push([
                                date,
                                item.coupon_amount - item.received_coupon_amount,
                            ]);
                            this.chartOptions.series[2].data.push([
                                date,
                                item.received_amortization_amount,
                            ]);
                            this.chartOptions.series[3].data.push([
                                date,
                                item.received_coupon_amount,
                            ]);
                            this.chartOptions.xAxis.categories.push(date);
                        });
                        this.events = [];
                        this.list.payments_month_income.forEach(item => {
                            Object.keys(item.month_payment_dates).map((key) => {
                                item.month_payment_dates[key].forEach((event, index) => {
                                    this.events.push({
                                        id: `${key}-${index}`,
                                        calendarId: `${event.payment_type}_${event.payment_status}`,
                                        ordering: this.calendarOrdering[`${event.payment_type}_${event.payment_status}`],
                                        title: event.name,
                                        isin: event.isin,
                                        instrument_id: event.instrument_id,
                                        slug: event.slug,
                                        rate: event.coupon_rate,
                                        start: key,
                                        end: key,
                                        record_date: event.record_date,
                                        payment_date: event.payment_date,
                                        payments: event.payments,
                                        amount: event.received_amount || event.amount,
                                        price: event.price,
                                        quantity: event.quantity,
                                        payment_type: event.payment_type_detail,
                                        payment_type_id: event.payment_type_id,
                                        payment_status: event.payment_status_detail,
                                        currency_symbol: event.currency_symbol,
                                        currency_id: event.currency_id,
                                    });
                                });
                            });
                        })
                        this.events = this.events.sort((a, b) => {
                            if (a.ordering > b.ordering)
                                return 1;
                            if (a.ordering < b.ordering)
                                return -1;
                            return 0;
                        });
                        //this.calendarDateMin = this.list.payments_month_income?.at(0)?.date ? this.list.payments_month_income.at(0).date + '-01' : null;
                        //this.calendarDateMax = this.list.payments_month_income?.at(-1)?.date ? this.list.payments_month_income.at(-1).date + '-01' : null;
                        this.calendarDateMin = this.list.from_date || null;
                        this.calendarDateMax = this.list.to_date || null;
                        this.currentFilterCalendar = this.list.payments_month_income.length - 1;
                        await this.generateCalendar();
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.bonds];
                if (list.length) {
                    console.log('prepareTableData');
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? (a[this.currentSorting.key] || '') : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? (b[this.currentSorting.key] || '') : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                //console.log(list);
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                /*
                list = list.filter(item => {
                    item._rowVariant = item.sold ? 'secondary text-muted' : '';
                    if (this.tableDataFilters.instrument_ticker) {
                        if (item.instrument_ticker.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1 || item.instrument_name.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                list = list.filter(item => {
                    if (this.tableDataFilters.sold) {
                        return true;
                    } else {
                        return (item.sold) ? false : true;
                    }
                });
                */
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            changePeriodFilter(index) {
                console.log(index);
                this.currentPeriodFilter = index;
                this.getPortfolioHistoryCouponsIncome();
                //this.bonds = this.list.payments_month_income[this.currentFilter].month_instruments;
            },
            changeFilter(index) {
                console.log(index);
                this.currentFilter = index;
                this.bonds = this.list.payments_month_income[this.currentFilter]?.month_instruments || [];
            },
            changeFilterCalendar(index) {
                console.log(index, this.list.payments_month_income[this.currentFilterCalendar].date);
                this.currentFilterCalendar = index;
                this.calendarControls.setDate(`${this.list.payments_month_income[this.currentFilterCalendar].date}-01`)
            },
            rowClass(item, type) {
                console.log(item);
                if (!item || type !== 'row') return;
                if (item.slod) return 'table-success';
            },
            onRowSelected(item) {
                console.log(item);
            },
            async generateCalendar() {
                this.calendarControls = createCalendarControlsPlugin();
                /*
                const scrollController = createScrollControllerPlugin({
                    initialScroll: '09:50'
                });
                const eventModal = createEventModalPlugin();
                const datePicker = createDatePicker({
                    listeners: {
                        onChange: (date) => {
                            console.log('date changed', date)
                        }
                    },
                });
                */
                let self = this;
                this.calendarApp = await createCalendar({
                    calendars: {
                        coupon_not_received: {
                            colorName: 'coupon_not_received',
                            lightColors: {
                                main: 'rgba(212, 107, 109, 1)',
                                container: 'rgba(212, 107, 109, 0.3)',
                                onContainer: 'rgba(212, 107, 109, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        amortization_not_received: {
                            colorName: 'amortization_not_received',
                            lightColors: {
                                main: 'rgba(212, 107, 109, 1)',
                                container: 'rgba(212, 107, 109, 0.2)',
                                onContainer: 'rgba(212, 107, 109, 1)',
                            },
                            darkColors: {
                                main: '#ffc0cc',
                                onContainer: '#ffdee6',
                                container: '#a24258',
                            },
                        },
                        repayment_not_received: {
                            colorName: 'repayment_not_received',
                            lightColors: {
                                main: 'rgba(212, 107, 109, 1)',
                                container: 'rgba(212, 107, 109, 0.05)',
                                onContainer: 'rgba(212, 107, 109, 1)',
                            },
                            darkColors: {
                                main: '#ffc0cc',
                                onContainer: '#ffdee6',
                                container: '#a24258',
                            },
                        },
                        coupon_partially: {
                            colorName: 'coupon_partially',
                            lightColors: {
                                main: 'rgba(215, 147, 59, 1)',
                                container: 'rgba(255, 187, 79, 0.3)',
                                onContainer: 'rgba(215, 147, 59, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        amortization_partially: {
                            colorName: 'amortization_partially',
                            lightColors: {
                                main: 'rgba(215, 147, 59, 1)',
                                container: 'rgba(255, 187, 79, 0.2)',
                                onContainer: 'rgba(215, 147, 59, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        repayment_partially: {
                            colorName: 'repayment_partially',
                            lightColors: {
                                main: 'rgba(215, 147, 59, 1)',
                                container: 'rgba(255, 187, 79, 0.05)',
                                onContainer: 'rgba(215, 147, 59, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        coupon_full: {
                            colorName: 'coupon_full',
                            lightColors: {
                                main: 'rgba(20, 108, 67, 1)',
                                container: 'rgba(20, 108, 67, 0.3)',
                                onContainer: 'rgba(20, 108, 67, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        amortization_full: {
                            colorName: 'amortization_full',
                            lightColors: {
                                main: 'rgba(20, 108, 67, 1)',
                                container: 'rgba(20, 108, 67, 0.2)',
                                onContainer: 'rgba(20, 108, 67, 1)',
                            },
                            darkColors: {
                                main: '#ffc0cc',
                                onContainer: '#ffdee6',
                                container: '#a24258',
                            },
                        },
                        repayment_full: {
                            colorName: 'repayment_full',
                            lightColors: {
                                main: 'rgba(20, 108, 67, 1)',
                                container: 'rgba(20, 108, 67, 0.05)',
                                onContainer: 'rgba(20, 108, 67, 1)',
                            },
                            darkColors: {
                                main: '#ffc0cc',
                                onContainer: '#ffdee6',
                                container: '#a24258',
                            },
                        },
                    },
                    //calendars: this.eventTypes,
                    locale: 'ru-RU',
                    selectedDate: this.calendarDateMax,
                    minDate: this.calendarDateMin,
                    maxDate: this.calendarDateMax,
                    monthGridOptions: {
                        nEventsPerDay: 2
                    },
                    views: [viewMonthGrid, viewMonthAgenda],
                    defaultView: viewMonthGrid.name,
                    events: this.events || [],
                    //plugins: [this.calendarControls, scrollController, eventModal],
                    plugins: [this.calendarControls],
                    /**
                    * Callbacks for events that occur in the calendar
                    * */
                    callbacks: {
                        /**
                         * Is called when:
                         * 1. Selecting a date in the date picker
                         * 2. Selecting a new view
                         * */
                        onRangeUpdate(range) {
                            console.log('new calendar range start date', range.start)
                            console.log('new calendar range end date', range.end)
                        },
                        /**
                         * Is called when an event is updated through drag and drop
                         * */
                        onEventUpdate(updatedEvent) {
                            console.log('onEventUpdate', updatedEvent)
                        },
                        /**
                        * Is called when an event is clicked
                        * */
                        onEventClick(calendarEvent) {
                            console.log('onEventClick', calendarEvent)
                            self.currentEvent = calendarEvent;
                            self.showCalendarEvent();
                        },
                        /**
                        * Is called when clicking a date in the month grid
                        * */
                        onClickDate(date) {
                            console.log('onClickDate', date) // e.g. 2024-01-01
                            self.currentDateEvents = self.events.filter(item => item.start === date);
                            console.log(self.currentDateEvents);
                            if (self.currentDateEvents.length) self.showCalendarDate();
                        },
                        /**
                        * Is called when clicking somewhere in the time grid of a week or day view
                        * */
                        onClickDateTime(dateTime) {
                            console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
                        },
                        /**
                        * Is called when clicking the "+ N events" button of a month grid-day
                        * */
                        onClickPlusEvents(date) {
                            console.log('onClickPlusEvents', date) // e.g. 2024-01-01
                            self.currentDateEvents = self.events.filter(item => item.start === date);
                            console.log(self.currentDateEvents);
                            if (self.currentDateEvents.length) self.showCalendarDate();
                        },
                        /**
                        * Is called when the selected date is updated
                        * */
                        onSelectedDateUpdate(date) {
                            console.log('onSelectedDateUpdate', date)
                            let idx = self.list.payments_month_income.map(item => { return item.date }).indexOf(self.$helpers.formatDate(new Date(date), 'YYYY-MM'))
                            if (idx !== -1) self.currentFilterCalendar = idx
                        }
                    },

                });
            },
            showCalendarEvent() {
                this.showModalCalendarEvent = true;
            },
            hideCalendarEvent(showDealAdd = false) {
                this.showModalCalendarEvent = false;
                if (showDealAdd) this.showCalendarDealAdd();
            },
            showCalendarDate() {
                this.showModalCalendarDate = true;
            },
            hideCalendarDate() {
                this.showModalCalendarDate = false;
            },
            showCalendarDealAdd() {
                this.showModalCalendarDealAdd = true;
            },
            hideCalendarDealAdd(refreshData = false) {
                this.showModalCalendarDealAdd = false;
                if (refreshData) this.getPortfolioHistoryCouponsIncome(true);
            },
        }
    };
</script>
