<template>
    <div class="account__settings-main account__edit">
        <div
            class="account__edit-form mb-4"
        >
            <b-form-group
                id="input-group-name"
                label="Название портфеля*"
                description="Отображается внутри системы"
                class="col-12"
            >
                <b-form-input
                    id="input-name"
                    v-model="formData.name"
                    type="text"
                    placeholder="Придумайте название портфеля"
                    size="sm"
                    required
                    class="shadow-sm m--no-pill"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-group-comment"
                label="Комментарий к портфелю"
                class="col-12"
            >
                <b-form-textarea
                    id="input-comment"
                    v-model="formData.comment"
                    placeholder="При необходимости введите ваш комментарий"
                    size="sm"
                    class="shadow-sm m--no-pill"
                ></b-form-textarea>
            </b-form-group>
            <input
                v-model="formData.id"
                type="hidden"
                name="id"
            >
            <div
                v-if="formErrors && formErrors.detail"
                class="col-12 mb-3"
            >
                <b-alert
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </div>
            <div
                class="col-12 mb-3"
            >
                <b-button
                    variant="outline-success mt-4"
                    class=""
                    pill
                    :disabled="showLoaderSending"
                    @click.prevent="updatePortfolio"
                >
                    Сохранить настройки
                </b-button>
            </div>
            <b-overlay
                :show="showLoaderSending"
                no-wrap
                spinner-variant="success"
            />
        </div>
        <div class="account__edit-form text-danger mb-2">
            <a
                href="#"
                class="text-danger"
                @click.prevent="showAccountDelete"
            ><strong>Удалить брокерский счет</strong></a>
            (все данные о портфеле изчезнут из сервиса "КАПИТАЛ")
        </div>
        <ModalAccountDelete
            :show="showModalAccountDelete"
            :portfolio="portfolio"
            @hideModal="hideAccountDelete"
        />
    </div>
</template>

<script>
    import { app } from "@/services";
    import ModalAccountDelete from "@/components/modals/modal-account-delete";
    export default {
        name: 'accountSettingsMain',
        emits: ['refreshPortfolioAll'],
        components: {
            ModalAccountDelete
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        data() {
            return {
                formData: {},
                formErrors: {},
                showModalAccountDelete: false,
                showLoaderSending: false,
            }
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = this.portfolio.name;
            this.formData = {
                id: this.portfolioId,
                name: this.portfolio.name,
                comment: this.portfolio.comment,
            };
            //this.getPortfolio();
        },
        methods: {
            getPortfolio() {
                if (this.formData.id) {
                    this.showLoaderSending = true;
                    let id = this.formData.id;
                    app.getPortfolio(id, this.portfolioType).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            this.formData = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                }
            },
            updatePortfolio() {
                this.formErrors = {};
                let params = this.formData;
                this.showLoaderSending = true;
                app.updatePortfolio(params, this.portfolioType).then(res => {
                    this.showLoaderSending = false;
                    this.formData = {
                        id: res.id,
                        name: res.name,
                        comment: res.comment,
                    };
                    this.$store.dispatch('refreshSidebarKey');
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response.data);
                        //this.formErrors = err.response.data.error;
                        //this.formErrors.token = 1;
                        this.formErrors.detail = err.response.data.detail;
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            showAccountDelete() {
                this.showModalAccountDelete = true;
            },
            hideAccountDelete(refresh) {
                this.showModalAccountDelete = false;
                if (refresh) {
                    this.$emit('refreshPortfolioAll');
                    this.$router.push({ name: 'accounts' });
                }
            },
        }
    };
</script>
