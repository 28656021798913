<template>
    <div class="app__sidebar sidebar">
        <router-link
            :to="{ name: 'accounts' }"
            class="sidebar__logo"
        />
        <div
            class="sidebar__menu"
        >
            <template
                v-for="(item, key) in menu"
                :key="key"
            >
                <router-link
                    v-if="!item.hidden && item.name"
                    :to="{ name: item.name }"
                    custom
                    v-slot="{ href, navigate, isActive }"
                >
                    <div :class="['sidebar__menu-item', isActive && 'is-active', item.disabled && 'is-disabled', item.icon ? `m--icon-${item.icon}` : '']">
                        <a
                            :href="href"
                            class="sidebar__menu-item-link"
                            @click="navigate"
                        >
                            {{ item.title }}
                        </a>
                        <b-button
                            v-if="item.items"
                            variant="link"
                            v-b-toggle="`item-${item.name}`"
                            class="sidebar__menu-item-arrow"
                        >
                            <b-icon-chevron-down />
                        </b-button>
                        <b-collapse
                            v-if="item.items"
                            :id="`item-${item.name}`"
                            class="sidebar__menu-item-block"
                            :visible="isActive"
                        >
                            <template
                                v-for="(sitem, key) in item.items"
                                :key="key"
                            >
                                <router-link
                                    v-if="!sitem.hidden"
                                    :to="{ name: sitem.name }"
                                    custom
                                    v-slot="{ href, navigate, isActive }"
                                >
                                    <div :class="['sidebar__menu-subitem', isActive && 'is-active', sitem.disabled && 'is-disabled', sitem.dynamic && 'm--collapse']">
                                        <a
                                            :href="href"
                                            class="sidebar__menu-subitem-link"
                                            @click="navigate"
                                        >
                                            {{ sitem.title }}
                                        </a>
                                        <template
                                            v-if="sitem.dynamic"
                                        >
                                            <b-button
                                                variant="link"
                                                v-b-toggle="`item-${sitem.name}`"
                                                class="sidebar__menu-subitem-arrow"
                                            >
                                                <b-icon-chevron-down />
                                            </b-button>
                                            <b-collapse
                                                :id="`item-${sitem.name}`"
                                                :key="`menu-${sitem.name}-${sidebarKey}`"
                                                class="sidebar__menu-item-block"
                                                :visible="isActive"
                                                @show="getItems(sitem)"
                                            >
                                                <template
                                                    v-for="(item, key) in sitem.items"
                                                    :key="`item-${sitem.name}-${key}`"
                                                >
                                                    <router-link
                                                        :to="{ name: sitem.childName, params: { portfolioId: item.id } }"
                                                        custom
                                                        v-slot="{ href, navigate, isActive }"
                                                    >
                                                        <div
                                                            class="sidebar__menu-subitem"
                                                            :class="[isActive && 'is-active']"
                                                        >
                                                            <a
                                                                :href="href"
                                                                class="sidebar__menu-subitem-link"
                                                                @click="navigate"
                                                            >
                                                                {{ item.name }}
                                                            </a>
                                                        </div>
                                                    </router-link>
                                                </template>
                                                <template v-if="!sitem.items?.length && !showLoaderSending">
                                                    <span class="text-muted small">{{ sitem.empty }}</span>
                                                </template>
                                                <b-overlay
                                                    :show="showLoaderSending"
                                                    spinner-variant="success"
                                                    spinner-small
                                                    no-center
                                                    no-wrap
                                                    opacity=0
                                                />
                                            </b-collapse>
                                        </template>
                                    </div>
                                </router-link>
                            </template>
                        </b-collapse>
                    </div>
                </router-link>
                <div
                    v-else-if="!item.hidden && item.href"
                    :class="['sidebar__menu-item', item.disabled && 'is-disabled', item.icon ? `m--icon-${item.icon}` : '']"
                >
                    <a
                        :href="item.href"
                        class="sidebar__menu-item-link"
                        target="_blank"
                    >
                        {{ item.title }}
                    </a>
                </div>
            </template>
        </div>
        <div  class="sidebar__banner m--no-border d-none d-xl-block">
            <a
                href="https://proakcii.finprogress.ru/"
                target="_blank"
                class="sidebar__banner-inner"
            >
                <img src="../assets/img/pics/banner-kapital.png" alt="" class="sidebar__banner-picture" />
            </a>
        </div>
        <div v-if="showTemplate" class="sidebar__banner d-none d-xl-block">
            <div class="sidebar__banner-inner">
                <div class="sidebar__banner-title">
                    Курс «Инвестор»
                </div>
                <div class="sidebar__banner-content text-center">
                    Как легко инвестировать, получать прибыль и правильно распределять деньги? Именно этому мы вас научим на курсе
                </div>
                <a
                    href="#"
                    class="sidebar__banner-button btn btn-outline-success rounded-pill"
                >
                    Подробнее
                </a>
            </div>
        </div>
        <div class="sidebar__date">
            Обновления котировок: <span>{{ $helpers.formatDate(updateDate, 'DD.MM.YYYY HH:mm') }}</span>
        </div>
        <div class="sidebar__bottom">
            <div class="sidebar__bottom-info mb-3">
                <b-icon-person class="icon" />
                <div>
                    {{ user.first_name }} (ID: {{ user.id }})
                    <div :class="['sidebar__bottom-info-tariff', user.tariff?.status === 'free' ? 'text-danger' : 'text-success' ]">
                        Тариф: <router-link :to="{ name: 'profile-tariffs' }">{{ user.tariff?.name }}</router-link>
                        <span
                            v-if="user.tariff?.status !== 'free'"
                            :class="[diffDays <= 7 ? 'text-danger' : 'text-muted']"
                        >
                            ({{ $helpers.stringForNumber(diffDays, ['день', 'дня', 'дней']) }})
                        </span>
                        <div
                            v-if="user.tariff?.status === 'trial'"
                            class="text-muted"
                        >
                            (пробный период)
                        </div>
                    </div>
                </div>
                <Helper
                    id="side-menu-help"
                    helper="side_menu_help"
                    placement="auto"
                    sup
                />
            </div>
            <div v-if="user.tariff?.status === 'free'" class="mb-3">
                <b-button
                    variant="primary"
                    pill
                    class="w-100"
                    @click.stop="next('profile-tariffs')"
                >
                    Оплатить
                </b-button>
            </div>
            <div class="sidebar__bottom-menu">
                <router-link
                    :to="{ name: 'profile' }"
                    custom
                    v-slot="{ isActive }"
                >
                    <b-button
                        variant="link-success"
                        :class="['btn-icon py-1', isActive && 'active']"
                        pill
                        @click.stop="next('profile')"
                    >
                        <b-icon-gear class="icon m--middle" />
                    </b-button>
                </router-link>
                <router-link
                    :to="{ name: 'messages' }"
                    custom
                    v-slot="{ isActive }"
                >
                    <b-button
                        variant="link-success"
                        :class="['btn-icon py-1', user.notifications_count && 'danger', isActive && 'active']"
                        pill
                        @click.stop="next('messages')"
                    >
                        <b-icon-bell-fill
                            v-if="user.notifications_count"
                            class="icon m--middle"
                        />
                        <b-icon-bell
                            v-else
                            class="icon m--middle"
                        />
                    </b-button>
                </router-link>
                <router-link
                    :to="{ name: 'support' }"
                    custom
                    v-slot="{ isActive }"
                >
                    <b-button
                        variant="link-success"
                        :class="['btn-icon py-1', isActive && 'active']"
                        pill
                        @click.stop="next('support')"
                    >
                        <b-icon-question
                            v-if="isActive"
                            class="icon m--middle"
                        />
                        <b-icon-question-circle
                            v-else
                            class="icon m--middle"
                        />
                    </b-button>
                </router-link>
                <b-button
                    variant="link-success"
                    class="btn-icon py-1"
                    pill
                    @click.stop="logout"
                >
                    <b-icon-box-arrow-right class="icon m--middle" />
                </b-button>
            </div>
        </div>
        <ModalAlert
            v-if="user.tariff"
            :show="showModalAlert"
            @hideModal="hideAlert"
        />
    </div>
</template>

<script>
    import { app } from '@/services';
    import { sidebarMenu } from '@/settings';
    import Helper from "@/components/helper";
    import ModalAlert from '@/components/modals/modal-alert';
    export default {
        components: {
            Helper,
            ModalAlert
        },
        data() {
            return {
                showTemplate: false,
                menu: sidebarMenu,
                timer: null,
                updateDate: new Date(),
                showLoaderSending: false,
                showModalAlert: false,
            };
        },
        computed: {
            sidebarKey() {
                return this.$store.state.sidebarKey;
            },
            diffDays() {
                let currDate = new Date();
                var endSubDate = new Date(this.$store.state.user.tariff?.subscription_end);
                var timeDiff = endSubDate.getTime() - currDate.getTime();
                return timeDiff > 0 ? Math.ceil(timeDiff / (1000 * 3600 * 24)) : 0;
            },
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            user() {
                return this.$store.state.user;
            }
        },
        created() {
            this.startTimer();
            if (this.$store.state.alert) this.showModalAlert = true;
        },
        unmounted() {
            this.stopTimer();
        },
        methods: {
            getItems(item) {
                let params = {};
                this.showLoaderSending = true;
                if (item.name === 'accounts') {
                    app.getPortfolioMenuList(params).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            item.items = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                            item.items = [];
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                        item.items = [];
                    });
                }
                if (item.name === 'accounts-demo') {
                    app.getPortfolioDemoMenuList(params).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            item.items = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                            item.items = [];
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                        item.items = [];
                    });
                }
            },
            showAlert() {
                this.showModalAlert = true;
            },
            hideAlert() {
                this.showModalAlert = false;
            },
            next(name) {
                this.$router.push({ name: name });
            },
            logout() {
                this.$store.dispatch('clearUser');
                this.$store.dispatch('clearToken');
                if (process.env.NODE_ENV !== 'development') {
                    window.location.href = '//finprogress.pro';
                } else {
                    this.$router.push({ name: 'home' });
                }
            },
            startTimer() {
                this.timer = setInterval(() => {
                    this.updateDate = new Date();
                }, 1000 * 60 * 20);
            },
            stopTimer() {
                clearTimeout(this.timer);
            },
        }
    };
</script>
