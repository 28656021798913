<template>
    <div class="account__analytic">
        <template v-if="!portfolio.hidden">
            <div class="col-12 mb-4">
                <b-nav tabs class="m--dark">
                    <template
                        v-for="item in menu"
                        :key="item.name"
                    >
                        <li class="nav-item">
                            <b-button
                                v-if="item.show"
                                :active="item.name === $route.name"
                                :disabled="item.disabled"
                                class="nav-link"
                                @click.stop="changeTabMenuItem(item)"
                            >
                                {{ item.title }}
                            </b-button>
                        </li>
                    </template>
                </b-nav>
            </div>
            <routerView
                :portfolioId="portfolioId"
                :portfolio="portfolio"
                :currentFilter="currentFilter"
                :portfolioType="portfolioType"
                :routeSuffix="routeSuffix"
                @refreshPortfolio="refreshPortfolio"
                @refreshPortfolioAll="refreshPortfolioAll"
            />
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
</template>

<script>
    import { app } from "@/services";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import ModalPage from "@/components/modals/modal-page";
    export default {
        name: 'account',
        emits: ['refreshPortfolioAll'],
        components: {
            BlockTariffLimited,
            ModalPage
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            currentFilter: {
                type: Boolean,
                default() { return false; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        watch: {
            'currencyCurrent': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (oldVal === undefined) return;
                    this.getPortfolio();
                }
            },
            portfolioId: {
                immediate: true,
                handler() {
                    //this.getPortfolio();
                    this.currentTabMenuItem = this.menu.filter(item => { return item.name === this.$route.name });
                }
            },
        },
        data() {
            return {
                lists: [],
                currentPerPage: 50,
                currentPage: 1,
                menu: [{
                    name: `${this.portfolioType}-analytic-sale-plan${this.routeSuffix}`,
                    title: 'План продажи',
                    icon: null,
                    show: true,
                }, {
                    name: `${this.portfolioType}-analytic-buy-plan${this.routeSuffix}`,
                    title: 'План покупки',
                    icon: null,
                    show: true,
                }, {
                    name: `${this.portfolioType}-analytic-results${this.routeSuffix}`,
                    title: 'Результаты',
                    icon: null,
                    show: true,
                }],
                currentTabMenuItem: {},
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        mounted() {
            //this.getPortfolioStrategies();
        },
        methods: {
            getPortfolioStrategies() {
                let params = {
                    id: this.portfolioId,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                };
                this.showLoaderSending = true;
                app.getPortfolioStrategies(params).then(res => {
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        this.lists = res.results;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            refreshPortfolio() {
                this.$emit('refreshPortfolio');
            },
            refreshPortfolioAll() {
                this.$emit('refreshPortfolioAll');
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            changeTabMenuItem(item) {
                this.currentTabMenuItem = item;
                if (item.name) this.next(item.name);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
