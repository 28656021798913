<template>
    <b-modal
        id="modal-strategy-add"
        v-model="showModal"
        size="md"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="strategyId ? 'Редактировать расчет' : 'Новый расчет'"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <template v-if="show">
                <b-alert
                    class="mb-3"
                    variant="success"
                    :modelValue="dismissCountDown"
                    @dismiss-count-down="countDownChanged"
                >
                    {{ strategyId ? 'Расчет успешно изменен' : 'Расчет успешно добавлен' }}
                </b-alert>
                <b-form
                    v-if="!dismissCountDown"
                    @submit="onSubmitHandler"
                    class="strategy__group-form row"
                >
                    <b-form-group
                        id="input-group-name"
                        label="Название расчета"
                        class="col-12"
                    >
                        <b-form-input
                            id="input-name"
                            v-model="formData.name"
                            type="text"
                            placeholder="Введите название расчета"
                            size="sm"
                            required
                            class="shadow-sm m--no-pill"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="input-group-currency"
                        label="Валюта списка инструментов"
                        class="col-12"
                    >
                        <Multiselect
                            id="input-currency"
                            v-model="formData.currency"
                            :options="currencyList"
                            mode="single"
                            :canClear="false"
                            :close-on-select="true"
                            class="shadow-sm m--no-pill"
                            placeholder=""
                            required
                            :disabled="strategyId ? true : false"
                        />
                    </b-form-group>
                    <div class="col-12">
                        <b-alert
                            variant="danger"
                            :show="addError"
                        >
                            {{ addError }}
                        </b-alert>
                    </div>
                    <div class="col-12 pt-3">
                        <b-button
                            variant="outline-success"
                            class="strategy__group-form-button mb-3 me-auto"
                            type="submit"
                            pill
                            :disabled="showLoaderSending"
                        >
                            Сохранить
                        </b-button>
                    </div>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
    </b-modal>
</template>

<script>
    import { app } from '@/services';
    import Multiselect from '@vueform/multiselect';
    export default {
        components: {
            Multiselect
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.refreshData = false;
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            strategyId: {
                type: [Number, String],
                default() { return null; }
            },
            strategy: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                currencyList: [],
                instrumentTypeList: [],
                formData: {
                    portfolioId: this.portfolioId,
                },
                showModal: this.show,
                showLoaderSending: false,
                dismissCountDown: 0,
                addSuccess: false,
                addError: null,
                refreshData: false,
            };
        },
        created() {
            if (this.strategyId) {
                this.formData = Object.assign({}, this.strategy);
            }
        },
        mounted() {
            this.getCurrencyList();
        },
        methods: {
            getCurrencyList() {
                this.showLoaderSending = true;
                app.getBondCurrencies().then(res => {
                    if (!res.error) this.currencyList = res.map(item => { return { label: item.name, value: item.key } });
                    if (!this.strategyId) this.formData.currency = this.currencyList[0]?.value;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.currencyList = [];
                });
            },
            onSubmitHandler() {
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let params = Object.assign({}, this.formData);
                if (this.strategyId) {
                    params.id = this.strategyId;
                    params.portfolioId = this.portfolioId;
                    console.log(params)
                    app.updatePortfolioStrategy(params).then(() => {
                        this.dismissCountDown = 3;
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                } else {
                    app.addPortfolioStrategy(params).then(() => {
                        this.dismissCountDown = 3;
                        this.refreshData = true;
                        this.addSuccess = true;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        this.addError = err.detail || err.message;
                        this.showLoaderSending = false;
                        console.error(err);
                    });
                }
            },
            countDownChanged(dismissCountDown) {
                console.log('dismissCountDown', dismissCountDown);
                if (dismissCountDown === 0) {
                    this.$nextTick(() => {
                        this.showModal = false;
                    });
                }
            },
            hideModal() {
                this.dismissCountDown = 0;
                this.addSuccess = false;
                this.addError = null;
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
