<template>
    <b-dropdown
        :id="`action-${strategy.id}`"
        class="ms-auto"
        dropleft
        variant="link-primary"
        no-caret
    >
        <template #button-content>
            <b-icon-gear />
        </template>
        <b-dropdown-item-button
            @click.prevent="showAddStrategy"
        >
            Переименовать стратегию
        </b-dropdown-item-button>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showDeleteStrategy"
        >
            Удалить стратегию
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalStrategyAdd
        v-if="showModalAddStrategy"
        :show="showModalAddStrategy"
        :portfolioId="portfolioId"
        :strategyId="strategy.id"
        :strategy="strategy"
        @hideModal="hideAddStrategy"
    />
    <ModalStrategyDelete
        v-if="showModalDeleteStrategy"
        :show="showModalDeleteStrategy"
        :portfolioId="portfolioId"
        :strategyId="strategy.id"
        :strategy="strategy"
        @hideModal="hideDeleteStrategy"
    />
</template>

<script>
    import ModalStrategyAdd from "@/components/modals/modal-strategy-add";
    import ModalStrategyDelete from "@/components/modals/modal-strategy-delete";
    export default {
        emits: ['getPortfolioStrategies'],
        components: {
            ModalStrategyAdd,
            ModalStrategyDelete,
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            strategy: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModalAddStrategy: false,
                showModalDeleteStrategy: false,
            }
        },
        mounted() {
        },
        methods: {
            showAddStrategy() {
                this.showModalAddStrategy = true;
            },
            hideAddStrategy(refreshData = false) {
                this.showModalAddStrategy = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getPortfolioStrategies');
                    })
                }
            },
            showDeleteStrategy() {
                this.showModalDeleteStrategy = true;
            },
            hideDeleteStrategy(refreshData = false) {
                this.showModalDeleteStrategy = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.$emit('getPortfolioStrategies');
                    })
                }
            },
        }
    };
</script>
