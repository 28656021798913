<template>
    <b-modal
        id="modal-filter-table-cols"
        v-model="showModal"
        size="lg"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Настройки отображения блоков метрики"
        centered
        hide-footer
        scrollable
        no-stacking
        @hidden="hideModal"
    >
        <div class="modal__content">
            <div
                class="row"
            >
                <div
                    :key="`filter-all`"
                    class="col-12 mb-1"
                >
                    <b-form-checkbox
                        v-model="filterAll"
                        name="filter"
                        switch
                        @change="onChangeAll()"
                    >
                        Все колонки
                    </b-form-checkbox>
                </div>
                <div class="col-12 column-count-1">
                    <template
                        v-for="(item, key) in items"
                        :key="`filter-${key}`"
                    >
                        <div
                            v-if="item.name && !item.hide"
                            class="mb-1"
                        >
                            <b-form-checkbox
                                v-model="filter"
                                name="filter"
                                :value="key"
                                :disabled="item.disabled || (this.filter.length === this.minCols && filter.indexOf(key) !== -1)"
                                switch
                                @change="onChange()"
                            >
                                {{ item.name }}{{ item.nameSuffix ? nameSuffix(item.nameSuffix, item) : '' }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </b-modal>

</template>

<script>
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            items: {
                type: Object,
                default() { return {}; }
            },
            groups: {
                type: Array,
                default() { return []; }
            },
            itemsFiltered: {
                type: Array,
                default() { return []; }
            },
            minCols: {
                type: Number,
                default() { return 1; }
            },
        },
        data() {
            return {
                filter: [],
                filterAll: false,
                showModal: this.show,
            };
        },
        created() {
        },
        mounted() {
            this.$nextTick(() => {
                this.filter = this.itemsFiltered;
                this.filterAll = (Object.keys(this.items).filter(key => { return !this.items[key].hide }).length === this.filter.length) ? true : false;
            });
        },
        methods: {
            onChangeAll() {
                this.filter = [];
                if (this.filterAll) {
                    Object.keys(this.items).filter(key => { return !this.items[key].hide }).map((key) => {
                        this.filter.push(key);
                    });
                } else {
                    this.filter = this.itemsFiltered;
                    this.filter = this.filter.splice(0, this.minCols);
                    Object.keys(this.items).filter(key => { return !this.items[key].hide }).map((key) => {
                        if (key === 'action' && this.filter.indexOf('action') === -1) this.filter.push(key);
                    });
                }
                this.$emit('changeFilter', this.filter);
            },
            onChange() {
                this.filterAll = (Object.keys(this.items).filter(key => { return !this.items[key].hide }).length === this.filter.length) ? true : false;
                this.$emit('changeFilter', this.filter);
            },
            hideModal() {
                this.$emit('hideModal', this.filter);
            },
            nameSuffix(fnName, params={}) {
                if (typeof this[fnName] === 'function') {
                    return this[fnName](params);
                }
            },
            currencySign() {
                //console.log('currencySign');
                let currency = this.$store.state.currencyCurrent;
                return `, ${currency.symbol}`;
            },
            currencyAssetSign(params) {
                //console.log('currencySign');
                let currency = params.currency || {};
                //console.log(currency);
                return `, ${currency.symbol}`;
            },
            currencyIsoSign() {
                //console.log('currencySign');
                let currency = this.$store.state.currencyCurrent;
                return `, (${currency.iso_name}, ${currency.symbol})`;
            },
        }
    };
</script>
