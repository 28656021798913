<template>
    <b-modal
        id="modal-deal-add"
        v-model="showModal"
        size="lg"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        title-class="w-100 align-items-center d-flex"
        content-class="modal__block"
        :title="dealId ? 'Редактирование сделки' : 'Добавление сделки'"
        centered
        hide-footer
        scrollable
        no-stacking
        @hidden="hideModal()"
    >
        <template #title>
            {{ dealId ? 'Редактирование сделки' : 'Добавление сделки' }}
            <HelperModal
                id="modal-deal-add-helper"
                helper="modal_deal_add_helper"
                placement="auto"
            />
        </template>
        <div class="modal__content">
            <template v-if="rules.deal_add">
                <div class="row">
                    <FormDealAdd
                        v-if="show"
                        :dealId="dealId"
                        :event="event"
                        :portfolioId="portfolioId"
                        :portfolioType="portfolioType"
                        :inModal="true"
                        @hideModal="hideModal"
                    />
                </div>
            </template>
            <template v-else>
                <BlockTariffLimited
                    @hideModal="hideModal"
                />
            </template>
        </div>
    </b-modal>
</template>

<script>
    import FormDealAdd from "@/components/form-deal-add";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import HelperModal from "@/components/helper-modal";
    export default {
        components: {
            FormDealAdd,
            BlockTariffLimited,
            HelperModal
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            dealId() {
                return this.event.payments?.[0]?.deal_id || null;
            }
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            event: {
                type: Object,
                default() { return {}; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                showModal: this.show,
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            hideModal(refreshData = false) {
                this.$emit('hideModal', refreshData);
            },
        }
    };
</script>
