<template>
    <template v-if="!showLoaderSending">
        <div
            v-if="!$route.meta.hideTitle"
            class="app__block-title m--help"
        >
            <h1 class="mb-3">Брокерский счет «{{ portfolioType === 'account' ? portfolio.name : (portfolio.trust_name || portfolio.name) }}»</h1>
            <router-link
                :to="{ name: 'page', params: { slug: 'help_screener' }}"
                custom
                v-slot="{ href }"
            >
                <a
                    :href="href"
                    class="app__block-help"
                    @click.prevent="showPage('help_account')"
                >
                    <b-icon-question-circle-fill />
                </a>
            </router-link>
            <template v-if="portfolioType === 'trust'">
                <div class="account__trust mb-2">
                    <div class="account__trust-header">
                        <span class="account__trust-access text-success text-uppercase">Доверительное управление</span>
                        <span class="account__trust-user">{{ portfolio.user_public_name }} (ID: {{ portfolio.user_id }})</span>
                    </div>
                </div>
            </template>
            <template v-else-if="portfolioType === 'public'">
                <div class="account__trust mb-4 pb-4">
                    <div class="account__trust-header">
                        <span class="account__trust-access text-success text-uppercase">Публичный брокерский счет</span>
                        <span class="account__trust-user">{{ portfolio.user_public_name }} (ID: {{ portfolio.user_id }})</span>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="account__trust mb-2">
                    {{ portfolio.broker_name }}
                    <span
                        v-if="portfolio.broker_key === 'tinkoff'"
                        class="ps-3"
                    >
                        Последняя синхронизация: {{ portfolio.last_parsed ? $helpers.formatDate(new Date(portfolio.last_parsed), 'DD.MM.YY HH:mm') : '—' }}
                    </span>
                </div>
            </template>
        </div>
        <template v-if="!portfolio.hidden">
            <div
                v-if="!$route.meta.hideButtons"
                class="account__buttons row pt-4 mb-3"
            >
                <div class="col-12 col-xl d-flex flex-wrap mb-2">
                    <template
                        v-for="item in menu"
                        :key="item.name"
                    >
                        <b-button
                            v-if="item.show"
                            :variant="(item.name === currentTabMenuItem.name) ? 'outline-primary' : 'outline-primary'"
                            :active="item.name === currentTabMenuItem.name"
                            :disabled="item.disabled"
                            class="account__buttons-item me-3 mb-2"
                            size=""
                            pill
                            @click.stop="changeTabMenuItem(item)"
                        >
                            {{ item.title }}
                        </b-button>
                    </template>
                </div>
                <div class="col-auto d-flex ms-auto mb-2">
                    <b-card
                        class="text-nowrap ms-3 mb-auto shadow"
                        bodyClass="py-0 pe-2 d-flex align-items-center"
                    >
                        <b-form-checkbox variant="success" size="sm" v-model="currentFilter" :value="true" name="check-button" switch>
                            Все валюты
                        </b-form-checkbox>
                    </b-card>
                    <BlockAccountActions
                        :portfolioId="portfolioId"
                        :portfolio="portfolio"
                        :portfolioType="portfolioType"
                        :routeSuffix="routeSuffix"
                        @refreshPortfolio="refreshPortfolio"
                    />
                </div>
            </div>
            <b-card
                v-if="showAlertDev"
                class="accounts__item-alert mb-4 shadow"
            >
                <b-icon-exclamation-triangle class="h2 text-danger mb-0 me-1" /> <strong class="text-danger">Внимание!</strong> Раздел «Брокерские счета» находится в процессе активной разработки и тестирования. Информация о БС может быть неточной.
            </b-card>
            <template v-if="portfolioType === 'public'">
                <AccountInfo
                    :portfolioId="portfolioId"
                    :portfolio="portfolio"
                    :currentFilter="currentFilter"
                    :portfolioType="portfolioType"
                    @refreshPortfolio="refreshPortfolio"
                    @refreshPortfolioAll="refreshPortfolioAll"
                />
            </template>
            <template v-else>
                <routerView
                    :portfolioId="portfolioId"
                    :portfolio="portfolio"
                    :currentFilter="currentFilter"
                    :portfolioType="portfolioType"
                    :routeSuffix="routeSuffix"
                    @refreshPortfolio="refreshPortfolio"
                    @refreshPortfolioAll="refreshPortfolioAll"
                />
            </template>
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </template>
    <template v-else>
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
</template>

<script>
    import { app } from "@/services";
    import AccountInfo from "@/views/account-info";
    import BlockAccountActions from "@/components/block-account-actions";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import ModalPage from "@/components/modals/modal-page";
    export default {
        name: 'account',
        emits: ['refreshPortfolioAll'],
        components: {
            AccountInfo,
            BlockAccountActions,
            BlockTariffLimited,
            ModalPage
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            ticker: {
                type: String,
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            currentTabMenuItem() {
                return this.menu.find(item => { return this.$route.matched.some(record => { return record.name === item.name }) });
            }
        },
        watch: {
            'currencyCurrent': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (oldVal === undefined) return;
                    this.getPortfolio();
                }
            },
            portfolioId: {
                immediate: true,
                handler() {
                    this.getPortfolio();
                    //this.currentTabMenuItem = this.menu.find(item => { return this.$route.matched.some(record => { return record.name === item.name }) });
                }
            },
        },
        data() {
            return {
                showAlertDev: false,
                menu: [{
                    name: `${this.portfolioType}-info${this.routeSuffix}`,
                    title: 'Сводная информация по счету',
                    icon: null,
                    show: true
                }, {
                    name: `${this.portfolioType}-history${this.routeSuffix}`,
                    title: 'История сделок',
                    icon: null,
                    show: true
                }, {
                    name: `${this.portfolioType}-coupons${this.routeSuffix}`,
                    title: 'Купоны',
                    icon: null,
                    show: true
                }, {
                    name: `${this.portfolioType}-flow${this.routeSuffix}`,
                    title: 'ДДС',
                    icon: null,
                    show: true,
                }, {
                    name: `${this.portfolioType}-analytics${this.routeSuffix}`,
                    title: 'Аналитика портфеля',
                    icon: null,
                    show: true,
                }],
                portfolio: {},
                currentFilter: false,
                //currentTabMenuItem: {},
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        mounted() {
            //this.currentTabMenuItem = this.menu.find(item => { return this.$route.matched.some(record => { return record.name === item.name }) });
        },
        methods: {
            getPortfolio() {
                this.showLoaderSending = true;
                app.getPortfolio(this.portfolioId, this.portfolioType, false, this.$route.meta.requiresAuth).then(res => {
                    if (!res.error) {
                        this.portfolio = res;
                        //this.$breadcrumbs.value.at(-2).label = this.portfolio.name;

                        if (!this.$route.meta.requiresAuth || (this.rules.portfolio && this.rules.portfolio.limit === 0)) {
                            this.showLoaderSending = false;
                        } else {
                            if (!this.rules.portfolio) {
                                this.portfolio.hidden = 1;
                            //    this.showLoaderSending = false;
                            //} else {
                            //#    this.getPortfolioList();
                            }
                            this.showLoaderSending = false;
                        }
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                        this.showLoaderSending = false;
                    }
                }).catch(err => {
                    console.error(err);
                    if (err.response.status === 404) {
                        if (this.$route.name === 'publics') {
                            this.$router.push({ name: 'publics-accounts' });
                        } else if (this.$route.name === 'public') {
                            this.$router.push({ name: 'public-accounts' });
                        } else {
                            this.$router.push({ name: 'page404' });
                        }
                    } else {
                        this.$store.dispatch('showError', err);
                    }
                    this.showLoaderSending = false;
                });
            },
            getPortfolioList() {
                app.getPortfolioMenuList({}).then(res => {
                    let index = res.map(item => item.id).indexOf(this.portfolio.id);
                    if (index + 1 > this.rules.portfolio.limit) this.portfolio.hidden = 1;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            refreshPortfolio() {
                console.log('refreshPortfolio');
                this.getPortfolio();
            },
            refreshPortfolioAll() {
                this.$emit('refreshPortfolioAll');
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            changeTabMenuItem(item) {
                //this.currentTabMenuItem = item;
                if (item.name) this.next(item.name);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
