<template>
    <div
        class="app__block d-flex h-100 flex-column px-4 pt-3 pb-4"
        :class="$route.name === 'accounts' ? 'accounts' : 'account'"
    >
        <BlockUserCurrencies/>
        <template
            v-if="$route.name === 'accounts'"
        >
            <div class="app__block-title">
                <h1 class="mb-5">Сводная информация по брокерским счетам</h1>
            </div>
            <b-card
                v-if="showAlertDev"
                class="accounts__item-alert mb-4 shadow"
            >
                <b-icon-exclamation-triangle class="h2 text-danger mb-0 me-1" /> <strong class="text-danger">Внимание!</strong> Раздел «Брокерские счета» находится в процессе активной разработки и тестирования. Информация о БС может быть неточной.
            </b-card>
            <template
                v-if="!showLoaderSending"
            >
                <div class="accounts__account account">
                    <div class="account__info">
                        <div class="account__info-filters d-flex mb-4">
                            <div class="h2">Объединенные метрики всех брокерских счетов</div>
                            <div class="d-flex flex-wrap ms-auto">
                                <b-button
                                    variant="light"
                                    :class="['shadow account__buttons-circle ms-auto']"
                                    pill
                                    @click.stop="showFilterBlocks()"
                                >
                                    <b-icon-sliders2-vertical></b-icon-sliders2-vertical>
                                </b-button>
                                <ModalFilterBlocks
                                    :show="showModalFilterBlocks"
                                    :items="blocksNames"
                                    :itemsFiltered="blocksFilters"
                                    :minCols="1"
                                    @changeFilter="changeFilterBlocks"
                                    @hideModal="hideFilterBlocks"
                                />
                                <b-card
                                    class="text-nowrap ms-3 mb-2 shadow"
                                    bodyClass="py-0 ps-3 pe-2 d-flex align-items-center"
                                >
                                    <b-form-checkbox variant="success" size="sm" v-model="currentFilter" :value="true" name="check-button" switch>
                                        Все валюты
                                    </b-form-checkbox>
                                </b-card>
                                <template
                                    v-for="item in dataFilters"
                                    :key="item.name"
                                >
                                    <b-button
                                        v-if="item.show"
                                        :variant="(item.name === currentFilter) ? 'success' : 'light'"
                                        class="text-nowrap px-3 ms-3 mb-2 shadow"
                                        :class="(item.name === currentFilter) ? 'active' : ''"
                                        size="sm"
                                        pill
                                        @click.stop="changeFilter(item)"
                                    >
                                        {{ item.title }}
                                    </b-button>
                                </template>
                            </div>
                        </div>
                        <div class="account__info-block row mb-5 pb-2">
                            <div
                                v-if="blocksFilters.indexOf('accounts_invested') !== -1"
                                class="account__info-block-item col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 mb-4"
                            >
                                <b-card
                                    class="shadow h-100"
                                    :bodyClass="`account__info-block-item-inner`"
                                >
                                    <div class="content pe-4 m--title">
                                        Внесено лично
                                    </div>
                                    <div class="d-flex account__info-block-item-title">
                                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('deposited'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                                    </div>
                                    <div class="content pe-4 m--title">
                                        Выведено лично
                                    </div>
                                    <div class="d-flex account__info-block-item-title">
                                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('withdrawn'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                                    </div>
                                    <div class="content pe-4 m--title">
                                        Остаток внесенных лично
                                    </div>
                                    <div class="d-flex account__info-block-item-title">
                                        <span class="h5 mb-2">{{ $helpers.toPrice(infoAccountParam('invested'), { sign: currencyCurrent.symbol, pointer: ',' }) }}</span>
                                    </div>
                                    <Helper
                                        id="accounts_invested"
                                        helper="accounts_invested"
                                        classParent="account__info-block-item"
                                    />
                                </b-card>
                            </div>
                            <!--BlockInfo
                                title="Инвестировано"
                                :showFull="currentFilter"
                                :value="infoAccountParam('invested')"
                                :values="infoAccountParams('invested')"
                                valueToPrice
                                classMain="account__info-block-item"
                                classColor=""
                                helper="accounts_invested"
                            /-->
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_current_value') !== -1"
                                title="Текущая оценка всех брокерских счетов"
                                :showFull="currentFilter"
                                :value="infoAccountParam('portfolio_aci_sum')"
                                :values="infoAccountParams('portfolio_aci_sum')"
                                valueToPrice
                                classMain="account__info-block-item"
                                classColor=""
                                helper="accounts_current_value"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_free_cash') !== -1"
                                title="Свободные денежные средства"
                                :showFull="currentFilter"
                                :value="infoAccountParam('cash_sum')"
                                :values="infoAccountParams('cash_sum')"
                                valueToPrice
                                classMain="account__info-block-item"
                                classColor=""
                                helper="accounts_free_cash"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_total_value') !== -1"
                                title="Текущая суммарная оценка ЦБ на всех БС"
                                :showFull="currentFilter"
                                :value="infoAccountParam('assets_aci_sum')"
                                :values="infoAccountParams('assets_aci_sum')"
                                valueToPrice
                                classMain="account__info-block-item"
                                classColor=""
                                helper="accounts_total_value"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_security_payments') !== -1"
                                title="Доход 1. Дивиденды // купоны за всю историю"
                                :showFull="currentFilter"
                                :value="infoAccountParam('security_payments_sum')"
                                :values="infoAccountParams('security_payments_sum')"
                                valueToPrice
                                valueUseSign
                                :subvalue="infoAccountParam('security_payments_percent')"
                                :subvalues="infoAccountParams('security_payments_percent')"
                                subvalueToPercent
                                classMain="account__info-block-item"
                                :classColor="`text-${infoAccountParam('security_payments_sum') >= 0 ? 'success' : 'danger'}`"
                                helper="accounts_security_payments"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_current_profit') !== -1"
                                title="Доход 2. Прибыль по ЦБ текущая"
                                :showFull="currentFilter"
                                :value="infoAccountParam('assets_income')"
                                :values="infoAccountParams('assets_income')"
                                valueToPrice
                                valueUseSign
                                :subvalue="infoAccountParam('assets_income_percent')"
                                :subvalues="infoAccountParams('assets_income_percent')"
                                subvalueToPercent
                                classMain="account__info-block-item"
                                :classColor="`text-${infoAccountParam('assets_income') >= 0 ? 'success' : 'danger'}`"
                                helper="accounts_current_profit"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_security_payments_sum') !== -1"
                                title="Доход 2. Прибыль по всем ЦБ за всю историю"
                                :showFull="currentFilter"
                                :value="infoAccountParam('income')"
                                :values="infoAccountParams('income')"
                                valueToPrice
                                valueUseSign
                                :subvalue="infoAccountParam('income_percent')"
                                :subvalues="infoAccountParams('income_percent')"
                                subvalueToPercent
                                classMain="account__info-block-item"
                                :classColor="`text-${infoAccountParam('income') >= 0 ? 'success' : 'danger'}`"
                                helper="accounts_security_payments_sum"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_assets_total_profit') !== -1"
                                title="Общий доход ЦБ за всю историю"
                                :showFull="currentFilter"
                                :value="infoAccountParam('assets_total_income_aci')"
                                :values="infoAccountParams('assets_total_income_aci')"
                                valueToPrice
                                valueUseSign
                                :subvalue="infoAccountParam('assets_total_income_aci_percent')"
                                :subvalues="infoAccountParams('assets_total_income_aci_percent')"
                                subvalueToPercent
                                classMain="account__info-block-item"
                                :classColor="`text-${infoAccountParam('assets_total_income_aci') >= 0 ? 'success' : 'danger'}`"
                                helper="account_assets_total_profit"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_total_profit') !== -1"
                                title="Общий доход БС за всю историю"
                                :showFull="currentFilter"
                                :value="infoAccountParam('total_income_aci')"
                                :values="infoAccountParams('total_income_aci')"
                                valueToPrice
                                valueUseSign
                                :subvalue="infoAccountParam('total_income_aci_percent')"
                                :subvalues="infoAccountParams('total_income_aci_percent')"
                                subvalueToPrice
                                classMain="account__info-block-item"
                                :classColor="`text-${infoAccountParam('total_income_aci') >= 0 ? 'success' : 'danger'}`"
                                helper="accounts_total_profit"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_amortization_sum') !== -1"
                                title="Амортизация за всю историю"
                                :showFull="currentFilter"
                                :value="infoAccountParam('amortization_sum')"
                                :values="infoAccountParams('amortization_sum')"
                                valueToPrice
                                classMain="account__info-block-item"
                                classColor=""
                                helper="accounts_amortization_sum"
                            />
                            <BlockInfo
                                v-if="blocksFilters.indexOf('accounts_assets_average_annual_income') !== -1"
                                title="Среднегодовая прибыль ЦБ на БС"
                                :value="infoAccountParam('assets_average_annual_income')"
                                valueToPercent
                                classMain="account__info-block-item"
                                :classColorValue="`text-${infoAccountParam('assets_average_annual_income') >= 0 ? 'success' : 'danger'}`"
                                helper="accounts_assets_average_annual_income"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="rules.chart"
                    class="accounts__chart chart mb-4 pb-2"
                >
                    <h2 class="mb-4">
                        График доходности брокерских счетов
                        <Helper
                            id="popover-chart"
                            helper="accounts_profit_chart"
                            classModifier="m--dark"
                            sup
                        />
                    </h2>
                    <b-card class="shadow mb-5 py-4">
                        <template v-if="!showLoaderCharts">
                            <div class="chart__filter row mb-3">
                                <div
                                    class="chart__filter-item col-12 col-lg-6 col-xl-5 pe-3 mb-2"
                                    v-for="item in chartFilterOptions"
                                    :key="`chart-filter-${item.value}`"
                                >
                                    <b-form-radio
                                        v-model="chartFilter"
                                        name="chartFilter"
                                        :value="item.value"
                                    >
                                        {{ item.text }}
                                    </b-form-radio>
                                </div>
                            </div>
                            <highcharts
                                v-if="chartFilter === 'capital'"
                                id="charts-capital-accounts"
                                :constructorType="'stockChart'"
                                :options="chartOptions[chartFilter]"
                            >
                            </highcharts>
                            <highcharts
                                v-else-if="chartFilter === 'profit'"
                                id="charts-profit-accounts"
                                :constructorType="'stockChart'"
                                :options="chartOptions[chartFilter]"
                            >
                            </highcharts>
                        </template>
                        <b-overlay
                            :show="showLoaderCharts"
                            variant="transparent"
                            no-wrap
                            spinner-variant="success"
                        />
                    </b-card>
                </div>

                <BlockAccount
                    v-for="portfolio in portfolioList"
                    :key="`portfolio-${portfolio.id}`"
                    :portfolioId="portfolio.id"
                    :portfolio="portfolio"
                    :portfolioType="portfolioType"
                    @refreshPortfolio="refreshPortfolio"
                />
            </template>
            <template
                v-else
            >
                <div class="app__loader">
                    <b-spinner
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
        </template>
        <template
            v-else
        >
            <routerView
                @refreshPortfolioAll="refreshPortfolio"
            />
        </template>
    </div>
</template>

<script>
    import { accountsProfit } from "@/settings/charts";
    import { blocksNames } from "@/settings/blocks";

    import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockInfo from "@/components/block-info";
    import BlockAccount from "@/components/block-account";
    import Helper from "@/components/helper";
    import ModalFilterBlocks from "@/components/modals/modal-filter-blocks";
    export default {
        name: 'accounts',
        components: {
            BlockUserCurrencies,
            BlockInfo,
            BlockAccount,
            Helper,
            ModalFilterBlocks
        },
        props: {
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        watch: {
            'currencyCurrent': {
                //immediate: true,
                handler() {
                    this.getPortfolioListSummary();
                    this.getPortfolioChartsSummary();
                    this.getPortfolioList();
                }
            },
            '$route.name': {
                immediate: true,
                handler(to) {
                    if (to !== 'accounts') return;
                    this.getPortfolioListSummary();
                    this.getPortfolioChartsSummary();
                    this.getPortfolioList();
                }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        data() {
            return {
                showAlertDev: false,
                blocksNames: blocksNames['accountsInfo'],
                blocksFilters: [],
                accountData: {},
                currentFilter: false,
                showModalFilterBlocks: false,
                dataFilters: [{
                /*
                    name: 'shorts',
                    title: 'Краткая информация',
                    show: true
                }, {
                    name: 'full',
                    title: 'Полная информация с разбивкой по валютам',
                    show: true
                }, {
                */
                    name: 'add',
                    title: 'Создать портфель',
                    route: { name: 'account-add' },
                    show: this.$store.state.user?.tariff?.rules.portfolio ? true : false
                }],
                portfolioList: [],
                chartFilterOptions: [{
                        text: 'Собственные средства в Инвестированный капитал',
                        value: 'capital'
                    /*
                    }, {
                        text: 'Прибыль БС и прибыль ЦБ, %',
                        value: 'profit'
                    */
                }],
                chartFilter: 'capital',
                chartOptions: {},
                showLoaderSending: false,
                showLoaderCharts: false,
            };
        },
        created() {
            /*
            this.getPortfolioListSummary();
            this.getPortfolioChartsSummary();
            this.getPortfolioList();
            */
        },
        mounted() {
            this.blocksFilters = [];
            Object.keys(this.blocksNames).map((key) => {
                if (!this.blocksNames[key].hide) {
                    if (this.userSettings.blockInfo?.['accountsInfo']) {
                        if (this.userSettings.blockInfo['accountsInfo'].indexOf(key) !== -1 || this.userSettings.blockInfo['accountsInfo'].indexOf(this.blocksNames[key].synonim) !== -1) this.blocksFilters.push(key);
                    } else {
                        if (!this.blocksNames[key].hideDefault) this.blocksFilters.push(key);
                    }
                }
            });
        },
        methods: {
            infoAccountParam(param) {
                if (this.accountData.summary) {
                    return this.accountData.summary[param] || 0;
                }
                return null;
            },
            infoAccountParams(param) {
                if (this.accountData.currency_summary) {
                    let data = {};
                    Object.keys(this.accountData.currency_summary).forEach(key => {
                        data[key] = this.accountData.currency_summary[key][param];
                    });
                    return data;
                }
                return {};
            },
            changeFilterBlocks(filter) {
                //this.$emit('changeFilterTable', filter);
                this.blocksFilters = filter;
            },
            showFilterBlocks() {
                this.showModalFilterBlocks = true;
            },
            hideFilterBlocks(filter) {
                console.log('hideFilterBlocks', filter);
                let settings = this.userSettings;
                if (!settings.blockInfo) settings.blockInfo = {};
                settings.blockInfo['accountsInfo'] = filter;
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                }).catch(err => {
                    console.error(err);
                });
                this.showModalFilterBlocks = false;
            },
            getPortfolioListSummary() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioListSummary(params).then(res => {
                    console.log(res);
                    if (!res.error) {
                        this.accountData = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getPortfolioList() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioList(params).then(res => {
                    if (!res.error) {
                        if (!res.length) {
                            this.next('stock');
                        }
                        if (this.rules.portfolio) {
                            if (this.rules.portfolio.limit !== 0) {
                                // Ограничение вывода кол-ва портфелей на тарифе
                                //res.splice(this.rules.portfolio.limit);
                            }
                        } else {
                            res = [];
                        }
                        this.portfolioList = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getPortfolioChartsSummary() {
                this.chartOptions = Object.assign({}, accountsProfit);
                let params = {
                    id: this.id,
                    period: 'day'
                };
                this.showLoaderCharts = true;
                app.getPortfolioChartsSummary(params).then(res => {
                    if (!res.error) {
                        console.log('getPortfolioChartsSummary', res);

                        this.chartOptions.capital.tooltip.valueSuffix = ` ${res[0]?.currency}`;
                        this.chartOptions.capital.series[0].name = this.chartOptions.capital.series[0].nameTemplate + res[0]?.currency;
                        this.chartOptions.capital.series[1].name = this.chartOptions.capital.series[1].nameTemplate + res[0]?.currency;
                        this.chartOptions.capital.series[2].name = this.chartOptions.capital.series[2].nameTemplate + res[0]?.currency;

                        this.chartOptions.capital.series[0].data = [];
                        this.chartOptions.capital.series[1].data = [];
                        this.chartOptions.capital.series[2].data = [];

                        this.chartOptions.profit.series[0].data = [];
                        this.chartOptions.profit.series[1].data = [];

                        res.forEach(item => {
                            const date = new Date(item.date).getTime();
                            this.chartOptions.capital.series[0].data.push([
                                date,
                                item.own_cash,
                            ]);
                            this.chartOptions.capital.series[1].data.push([
                                date,
                                item.close_assets_sum,
                            ]);
                            this.chartOptions.capital.series[2].data.push([
                                date,
                                item.close_sum,
                            ]);
                            /*
                            this.chartOptions.profit.series[0].data.push([
                                date,
                                item.asset_income_percent_sum,
                            ]);
                            this.chartOptions.profit.series[1].data.push([
                                date,
                                item.income_percent_sum,
                            ]);
                            */
                        });
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderCharts = false;
                }).catch(err => {
                    this.showLoaderCharts = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeFilter(item) {
                console.log(item);
                if (item.route) {
                    console.log(item.route);
                    this.$router.push(item.route);
                }
                if (item.modal) {
                    console.log(item.modal);
                    this[item.modal]();
                } else {
                    this.currentFilter = !this.currentFilter;
                }
            },
            refreshPortfolio() {
                console.log('refreshPortfolios All');
                this.getPortfolioListSummary();
                this.getPortfolioChartsSummary();
                this.getPortfolioList();
                this.$store.dispatch('refreshSidebarKey');
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
