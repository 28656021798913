<template>
    <b-dropdown
        :id="`action-${instrument.id}`"
        class="float-end"
        dropleft
        variant="link-primary px-1 py-0 h2 mb-0"
        no-caret
    >
        <template #button-content>
            <b-icon-three-dots />
        </template>
        <b-dropdown-item-button
            variant="danger"
            @click.prevent="showDeleteInstrument"
        >
            Удалить
        </b-dropdown-item-button>
    </b-dropdown>
    <ModalStrategyInstrumentDelete
        :show="showModalDeleteInstrument"
        :portfolioId="portfolioId"
        :strategyId="strategyId"
        :instrument="instrument"
        :portfolioType="portfolioType"
        @hideModal="hideDeleteInstrument"
    />
</template>

<script>
    import ModalStrategyInstrumentDelete from "@/components/modals/modal-strategy-instrument-delete";
    export default {
        emits: ['updateData'],
        components: {
            ModalStrategyInstrumentDelete
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            strategyId: {
                type: [Number, String],
                default() { return null; }
            },
            instrument: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                showModalDeleteInstrument: false,
            }
        },
        mounted() {
        },
        methods: {
            showDeleteInstrument() {
                this.showModalDeleteInstrument = true;
            },
            hideDeleteInstrument(refreshData = false) {
                this.showModalDeleteInstrument = false;
                if (refreshData) {
                    this.$emit('updateData');
                }
            },
        }
    };
</script>
