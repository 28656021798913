<template>
    <div class="app__block emitter d-flex h-100 flex-column px-4 pt-3 pb-4">
        <BlockUserCurrencies/>
        <template v-if="!showLoaderSending">
            <div class="app__block-title emitter__title m--help">
                <h1 class="mb-3">Профиль эмитента «{{ emitter.name }}»</h1>
            </div>

            <div class="emitter__info-block row">
                <div class="col-12 col-xl-6 mb-4">
                    <div class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <div class="bond__info-block-item-title d-flex mb-1">
                                Название компании (эмитент)
                                <span class="ms-auto">Рейтинг <strong>{{ emitter.rating_detail }}</strong></span>
                            </div>
                            <div class="bond__info-block-item-title h2 d-flex w-100 mb-3">
                                {{ emitter.name }}
                            </div>
                            <div
                                v-if="emitter.country_name || emitter.inn || emitter.foundation_date"
                                class="row"
                            >
                                <div class="bond__info-block-item-title mb-2">
                                    <template v-if="emitter.country_name">
                                        <strong>{{ emitter.country_name }}</strong> {{ emitter.inn || emitter.foundation_date ? '· ' : '' }}
                                    </template>
                                    <template v-if="emitter.inn">
                                        <strong>ИНН</strong> {{ emitter.inn }} {{ emitter.status_detail ? '· ' : '' }}
                                    </template>
                                    <template v-if="emitter.status">
                                        <strong>Статус</strong> <span :class="`m--${emitter.status}`">{{ emitter.status_detail }} {{ emitter.foundation_date ? '· ' : '' }}</span>
                                    </template>
                                    <template v-if="emitter.foundation_date">
                                        <strong>Год основания</strong> {{ emitter.foundation_date }}
                                    </template>
                                </div>
                            </div>
                            <div
                                v-if="emitter.branch"
                                class="bond__info-block-item-titlemb-2"
                            >
                                Отрасль
                                <Helper
                                    id="popover-bond-emitter"
                                    helper="emitter_branch"
                                />
                                <strong>{{ emitter.branch }}</strong>
                            </div>
                            <div
                                v-if="emitter.description"
                                class="mb-2"
                            >
                                {{ emitter.description }}
                            </div>
                        </b-card>
                    </div>
                    <div class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <div class="bond__info-block-item-title h2 d-flex w-100 mb-3">
                                Мои комментарии об эмитенте
                                <b-button
                                    variant="link-success"
                                    class="opacity-50 ms-auto py-0 px-2"
                                    @click.prevent="showCommentEmitter()"
                                >
                                    <b-icon-pencil />
                                </b-button>
                                <!--span class="ms-auto">Рейтинг <strong>{{ emitter.rating_detail }}</strong></span-->
                            </div>
                            <div
                                v-if="emitter.comment"
                                class="mb-2"
                            >
                                {{ emitter.comment }}
                            </div>
                        </b-card>
                    </div>
                </div>
                <div class="col-12 col-xl-6 mb-4">
                    <div class="bond__info-block-item mb-4">
                        <b-card
                            class="shadow"
                            bodyClass="bond__info-block-item-inner"
                        >
                            <div class="bond__info-block-item-title d-flex mb-3">
                                Текущая доля бумаг эмитента на ваших брокерских счетах
                            </div>
                            <div v-for="share in shares" :key="`portfolio-shares-${share.portfolio}`" class="mb-3">
                                <div class="bond__info-block-item-title h2 d-flex w-100 mb-1">
                                    <router-link
                                        :to="{ name: 'account-info', params: { portfolioId: share.portfolio } }"
                                        class="m--underline"
                                    >
                                        {{ share.portfolio_name }}
                                    </router-link>
                                    <div class="ms-auto">
                                        {{ $helpers.toPrice(share.share_in_portfolio, { sign: '%', pointer: ',' }) }}
                                        {{ $helpers.toPrice(share.actual_sum, { sign: currencyCurrent.symbol, pointer: ',' }) }}
                                    </div>
                                </div>
                                <div v-for="item in share.portfolio_shares" :key="`portfolio-shares-${share.portfolio}-${item.instrument_slug}`">
                                        <div class="bond__info-block-item-title d-flex w-100 mb-1">
                                        <router-link
                                            :to="{ name: 'bond', params: { slug: item.instrument_slug } }"
                                            class="m--underline"
                                        >
                                            {{ item.instrument_name }}
                                        </router-link>
                                        <div class="ms-auto">
                                            {{ $helpers.toPrice(item.share_in_portfolio, { sign: '%', pointer: ',' }) }}
                                            ({{ $helpers.toPrice(item.actual_sum, { sign: currencyCurrent.symbol, pointer: ',' }) }})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>

            <div class="filters bond-screener__filters d-flex">
                <div class="row mb-4 me-1 w-100">
                    <div class="col-auto mb-3">
                        <b-card
                            class="filters__item shadow"
                        >
                            <b-form-checkbox variant="success" v-model="tableDataFilters.is_active" name="check-button" switch>
                                В обращении
                            </b-form-checkbox>
                        </b-card>
                    </div>
                    <b-button
                        variant="primary"
                        :class="['filters__item col-auto ms-auto me-2 mb-3']"
                        :disabled="showLoaderExport"
                        size=""
                        pill
                        @click.prevent="exportEmitterBondsXLS"
                    >
                        <template
                            v-if="showLoaderExport"
                        >
                            <b-spinner
                                class="me-3"
                                small
                            />
                            <span>Генерация ...</span>
                        </template>
                        <template
                            v-else
                        >
                            Выгрузить в Excel
                        </template>
                    </b-button>
                    <a ref="download" href="#" download />
                </div>
                <BlockFilterTable
                    :tableHeader="tableHeader"
                    :tableHeaderName="tableHeaderName"
                    :tableFilters="tableFilters"
                    :minCols="2"
                    @changeFilterTable="changeFilterTable"
                />
            </div>
            <b-card
                :class="['bond-screener__table col-12 shadow mb-5', (showLoaderBonds && !bonds.length) && 'py-4']"
                bodyClass="p-1"
            >
                <template v-if="currentTotalRows">
                    <b-table
                        :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                        :items="prepareTableData()"
                        sticky-header
                        responsive
                        hover
                        class="table__data b-table-sticky-header m--table-bond-screener"
                        @row-selected="onRowSelected"
                    >
                        <template #head()="data">
                            <TableHeaderSlot
                                :data="data"
                                :currentSorting="currentSorting"
                                @changeSorting="changeSorting"
                            />
                        </template>
                        <template #cell(action)="data">
                            <BlockActionsBond
                                :key="`bond-item-${data.item.id}`"
                                :bond="data.item"
                                :lists="lists"
                                @getWatchLists="getEmitterBonds"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div :class="this.$store.state.mode === 'blur' && 'm--blur'">
                                <router-link
                                    v-if="data.item.slug"
                                    :to="{ name: 'bond', params: { slug: data.item.slug } }"
                                    class="d-block"
                                    target="_blank"
                                >
                                    <strong>{{ data.item.name }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.item.ticker }}</small>
                                </router-link>
                                <template
                                    v-else
                                >
                                    <strong>{{ data.item.isin }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.item.name }}</small>
                                </template>
                            </div>
                        </template>
                        <template #cell(currency)="data">
                            {{ data.item.currency_iso_name }}
                        </template>
                        <template #cell(price)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(price_percent)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(aci_value)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(coupon_amount)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(coupon_amount_tax)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(coupon_frequency)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(year_income)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(close_income)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(total_income)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(yield_to_maturity)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(coupon_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                        </template>
                        <template #cell(coupon_days_after)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(coupon_days_until_next)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(issue_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                        </template>
                        <template #cell(maturity_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                        </template>
                        <template #cell(maturity_years)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(valume_trading)="data">
                            <div class="text-end text-nowrap">
                                {{ data.item.value || data.item.value === 0 ? $helpers.toPrice(data.item.value) : '—' }}
                            </div>
                        </template>
                        <template #cell(duration)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                            </div>
                        </template>
                        <template #cell(nominal_feature)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(coupon_feature)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(security_type)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>

                        <template #cell(qualified)="data">
                            {{ data.value ? 'Да' : 'Нет' }}
                        </template>
                        <template #cell(offer_date)="data">
                            {{ data.value ? 'Да' : 'Нет' }}
                        </template>
                        <template #cell(rating)="data">
                            {{ data.value ||  '—' }}
                        </template>
                        <template #cell(has_default)="data">
                            {{ data.value !== 'not' ? data.item.has_default_detail : '—' }}
                        </template>
                        <template #cell(portfolio_shares)="data">
                            <div v-if="!data.item.portfolio_shares.length">
                                —
                            </div>
                            <BlockPortfolioShares
                                v-else
                                :id="data.item.id"
                                :shares="data.item.portfolio_shares"
                                :sign="$store.state.currencyCurrent.symbol"
                            />
                        </template>
                        <template #cell(emitter_portfolio_shares)="data">
                            <div v-if="!data.item.emitter_portfolio_shares">
                                —
                            </div>
                            <BlockEmitterPortfolioShares
                                v-else
                                :id="data.item.id"
                                :inn="emitter.inn"
                                :shares="data.item.emitter_portfolio_shares"
                                :sign="$store.state.currencyCurrent.symbol"
                            />
                        </template>
                    </b-table>
                    <BlockNavigateTable
                        :totalRows="currentTotalRows"
                        :currentPage="currentPage"
                        :currentPerPage="currentPerPage"
                        @changeNavigateTable="changeNavigateTable"
                    />
                </template>
                <template v-else-if="!showLoaderBonds">
                    <b-alert
                        class="mb-0"
                        variant="warning"
                        :model-value="true"
                    >
                        По вашему запросу ничего не найдено. Измените параметра поиска.
                    </b-alert>
                </template>
                <b-overlay
                    :show="showLoaderBonds"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-card>
            <ModalEmitterComment
                v-if="showModalCommentEmitter"
                :emitter="emitter"
                :show="showModalCommentEmitter"
                @hideModal="hideCommentEmitter"
            />


            <template v-if="$store.state.mode === 'all'">
                <pre>{{ bond }}</pre>
                <pre>{{ coupons }}</pre>
            </template>
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
    </div>
</template>

<script>
    //import { bondHistory } from "@/settings/charts";
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    //import BlockInfo from "@/components/block-info";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    import BlockActionsBond from "@/components/block-actions-bond";
    import BlockPortfolioShares from "@/components/block-portfolio-shares";
    import BlockEmitterPortfolioShares from "@/components/block-emitter-portfolio-shares";
    import Helper from "@/components/helper";
    import ModalEmitterComment from "@/components/modals/modal-emitter-comment";
    export default {
        name: 'emitter',
        components: {
            BlockUserCurrencies,
            //BlockInfo,
            BlockFilterTable,
            BlockActionsBond,
            BlockPortfolioShares,
            BlockEmitterPortfolioShares,
            BlockNavigateTable,
            TableHeaderSlot,
            Helper,
            ModalEmitterComment
        },
        props: {
            inn: {
                type: String,
                default() { return null; }
            },
        },
        data() {
            return {
                emitter: {},
                bonds: [],
                shares: [],
                lists: [],
                currentSorting: {
                    key: 'isin',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {
                    is_active: true
                },
                tableHeaderName: 'bondEmitterItem',
                tableHeader: fieldNames.bondEmitterItem,
                //fields: [],
                currentPerPage: 25,
                currentPage: 1,
                showModalCommentEmitter: false,
                showLoaderExport: false,
                showLoaderSending: false,
                showLoaderBonds: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        watch: {
            'tableDataFilters.is_active': {
                //immediate: true,
                handler() {
                    this.getEmitterBonds();
                }
            },
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            });
            this.getEmitter();
            this.getEmitterBonds();
            this.getEmitterShares();
            this.getEmitterPortfolioShares();
        },
        mounted() {
            /*
            this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            */
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    if (!this.tableHeader[key].hideDefault) this.tableFilters.push(key);
                }
            });
        },
        methods: {
            getEmitter() {
                this.showLoaderSending = true;
                app.getEmitter(this.inn).then(res => {
                    if (!res.error) {
                        this.emitter = res;
                        this.$breadcrumbs.value.at(-1).label = `Эмитент ${this.emitter.name}`;

                        console.log(res);
                        this.getEmitterComment();
                        //this.getBondCoupons();
                        //this.getBondHistoryChart();
                        this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    if (err.response.status === 404) {
                        this.$router.push({ name: 'page404' });
                    } else {
                        this.$store.dispatch('showError', err);
                    }
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            getEmitterBonds() {
                this.showLoaderBonds = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    inn: this.inn,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                app.getEmitterBonds(params).then(res => {
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        this.bonds = res.results;
                        console.log(res);
                        this.getWatchLists();
                        this.showLoaderBonds = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderBonds = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getEmitterComment() {
                //this.showLoaderSending = true;
                app.getEmitterComment(this.inn).then(res => {
                    if (!res.error) {
                        this.emitter.comment = res.comment;
                        console.log(res);
                        //this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getEmitterShares() {
                app.getEmitterShares(this.inn).then(res => {
                    if (!res.error) {
                        //this.emitter.comment = res.comment;
                        console.log('Shares', res);
                        //this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getEmitterPortfolioShares() {
                app.getEmitterPortfolioShares(this.inn).then(res => {
                    if (!res.error) {
                        this.shares = res.emitter_in_portfolio_shares;
                        console.log('Shares', res);
                        //this.showLoaderSending = false;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getWatchLists() {
                let params = {
                    instrument_type: 'bond',
                    currency: this.tableDataFilters.currency
                };
                //this.showLoaderSending = true;
                app.getWatchLists(params).then(res => {
                    if (!res.error) {
                        this.lists = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.bonds];
                if (list.length) {
                    console.log('prepareTableData');
                    list.forEach((item, index) => {
                        item._index = index + 1;
                        item._rowVariant = !item.is_active ? 'secondary text-muted' : '';
                        item._cellVariants = !item.is_active ? { action: 'secondary text-muted', name: 'secondary text-muted' } : '';
                    });
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                list = list.filter(item => {
                    item._rowVariant = !item.is_active ? 'secondary text-muted' : '';
                    return true;
                });
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getEmitterBonds();
            },
            exportEmitterBondsXLS() {
                this.showLoaderExport = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    inn: this.inn,
                    //offset: (this.currentPage - 1) * this.currentPerPage,
                    //limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                app.exportEmitterBondsXLS(params).then(res => {
                    let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' });
                    this.$refs.download.href = window.URL.createObjectURL(blob);
                    this.$refs.download.download = decodeURI(res.headers['content-disposition'].split('filename=')[1]);
                    this.$refs.download.click();
                    this.showLoaderExport = false;
                }).catch(err => {
                    this.showLoaderExport = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            showCommentEmitter() {
                this.showModalCommentEmitter = true;
            },
            onRowSelected(item) {
                console.log(item);
            },
            hideCommentEmitter(refreshData = false) {
                this.showModalCommentEmitter = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.getEmitterComment();
                    })
                }
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
