<template>
    <sup
        v-if="sup"
        :id="`popover-block-${id}`"
        variant="link"
        class="helper"
        :class="[`${classParent}-helper`, classModifier]"
        @mouseover.stop="getHelperContent(helper)"
    >
        <template v-if="info">
            <b-icon-info-circle></b-icon-info-circle>
        </template>
        <template v-else>
            <b-icon-question-circle></b-icon-question-circle>
        </template>
    </sup>
    <span
        v-else
        :id="`popover-block-${id}`"
        variant="link"
        class="helper"
        :class="[`${classParent}-helper`, classModifier]"
        @mouseover.stop="getHelperContent(helper)"
    >
        <template v-if="info">
            <b-icon-info-circle></b-icon-info-circle>
        </template>
        <template v-else>
            <b-icon-question-circle></b-icon-question-circle>
        </template>
    </span>
    <b-popover
        :ref="`popover-block-item-${id}`"
        custom-class="shadow"
        :target="`popover-block-${id}`"
        :triggers="triggers"
        :placement="placement"
    >
        <template
            v-if="showLoaderSending"
        >
            <b-spinner
                variant="success"
                small
                class="me-2"
            />
            <span class="text-success">Загрузка подсказки ...</span>
        </template>
        <template
            v-else
        >
            <div
                v-html="hint.content || `Создайте подсказку с полем slug: ${helper}`"
            />
        </template>
    </b-popover>
</template>

<script>
    import { app } from "@/services";
    export default {
        components: {
        },
        props: {
            id: {
                type: [Number, String],
                default: ''
            },
            sup: {
                type: Boolean,
                default: false
            },
            info: {
                type: Boolean,
                default: false
            },
            classParent: {
                type: String,
                default: null
            },
            classModifier: {
                type: String,
                default: null
            },
            triggers: {
                type: String,
                default: 'hover'
            },
            placement: {
                type: String,
                default: 'top'
            },
            helper: {
                type: String,
                default: null
            },
        },
        computed: {
        },
        data() {
            return {
                hint: {},
                requested: false,
                showLoaderSending: false,
            }
        },
        mounted() {
        },
        methods: {
            getHelperContent(helper) {
                if (!this.requested) {
                    this.showLoaderSending = true;
                    app.getHint(helper).then(res => {
                        this.hint = res;
                        this.showLoaderSending = false;
                    }).catch(err => {
                        console.error(err);
                        this.showLoaderSending = false;
                    });
                    this.requested = true;
                }
            },
        }
    };
</script>
