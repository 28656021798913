<template>
    <b-modal
        id="modal-account-manager-delete"
        v-model="showModal"
        classes="modal__container"
        header-class="modal__header pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Удаление доступа"
        centered
        hide-footer
        scrollable
        @hidden="hideModal"
    >
        <div class="modal__content auth__form">
            <template
                v-if="accountManagerDeleted"
            >
                <b-alert
                    class="mb-3"
                    variant="success"
                    show
                >
                    <div class="form__content">
                        {{ accountManagerDeleted }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else-if="formErrors.detail"
            >
                <b-alert
                    class="mb-3"
                    variant="danger"
                    show
                >
                    <div class="form__content">
                        {{ formErrors.detail }}
                    </div>
                </b-alert>
            </template>
            <template
                v-else
            >
                <b-form
                    class="form d-flex flex-column"
                    @submit="onSubmit"
                >
                    <div class="form__content mb-3">
                        Вы удаляете дуступ пользавателю <strong>{{ trustUser.user_public_name }} (ID: {{ trustUser.user }})</strong> к портфелю <strong>«{{ portfolio.name }}»</strong> из сервиса "КАПИТАЛ".
                    </div>
                    <b-button
                        type="submit"
                        variant="success"
                        class="d-block mx-auto"
                        pill
                    >
                        Подтвердить
                    </b-button>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
        <b-overlay
            :show="showLoaderModal"
            variant="transparent"
            no-wrap
            spinner-variant="success"
        />
    </b-modal>
</template>

<script>
    import { app } from "@/services";
    export default {
        emits: ['refreshPortfolio'],
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.showModal = this.show;
                }
            }
        },
        //computed: {
        //    showModal() {
        //        return this.show;
        //    },
        //},
        props: {
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            trustUser: {
                type: Object,
                default() { return {}; }
            },
            show: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                formErrors: {},
                showModal: this.show,
                showLoaderModal: false,
                showLoaderSending: false,
                accountManagerDeleted: '',
                refreshData: false,
            };
        },
        created() {
        },
        methods: {
            onSubmit() {
                this.formErrors = {};
                let params = {
                    portfolio: this.portfolio.id,
                    user: this.trustUser.user,
                };
                this.showLoaderSending = true;
                app.deleteTrustUser(params).then(res => {
                    console.log(res);
                    this.showLoaderSending = false;
                    this.accountManagerDeleted = 'Пользователь успешно удален!';
                    this.refreshData = true;
                }).catch(err => {
                    this.showLoaderSending = false;
                    if (err.response.status === 400) {
                        console.log(err.response);
                        this.formErrors = err;
                        this.formErrors.detail = 'Ошибка удаления пользователя!';
                    } else {
                        //this.$store.dispatch('showError', err);
                        this.formErrors = {
                            detail: err
                        }
                        console.error(err);
                    }
                });
            },
            hideModal() {
                this.accountManagerDeleted = '',
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
