<template>
    <div class="account__analytic-flow account__flow">
        <template v-if="rules.account_flow && (rules.account_flow.limit === 0 || rules.account_flow.limit >= portfolio.ordering)">
            <template v-if="!showLoaderSending">
                <div class="d-flex account__buttons align-items-center mb-3">
                    <div class="h2">Метрики</div>
                    <b-button
                        variant="light"
                        :class="['shadow account__buttons-circle ms-auto']"
                        pill
                        @click.stop="showFilterBlocks()"
                    >
                        <b-icon-sliders2-vertical></b-icon-sliders2-vertical>
                    </b-button>
                    <ModalFilterBlocks
                        :show="showModalFilterBlocks"
                        :items="blocksNames"
                        :itemsFiltered="blocksFilters"
                        :minCols="1"
                        @changeFilter="changeFilterBlocks"
                        @hideModal="hideFilterBlocks"
                    />
                </div>
                <div class="account__coupons-block row mb-5">
                    <BlockInfo
                        v-if="blocksFilters.indexOf('future_total_income') !== -1"
                        title="НОВЫЕ. Общий доход Д1+Д2 по всем облигациям"
                        :value="strategy.future_total_income"
                        valueToPrice
                        classMain="account__coupons-block-item"
                        classColor=""
                        helper="buy_plan_future_total_income"
                    />

                    <BlockInfo
                        v-if="blocksFilters.indexOf('actual_future_total_income') !== -1"
                        title="СТАРЫЕ. Общий доход. Д1+Д2, если держим до погашения (с текущей даты)"
                        :value="strategy.actual_future_total_income"
                        valueToPrice
                        classMain="account__coupons-block-item"
                        classColor=""
                        helper="buy_plan_actual_future_total_income"
                    />

                    <!--BlockInfo
                        v-if="blocksFilters.indexOf('total_income') !== -1"
                        title="ПУ после замены облигаций"
                        :value="strategy.total_income"
                        valueToPrice
                        classMain="account__coupons-block-item"
                        classColor=""
                        helper="buy_plan_total_income"
                    /-->

                    <BlockInfo
                        v-if="blocksFilters.indexOf('actual_sale_sum') !== -1"
                        title="СТАРЫЕ. Общая сумма продаж с тек. НКД"
                        :value="strategy.actual_sale_sum"
                        valueToPrice
                        classMain="account__coupons-block-item"
                        helper="buy_plan_actual_sale_sum"
                    />

                    <BlockInfo
                        v-if="blocksFilters.indexOf('future_buy_sum') !== -1"
                        title="НОВЫЕ. Общая сумма покупок с тек. НКД"
                        :value="strategy.future_buy_sum"
                        valueToPrice
                        classMain="account__coupons-block-item"
                        helper="buy_plan_future_buy_sum"
                    />
                </div>
                <div class="bond-lists__buttons d-flex pt-4 mb-4">
                    <div class="d-flex flex-wrap">
                        <b-button
                            :variant="'primary'"
                            class="bond-lists__buttons-item me-3 mb-2 shadow"
                            size=""
                            pill
                            @click.prevent="showAddInstrumentBuyPlan"
                        >
                            Добавить облигацию
                            <b-icon-plus-lg class="ms-2" />
                        </b-button>
                        <b-button
                            :variant="'light'"
                            class="bond-lists__buttons-item me-3 mb-2 shadow"
                            size=""
                            pill
                            @click.prevent="showAddWatchlistBuyPlan"
                        >
                            Загрузить из Watchlist
                        </b-button>
                    </div>
                </div>
                <div class="account__filters">
                    <div class="row mb-4">
                        <div class="col-12 col-md-auto mb-3">
                            <div class="d-flex">
                                <div class="col-auto me-2">
                                    <b-card
                                        class="filters__item shadow"
                                    >
                                        <b-input-group>
                                            <b-form-input
                                                v-model="tableDataFilters.q"
                                                placeholder="Поиск по тикеру"
                                                size="sm"
                                                class="border-transparent py-0"
                                            >
                                            </b-form-input>
                                            <b-input-group-append>
                                                <a
                                                    v-if="tableDataFilters.q"
                                                    href="#"
                                                    class="btn-icon"
                                                    @click.prevent="clearInputQ"
                                                >
                                                    <b-icon-x-lg />
                                                </a>
                                                <div
                                                    v-else
                                                    class="btn-icon"
                                                >
                                                    <b-icon-search />
                                                </div>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-card>
                                </div>
                                <b-button
                                    variant="primary"
                                    class="filters__item col-auto shadow m--mw-120"
                                    size=""
                                    pill
                                    @click.prevent="changeFilterTableDataSimple"
                                >
                                    Искать
                                </b-button>
                            </div>
                        </div>
                        <div class="col-12 col-md-auto ms-auto mb-3">
                            <div class="d-flex">
                                <BlockFilterTable
                                    :tableHeader="tableHeader"
                                    :accountId="portfolioId"
                                    :tableHeaderName="tableHeaderName"
                                    :tableFilters="tableFilters"
                                    @changeFilterTable="changeFilterTable"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <b-card
                    class="account__flow-table col-12 shadow mb-5"
                    bodyClass="p-1"
                >
                    <b-table
                        :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                        :items="prepareTableData()"
                        sticky-header
                        responsive
                        hover
                        class="table__data b-table-sticky-header m--table-flow"
                    >
                        <template #head()="data">
                            <TableHeaderSlot
                                :data="data"
                                :currentSorting="currentSorting"
                                @changeSorting="changeSorting"
                            />
                        </template>
                        <template #cell(action)="data">
                            <BlockActionsStrategyBuyPlanInstrument
                                :key="`watchlist-instrument-${strategyId}-${data.item.id}`"
                                :portfolioId="portfolioId"
                                :strategyId="strategyId"
                                :instrument="data.item"
                                @updateData="updateData"
                            />
                        </template>
                        <template #cell(name)="data">
                            <div :class="['d-flex', this.$store.state.mode === 'blur' && 'm--blur']">
                                <span v-if="!hideIndex" class="table__data-row-index m--inline">{{ data.item._index }}</span>
                                <router-link
                                    v-if="data.item.slug"
                                    :to="{ name: `bond`, params: { slug: data.item.slug } }"
                                    class="d-block"
                                >
                                    <strong>{{ data.item.name }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.item.ticker }}</small>
                                </router-link>
                                <template
                                    v-else
                                >
                                    <strong>{{ data.item.name }}</strong>
                                    <br>
                                    <small class="text-muted">{{ data.item.ticker }}</small>
                                </template>
                            </div>
                        </template>
                        <template #cell(quantity)="data">
                            <div class="d-flex m--input-control">
                                <b-form-input
                                    id="input-quantity"
                                    v-maska
                                    data-maska="0"
                                    data-maska-tokens="0:\d:multiple"
                                    v-model="data.item.quantity"
                                    type="text"
                                    placeholder=""
                                    size="sm"
                                    :class="['shadow-sm py-0 px-2 m--no-pill', !data.item._editQuantity ? '' : 'disabled']"
                                    :readonly="!data.item._editQuantity"
                                    @click.prevent="toggleEditStatusQuantity(data.item)"
                                    @keypress.enter="changeInstrumentQuantity(data.item)"
                                    @blur="changeInstrumentQuantity(data.item)"
                                ></b-form-input>
                                <b-button
                                    v-if="data.item._editQuantity"
                                    variant="link-success"
                                    class="py-0 px-1"
                                    size="lg"
                                    @click.prevent="changeInstrumentQuantity(data.item)"
                                >
                                    <b-icon-check2 />
                                </b-button>
                            </div>
                        </template>
                        <template #cell(future_security_payments_income)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(future_income)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(future_total_income)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(nominal)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(price)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(purchase_amount)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(aci_sum)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(purchase_amount_aci)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(coupon_frequency)="data">
                            <div class="text-end text-nowrap">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(future_security_payments_income_percent)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(future_income_percent)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(future_total_income_percent)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(yield_to_maturity)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(maturity_years)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(minimum_coupon_rate)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(minimum_coupon_rate_price)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(spread_amount)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(amortization_amount)="data">
                            <div class="text-end text-nowrap">
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </div>
                        </template>
                        <template #cell(maturity_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                        </template>
                        <template #cell(coupon_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                        </template>
                        <template #cell(amortization_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                        </template>
                        <template #cell(issue_date)="data">
                            {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                        </template>
                        <template #cell(nominal_feature)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(coupon_feature)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(emitter)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(emitter_inn)="data">
                            <div class="small">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(rating)="data">
                            {{ data.value ||  '—' }}
                        </template>
                        <template #cell(has_default)="data">
                            {{ data.value !== 'not' ? data.item.has_default_detail : '—' }}
                        </template>
                    </b-table>
                    <BlockNavigateTable
                        :totalRows="currentTotalRows"
                        :currentPage="currentPage"
                        :currentPerPage="currentPerPage"
                        @changeNavigateTable="changeNavigateTable"
                    />
                    <b-overlay
                        :show="showLoaderlazy"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                        z-index="1010"
                    />
                </b-card>
                <ModalInstrumentBuyPlanAdd
                    v-if="showModalAddInstrumentBuyPlan"
                    :portfolioId="portfolioId"
                    :strategyId="strategyId"
                    :show="showModalAddInstrumentBuyPlan"
                    @hideModal="hideAddInstrumentBuyPlan"
                />
                <ModalWatchlistBuyPlanAdd
                    v-if="showModalAddWatchlistBuyPlan"
                    :portfolioId="portfolioId"
                    :strategyId="strategyId"
                    :show="showModalAddWatchlistBuyPlan"
                    @hideModal="hideAddWatchlistBuyPlan"
                />
            </template>
            <template v-else>
                <div class="app__loader">
                    <b-spinner
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import { blocksNames } from "@/settings/blocks";

    import { app } from "@/services";
    import BlockInfo from "@/components/block-info";

    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import BlockActionsStrategyBuyPlanInstrument from "@/components/block-actions-strategy-buy-plan-instrument";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import TableHeaderSlot from "@/components/table-header-slot";
    import ModalFilterBlocks from "@/components/modals/modal-filter-blocks";
    import ModalInstrumentBuyPlanAdd from "@/components/modals/modal-instrument-buy-plan-add";
    import ModalWatchlistBuyPlanAdd from "@/components/modals/modal-watchlist-buy-plan-add";

    export default {
        name: 'accountAnalyticSalePlan',
        components: {
            BlockInfo,
            BlockFilterTable,
            BlockNavigateTable,
            BlockTariffLimited,
            TableHeaderSlot,
            BlockActionsStrategyBuyPlanInstrument,
            ModalFilterBlocks,
            ModalInstrumentBuyPlanAdd,
            ModalWatchlistBuyPlanAdd
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            strategyId: {
                type: [Number, String],
                default() { return null; }
            },
        },
        watch: {
            /*
            dateRange: {
                immediate: true,
                handler() {
                    if (this.dateRange[0] && this.dateRange[1]) {
                        console.log('dateRange', this.dateRange);
                        this.$nextTick(() => {
                            this.getPortfolioStrategyBuyPlan();
                        });
                    }
                }
            },
            */
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent.value;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        data() {
            return {
                blocksNames: blocksNames['accountAnalyticBuyPlan'],
                blocksFilters: [],
                strategy: {},
                tableFilters: [],
                tableDataFilters: {},
                tableHeaderName: 'StrategyBuyItem',
                tableHeader: fieldNames.StrategyBuyItem,
                tableCellVariant: {},
                currentSorting: {
                    key: 'isin',
                    direction: 'asc'
                },
                //fields: [],
                //fieldsTotal: [],
                list: [],
                currentPerPage: 50,
                currentPage: 1,
                hideIndex: false,
                showModalFilterBlocks: false,
                showModalAddInstrumentBuyPlan: false,
                showModalAddWatchlistBuyPlan: false,
                showLoaderSending: false,
                showLoaderlazy: false
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.blocksFilters = [];
            Object.keys(this.blocksNames).map((key) => {
                if (!this.blocksNames[key].hide) {
                    if (this.userSettings.blockInfo?.['strategyBuyPlan']) {
                        if (this.userSettings.blockInfo['strategyBuyPlan'].indexOf(key) !== -1 || this.userSettings.blockInfo['strategyBuyPlan'].indexOf(this.blocksNames[key].synonim) !== -1) this.blocksFilters.push(key);
                    } else {
                        if (!this.blocksNames[key].hideDefault) this.blocksFilters.push(key);
                    }
                }
            });
            /*
            this.$breadcrumbs.value.at(-3).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            */
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.accounts?.[this.portfolioId]?.[this.tableHeaderName]) {
                    if (this.userSettings.accounts[this.portfolioId][this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            this.getPortfolioStrategyBuyPlan();
            this.getPortfolioStrategyInstrumentsBuys();
        },
        methods: {
            getPortfolioStrategyBuyPlan(lazy = false) {
                if (!lazy) this.showLoaderSending = true;
                let params = {
                    portfolioId: this.portfolioId,
                    id: this.strategyId
                };
                app.getPortfolioStrategyBuyPlan(params, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.strategy = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                });
            },
            getPortfolioStrategyInstrumentsBuys() {
                this.showLoaderlazy = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    portfolioId: this.portfolioId,
                    id: this.strategyId,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                app.getPortfolioStrategyInstrumentsBuys(params, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.list = res.results;
                        this.currentTotalRows = res.count;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderlazy = false;
                }).catch(err => {
                    this.showLoaderlazy = false;
                    this.$store.dispatch('showError', err);
                });
            },
            prepareTableData() {
                let list = [...this.list];
                if (list.length) {
                    list.forEach((item, index) => {
                        item._index = index + 1;
                        item._cellVariants = this.tableCellVariant;
                    });
                    /*
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                    */
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                /*
                list = list.filter(item => {
                    //if (this.currentDataFilter === 'all') {
                    //    return true;
                    //}
                    //console.log(new Date(item.date), new Date(this.dateRange.start), new Date(this.dateRange.end));
                    if (new Date(item.date) >= new Date(this.dateRange.start) && new Date(item.date) <= new Date(this.dateRange.end)) {
                        return true;
                    }
                    return false;
                });
                */
                return list;
            },
            changeFilterTableDataSimple(/*data = null*/) {
                console.log('changeFilterTableDataSimple');
                //if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.getPortfolioStrategyInstrumentsBuys();
            },
            clearInputQ() {
                this.tableDataFilters.q = null;
            },
            clearFilterTableData() {
                this.tableDataFilters = {};
                this.currentPage = 1;
                this.useFilters = false;
                this.getPortfolioStrategyInstrumentsBuys();
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
                this.getPortfolioStrategyInstrumentsBuys();
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getPortfolioStrategyInstrumentsBuys();
            },
            changeFilterBlocks(filter) {
                //this.$emit('changeFilterTable', filter);
                this.blocksFilters = filter;
            },
            showFilterBlocks() {
                this.showModalFilterBlocks = true;
            },
            hideFilterBlocks(filter) {
                console.log('hideFilterBlocks', filter);
                let settings = this.userSettings;
                if (!settings.blockInfo) settings.blockInfo = {};
                settings.blockInfo['strategyBuyPlan'] = filter;
                app.updateUser({ user_settings: settings }).then(res => {
                    console.log(res);
                    delete res.currencies;
                    this.$store.dispatch('setUser', res);
                }).catch(err => {
                    console.error(err);
                });
                this.showModalFilterBlocks = false;
            },
            showAddInstrumentBuyPlan() {
                this.showModalAddInstrumentBuyPlan = true;
            },
            hideAddInstrumentBuyPlan(refreshData = false) {
                console.log('hideAddInstrumentBuyPlan', refreshData);
                this.showModalAddInstrumentBuyPlan = false;
                if (refreshData) {
                    console.log('refreshData');
                    this.getPortfolioStrategyBuyPlan(true);
                    this.getPortfolioStrategyInstrumentsBuys();
                }
            },
            showAddWatchlistBuyPlan() {
                this.showModalAddWatchlistBuyPlan = true;
            },
            hideAddWatchlistBuyPlan(refreshData = false) {
                console.log('hideAddWatchlistBuyPlan', refreshData);
                this.showModalAddWatchlistBuyPlan = false;
                if (refreshData) {
                    console.log('refreshData');
                    this.getPortfolioStrategyBuyPlan(true);
                    this.getPortfolioStrategyInstrumentsBuys();
                }
            },
            toggleEditStatusQuantity(item) {
                this.item = item;
                this.item._editQuantity = this.item._editQuantity ? false : true;
            },
            changeInstrumentQuantity(item) {
                let params = {
                    portfolioId: this.portfolioId,
                    strategyId: this.strategyId,
                    id: item.id,
                    instrument: item.instrument,
                    quantity: !item.quantity ? 0 : item.quantity > item.initial_quantity ? item.initial_quantity : item.quantity,
                };
                app.setPortfolioStrategyInstrumentQuantity(params).then(res => {
                    if (!res.error) {
                        item._editQuantity = false;
                        this.getPortfolioStrategyBuyPlan(true);
                        this.getPortfolioStrategyInstrumentsBuys();
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            updateData() {
                this.getPortfolioStrategyBuyPlan(true);
                this.getPortfolioStrategyInstrumentsBuys();
            }
        }
    };
</script>
