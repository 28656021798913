<template>
    <b-modal
        id="modal-watchlist-add"
        v-model="showModal"
        size="md"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        title="Выбор Watchlist"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <b-alert
                class="mb-3"
                variant="success"
                :modelValue="dismissCountDown"
                @dismiss-count-down="countDownChanged"
            >
                Watchlist успешно добавлен
            </b-alert>
            <template v-if="show">
                <b-form
                    v-if="!dismissCountDown"
                    @submit="onSubmitHandler"
                    class="bond-screener__group-form row"
                >
                    <div class="mb-3">Выберите Watchlist для загрузки облигаций из списка </div>
                    <b-form-group
                        id="input-group-type"
                        label="Выберите из списка  Watchlist"
                        class="col-12"
                    >
                        <Multiselect
                            id="input-currency"
                            v-model="formData.id"
                            :options="watchlistOptions"
                            mode="single"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            class="shadow-sm m--no-pill"
                            placeholder=""
                            required
                        >
                            <template #option="{ option }">
                                <div>
                                    {{ option.label }}
                                    <div class="text-muted small">{{ option.sublabel }}</div>
                                </div>
                            </template>
                        </Multiselect>
                    </b-form-group>
                    <div class="col-12">
                        <b-alert
                            variant="danger"
                            :show="addError"
                        >
                            {{ addError }}
                        </b-alert>
                    </div>
                    <div class="col-12 pt-3">
                        <b-button
                            variant="outline-success"
                            class="bond-screener__group-form-button mb-3 me-auto"
                            type="submit"
                            pill
                            :disabled="showLoaderSending"
                        >
                            Сохранить
                        </b-button>
                    </div>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-form>
            </template>
        </div>
    </b-modal>
</template>

<script>
    import { app } from '@/services';
    import Multiselect from '@vueform/multiselect';
    export default {
        components: {
            Multiselect
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.refreshData = false;
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            strategyId: {
                type: [Number, String],
                default() { return null; }
            },
        },
        data() {
            return {
                watchlistOptions: [],
                formData: {},
                showModal: this.show,
                showLoaderSending: false,
                dismissCountDown: 0,
                addSuccess: false,
                addError: null,
                refreshData: false,
            };
        },
        created() {
            if (this.watchlistId) {
                this.formData = Object.assign({}, this.list);
            }
        },
        mounted() {
            this.getPortfolioStrategiesWatchlists();
        },
        methods: {
            getPortfolioStrategiesWatchlists() {
                this.showLoaderSending = true;
                let params = {
                    portfolioId: this.portfolioId,
                    id: this.strategyId
                };
                app.getPortfolioStrategiesWatchlists(params).then(res => {
                    if (!res.error) this.watchlistOptions = res.map(item => { return { label: item.name, sublabel: `Облигаций (${item.instrument_count}). Изменен ${this.$helpers.formatDate(new Date(item.updated), 'DD.MM.YYYY')}`, value: item.id } });
                    //if (!this.watchlistId) this.formData.currency = this.currencyList[0]?.value;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.watchlistOptions = [];
                });
            },
            onSubmitHandler() {
                this.showLoaderSending = true;
                this.addSuccess = false;
                this.addError = null;
                let params = {
                    portfolioId: this.portfolioId,
                    strategyId: this.strategyId,
                    id: this.formData.id
                };
                app.addPortfolioStrategyWatchlist(params).then(() => {
                    this.dismissCountDown = 3;
                    this.refreshData = true;
                    this.addSuccess = true;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.addError = err.detail || err.message;
                    this.showLoaderSending = false;
                    console.error(err);
                });
            },
            countDownChanged(dismissCountDown) {
                console.log('dismissCountDown', dismissCountDown);
                if (dismissCountDown === 0) {
                    this.$nextTick(() => {
                        this.showModal = false;
                    });
                }
            },
            hideModal() {
                this.dismissCountDown = 0;
                this.addSuccess = false;
                this.addError = null;
                this.$emit('hideModal', this.refreshData);
            },
        }
    };
</script>
