<template>
    <div
        :class="$route.name === 'account-add' ? 'app__block account d-flex h-100 flex-column px-4 pt-3 pb-4' : ''"
    >
        <template
            v-if="$route.name === 'account-add'"
        >
           <BlockUserCurrencies/>
        </template>
        <template v-if="id || (rules.portfolio && (rules.portfolio.limit === 0 || rules.portfolio.limit > portfolioList.length))">
            <div class="account__edit">
                <div class="account__edit-steps mb-5">
                    <div
                        v-for="(step, index) in steps"
                        :key="`step-${index}`"
                        class="account__edit-step"
                        :class="currentStep >= index + 1 ? currentStep === index + 1 ? 'is-current' : 'is-ok' : ''"
                    >
                        <div class="account__edit-step-name">{{ step.name }}</div>
                        <div class="account__edit-step-info">{{ step.info }}</div>
                    </div>
                </div>
            </div>
            <div
                v-if="currentStep === 1"
                class="account__edit-form"
            >
                <b-form-group
                    id="input-group-broker"
                    label="Выберите брокера*"
                    class="col-12 account__edit-form-brokers"
                >
                    <div
                        v-for="broker in brokerList"
                        :key="`broker-${broker.id}`"
                        class="account__edit-form-broker"
                        :class="id || portfolioId ? 'is-disabled' : ''"
                    >
                        <input
                            :id="`broker-input-${broker.id}`"
                            v-model="formData.broker"
                            type="radio"
                            name="broker"
                            :value="broker.id"
                            :disabled="id || portfolioId ? true : false"
                            class="d-none"
                        />
                        <label
                            class="account__edit-form-broker-label"
                            :for="`broker-input-${broker.id}`"
                        >
                            <div
                                class="account__edit-form-broker-logo"
                            >
                                <img
                                    :src="broker.logo"
                                    alt=""
                                >
                            </div>
                            <span>{{ broker.name }}</span>
                        </label>
                    </div>
                </b-form-group>

                <b-form-group
                    id="input-group-name"
                    label="Название портфеля*"
                    class="col-12"
                >
                    <b-form-input
                        id="input-name"
                        v-model="formData.name"
                        type="text"
                        placeholder="Придумайте название портфеля"
                        size="sm"
                        required
                        class="shadow-sm m--no-pill"
                    ></b-form-input>
                </b-form-group>
                <!--b-form-group
                    id="input-group-start_date"
                    label="Дата открытия счета у брокера (если помните)"
                    class="col-12"
                >
                    <DatePicker
                        v-model="formData.start_date"
                        :model-config="calendarConfig"
                        :masks="masks"
                        :popover="{ visibility: 'click' }"
                        mode="date"
                        color="blue"
                    >
                        <template v-slot="{ inputValue, inputEvents }">
                            <b-input-group
                                class="m--with-icon"
                            >
                                <template #append>
                                    <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                                </template>
                                <b-form-input
                                    name="start_date"
                                    readonly
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    :state="null"
                                    class="m--date shadow-sm m--no-pill"
                                ></b-form-input>
                            </b-input-group>
                        </template>
                    </DatePicker>
                </b-form-group-->
                <b-form-group
                    id="input-group-comment"
                    label="Комментарий к портфелю"
                    class="col-12"
                >
                    <b-form-textarea
                        id="input-comment"
                        v-model="formData.comment"
                        placeholder="При необходимости введите ваш комментарий"
                        size="sm"
                        class="shadow-sm m--no-pill"
                    ></b-form-textarea>
                </b-form-group>
                <input
                    v-model="formData.id"
                    type="hidden"
                    name="id"
                >
                <b-overlay
                    :show="showLoaderSending"
                    no-wrap
                    spinner-variant="success"
                />
            </div>
            <div
                v-if="currentStep > 1"
                class="account__edit-import"
            >
                <div class="account__edit-import-broker mb-5">
                    <div class="account__edit-import-broker-logo">
                        <img
                            :src="broker.logo"
                            alt=""
                        >
                    </div>
                    <div class="account__edit-import-broker-name me-4">
                        {{ broker.name }}
                    </div>
                    <div class="account__edit-import-broker-help"
                        v-if="typeImportDeals === 'api'"
                    >
                        <b-icon-exclamation-circle></b-icon-exclamation-circle>
                        <a
                            href="#"
                            class="ms-2"
                            @click.prevent="showPage('account_instruction_tinkoff')"
                        >
                            Инструкция по получению токена у брокера Тинькофф
                        </a>
                    </div>
                    <div class="account__edit-import-broker-help"
                        v-if="typeImportDeals === 'file'"
                    >
                        <b-icon-exclamation-circle></b-icon-exclamation-circle>
                        <a
                            href="#"
                            class="ms-2"
                            @click.prevent="showPage('account_instruction_file')"
                        >
                             Инструкция “Как получить отчет брокера?”
                        </a>
                    </div>
                    <div class="account__edit-import-broker-help"
                        v-if="typeImportDeals === 'manually'"
                    >
                        <b-icon-exclamation-circle></b-icon-exclamation-circle>
                        <a
                            href="#"
                            class="ms-2"
                            @click.prevent="showPage('account_instruction_manually')"
                        >
                             Инструкция “Как добавить сделку вручную?”
                        </a>
                    </div>
                </div>
                <b-form-group
                    v-if="!typeImportDeals"
                    id="input-group-imports"
                    label="Способ добавления сделок"
                    class="col-12"
                >
                    <div
                        v-if="broker.api_upload && (portfolioType === 'account')"
                        class="account__edit-import-item pt-3 pb-2"
                    >
                        <b-button
                            variant="outline-success"
                            class="account__edit-import-item-button col-4 me-3"
                            size="lg"
                            pill
                            @click.prevent="showAgreeApi"
                        >
                            Настроить интеграцию по API
                        </b-button>
                        <div class="account__edit-import-item-info">
                            Автоматическая загрузка портфеля через API. Доступна для клиентов банка Тинькофф.
                        </div>
                    </div>
                    <div
                        v-if="broker.file_upload"
                        class="account__edit-import-item pt-3 pb-2"
                    >
                        <b-button
                            variant="outline-success"
                            class="account__edit-import-item-button col-4 me-3"
                            size="lg"
                            pill
                            @click.stop="setTypeImportDeals('file')"
                        >
                            Загрузить файл с отчётом брокера
                        </b-button>
                        <div class="account__edit-import-item-info">
                            Импортируйте все ваши операции из отчета брокера из файла с расширением xml. Система обработает ваш файл и выдаст отчет о загрузке данных.
                        </div>
                    </div>
                    <div class="account__edit-import-item pt-3 pb-2">
                        <b-button
                            variant="outline-success"
                            class="account__edit-import-item-button col-4 me-3"
                            size="lg"
                            pill
                            @click.stop="setTypeImportDeals('manually')"
                        >
                            Ручное добавление сделок
                        </b-button>
                        <div class="account__edit-import-item-info">
                            Если ваш брокер не поддерживается или вы хотите добавить тестовый счет, вы можете ввести данные о сделках самостоятельно.
                        </div>
                    </div>
                </b-form-group>
                <template v-else>
                    <template v-if="typeImportDeals === 'api'">
                        <b-form
                            @submit="onSubmitApi"
                            class="row"
                        >
                            <b-form-group
                                v-if="currentStep === 2"
                                id="input-group-token"
                                label="Токен Тинькофф API"
                                class="account__edit-import-token col-12"
                            >
                                <b-form-input
                                    id="input-token"
                                    v-model="formData.token"
                                    type="text"
                                    placeholder="Укажите ваш токен для получения данных из сервиса"
                                    size="sm"
                                    class="shadow-sm m--no-pill"
                                    autocomplete="off"
                                    :disabled="showLoaderSending"
                                    :readonly="importResultStatus === 'ok'"
                                ></b-form-input>
                            </b-form-group>
                            <div
                                v-if="formData.token && currentStep === 2"
                                class="col-12 mb-3 pt-3"
                            >
                                <b-button
                                    v-if="importResultStatus !== 'ok'"
                                    variant="outline-success"
                                    class="account__edit-import-button"
                                    type="submit"
                                    pill
                                    :disabled="showLoaderSending"
                                >
                                    Установить соединение и получить данные о портфеле
                                    <b-overlay
                                        :show="showLoaderSending"
                                        no-wrap
                                        spinner-variant="success"
                                        spinner-small
                                    >
                                    </b-overlay>
                                </b-button>
                            </div>
                            <template v-if="importResultStatus">
                                <template v-if="showLoaderSending">
                                    <div class="account__edit-import-success text-success mb-4">
                                        Соединение успешно установлено
                                    </div>
                                    <div class="app__loader">
                                        <b-spinner
                                            variant="success"
                                            class="me-3"
                                        />
                                        <span class="text-success">Импорт данных ...</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="account__edit-import-status">
                                        <b-card class="shadow px-3 py-4 mb-5">
                                            <template v-if="importResultStatus === 'ok'">
                                                <div class="account__edit-import-status-title mb-4">
                                                    Импорт завершен
                                                </div>
                                                <div class="account__edit-import-status-items mb-4">
                                                    <div class="account__edit-import-status-item">
                                                        Активов: <span>{{ importResultData.instrument }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Сделок: <span>{{ importResultData.deal }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Комиссий: <span>{{ importResultData.tax_commision }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Начисления: <span>{{ importResultData.charge }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Прочее: <span>{{ importResultData.other }}</span>
                                                    </div>
                                                    <template
                                                        v-if="importResultData.errors"
                                                    >
                                                        <div class="account__edit-import-status-item text-danger">
                                                            Ошибок: {{ importResultData.errors }}
                                                            <a
                                                                v-if="importResultData.errors"
                                                                href="#"
                                                                class="small text-danger text-decoration-underline"
                                                                v-b-toggle.collapse-errors
                                                            >
                                                                подробнее
                                                            </a>
                                                        </div>
                                                        <b-collapse
                                                            v-if="importResultData.errors"
                                                            id="collapse-errors"
                                                        >
                                                            <template
                                                                v-for="(error, key) in importResultData.errors_info"
                                                                :key="`error-info-${key}`"
                                                            >
                                                                <div
                                                                    v-if="key === 'bad_operations_figi'"
                                                                    class="text-danger"
                                                                >
                                                                    <p class="mb-1"><strong>Сделки не были обработаны по причине отсутствия FIGI у Тинькофф. Вы можете добавить их в ручном режиме.</strong></p>
                                                                    <ol>
                                                                        <li
                                                                            v-for="(item, index) in error"
                                                                            :key="`error-${key}-${index}`"
                                                                            class="small"
                                                                        >
                                                                            {{ item.date }}, {{ $helpers.toPrice(item.payment_sum, { pointer: ',', sign: item.currency }) }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </template>
                                                        </b-collapse>
                                                    </template>
                                                </div>
                                            </template>
                                            <template v-else-if="importResultStatus === 'error'">
                                                <div class="account__edit-import-status-title mb-4 text-danger">
                                                    Импорт не удался
                                                </div>
                                                <div class="content">
                                                    <p>Ошибка! Неправильно введен токен.</p>
                                                    <p>Пожалуйста, убедитесь, что вы правильно скопировали токен, и повторите попытку.</p>
                                                    <p
                                                        v-if="importResultStatusDetail"
                                                        class="text-danger"
                                                    >
                                                        {{ importResultStatusDetail }}
                                                    </p>
                                                </div>
                                            </template>
                                        </b-card>
                                    </div>
                                </template>
                            </template>
                        </b-form>
                    </template>
                    <template v-if="typeImportDeals === 'file'">
                        <b-form
                            @submit="onSubmitFile()"
                            class="account__edit-import-file row"
                            @dragover.prevent
                            @drop.prevent
                            @dragenter.prevent
                            @dragleave.prevent
                        >
                            <input
                                id="input-file"
                                type="file"
                                accept=".xml"
                                class="d-none"
                                @change="uploadFileComplete"
                            />
                            <div
                                class="col-3 mb-3"
                                @drop.prevent="dragFile"
                            >
                                <b-card
                                    class="account__edit-import-file-button shadow"
                                    @click="onClickUploadFile('input-file')"
                                >
                                    <b-icon-filetype-xml />
                                </b-card>
                            </div>
                            <div
                                class="col-9 mb-3"
                                @drop.prevent="dragFile"
                            >
                                <div class="account__edit-import-file-title">
                                    Перетащите сюда отчёт брокера в формате .xml или кликните по значку XML слева.
                                </div>
                                <div>
                                    Если вы не знаете, где взять отчёт, нажмите "Как получить отчет брокера?"
                                </div>
                            </div>
                            <div
                                v-if="formData.file"
                                class="col-12 mb-3"
                            >
                                <b-icon-filetype-xml /> <strong>{{ formData.file.name }}</strong>
                            </div>
                            <div
                                v-if="formData.file"
                                class="col-12 mb-3"
                            >
                                <b-button
                                    v-if="importResultStatus !== 'ok'"
                                    variant="outline-success"
                                    class="account__edit-import-button"
                                    type="submit"
                                    pill
                                    :disabled="showLoaderSending"
                                >
                                    Обработать данные
                                    <b-overlay
                                        :show="showLoaderSending"
                                        no-wrap
                                        spinner-variant="success"
                                        spinner-small
                                    >
                                    </b-overlay>
                                </b-button>
                            </div>
                            <template v-if="importResultStatus">
                                <template v-if="showLoaderSending">
                                    <div class="app__loader">
                                        <b-spinner
                                            variant="success"
                                            class="me-3"
                                        />
                                        <span class="text-success">Импорт данных ...</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="account__edit-import-status">
                                        <b-card class="shadow px-3 py-4 mb-5">
                                            <template v-if="importResultStatus === 'ok'">
                                                <div class="account__edit-import-status-title mb-4">
                                                    Импорт завершен
                                                </div>
                                                <div class="account__edit-import-status-items mb-4">
                                                    <div class="account__edit-import-status-item">
                                                        Активов: <span>{{ importResultData.instrument }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Сделок: <span>{{ importResultData.deal }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Комиссий: <span>{{ importResultData.tax_commission }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Начисления: <span>{{ importResultData.charge }}</span>
                                                    </div>
                                                    <div class="account__edit-import-status-item">
                                                        Прочее: <span>{{ importResultData.other }}</span>
                                                    </div>
                                                    <template v-if="importResultData.errors">
                                                        <div class="account__edit-import-status-item text-danger">
                                                            Ошибок: {{ importResultData.errors }}
                                                            <a
                                                                v-if="importResultData.errors_info && importResultData.errors_info.length"
                                                                href="#"
                                                                class="small text-danger text-decoration-underline"
                                                                v-b-toggle.collapse-errors
                                                            >
                                                                подробнее
                                                            </a>
                                                        </div>
                                                        <b-collapse
                                                            v-if="importResultData.errors_info && importResultData.errors_info.length"
                                                            id="collapse-errors"
                                                        >
                                                            <template
                                                                v-for="(error, index) in importResultData.errors_info"
                                                                :key="`error-info${index}`"
                                                            >
                                                                <div
                                                                    v-if="error.category === 'isin_not_found'"
                                                                    class="text-danger"
                                                                >
                                                                    <p class="mb-1"><strong>{{ error.message }}</strong></p>
                                                                    <ol>
                                                                        <li
                                                                            v-for="(item, index) in error.data"
                                                                            :key="`error-${error.category}-${index}`"
                                                                            class="small"
                                                                        >
                                                                            {{ item.isin }}, {{ item.comment }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                                <div
                                                                    v-else
                                                                    class="text-danger"
                                                                >
                                                                    <p class="mb-1"><strong>{{ error.message }}</strong></p>
                                                                    <ol>
                                                                        <li
                                                                            v-for="(item, index) in error.data"
                                                                            :key="`error-${error.category}-${index}`"
                                                                            class="small"
                                                                        >
                                                                            {{ item.date }}, {{ item.comment }}
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                            </template>
                                                        </b-collapse>
                                                    </template>
                                                </div>
                                                <!--b-button
                                                    variant="outline-success"
                                                    class="me-3"
                                                    pill
                                                    @click.prevent="onClearFile()"
                                                >
                                                    Обработать данные
                                                </b-button-->
                                            </template>
                                            <template v-else-if="importResultStatus === 'error'">
                                                <div class="account__edit-import-status-title mb-4 text-danger">
                                                    Импорт не удался
                                                </div>
                                                <div class="content">
                                                    <p>Ошибка!</p>
                                                    <p>Пожалуйста, убедитесь, что отчет выгружен правильно согласно инструкции и имеет полную форму документа.</p>
                                                    <p
                                                        v-if="importResultStatusDetail"
                                                        class="text-danger"
                                                    >
                                                        {{ importResultStatusDetail }}
                                                    </p>
                                                </div>
                                            </template>
                                        </b-card>
                                    </div>
                                </template>
                            </template>
                        </b-form>
                        <template v-if="formData.files?.length">
                            <div class="h4">Загруженные отчеты</div>
                            <b-card class="account__settings-import-files shadow">
                                <div class="account__settings-import-files-inner">
                                    <div
                                        v-for="(item, index) in formData.files"
                                        :key="`file-${index}`"
                                        class="account__settings-import-files-item"
                                    >
                                        <span :class="['account__settings-import-files-item-date', item.parsed ? 'm--ok' : 'm--error']">{{ $helpers.formatDate(new Date(item.date_publication), 'DD.MM.YYYY HH.mm') }}</span>
                                        <div class="text-muted">
                                            {{ item.file.split('/').at(-1) }}
                                        </div>
                                        <span v-if="!item.parsed" class="account__settings-import-files-item-link">
                                            <a href="#" class="text-danger" @click.prevent="onSubmitFile(item.id)">Обработать</a>
                                        </span>
                                    </div>
                                </div>
                            </b-card>
                        </template>

                    </template>
                    <template v-if="typeImportDeals === 'manually'">
                        <template v-if="!importResultStatus">
                            <b-tabs content-class="mt-3">
                                <b-tab
                                    title="Денежные средства"
                                    active
                                >
                                    <BlockAccountCash
                                        :key="keyBlockAccountCash"
                                        :portfolioId="formData.id"
                                    />
                                    <FormCashDealAdd
                                        :portfolioId="formData.id"
                                        @refrshData="refreshAccountCash"
                                    />
                                </b-tab>
                                <b-tab
                                    title="Сделки"
                                >
                                    <FormDealAdd
                                        :portfolioId="formData.id"
                                    />
                                </b-tab>
                            </b-tabs>
                        </template>
                    </template>
                </template>
                <ModalAgreeApi
                    :show="showModalAgreeApi"
                    slug="account_agree_api"
                    @hideModal="hideAgreeApi"
                    @confirmApi="confirmApi"
                />
                <ModalPage
                    :slug="ModalPageSlug"
                    :show="showModalPage"
                    @hideModal="hidePage"
                />
            </div>
            <div class="account__edit-buttons mt-4">
                <b-button
                    v-if="currentStep > 1"
                    variant="outline-primary"
                    class="account__edit-button m--prev px-4 me-3"
                    pill
                    :disabled="disabledStepPrev"
                    @click.prevent="prevStep"
                >
                    <b-icon-reply-all-fill></b-icon-reply-all-fill>
                    Назад
                </b-button>
                <b-button
                    v-if="(currentStep === 1) && !disabledStepNext"
                    variant="primary"
                    class="account__edit-button m--next px-4 me-3"
                    pill
                    :disabled="disabledStepNext"
                    @click.prevent="nextStep"
                >
                    Далее
                    <b-icon-reply-all-fill></b-icon-reply-all-fill>
                </b-button>
                <b-button
                    v-if="showFinishButton"
                    variant="primary"
                    class="account__edit-button px-4 me-3"
                    pill
                    @click.prevent="next('accounts')"
                >
                    Завершить
                </b-button>
            </div>
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";
    import BlockAccountCash from "@/components/block-account-cash";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import FormCashDealAdd from "@/components/form-cash-deal-add";
    import FormDealAdd from "@/components/form-deal-add";
    import ModalAgreeApi from "@/components/modals/modal-agree-api";
    //import ModalHelpApi from "@/components/modal-help-api";
    import ModalPage from "@/components/modals/modal-page";
    export default {
        name: 'accountAdd',
        components: {
            BlockAccountCash,
            BlockUserCurrencies,
            BlockTariffLimited,
            FormCashDealAdd,
            FormDealAdd,
            ModalAgreeApi,
            //ModalHelpApi,
            ModalPage
        },
        props: {
            id: {
                type: [String, Number],
                default() { return null; }
            },
            portfolioId: {
                type: [String, Number],
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                keyBlockAccountCash: +(new Date()),
                masks: {
                    input: 'DD.MM.YYYY',
                },
                calendarConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                },
                portfolioList: [],
                brokerList: [],
                broker: {},
                formData: {},
                steps: [{
                    name: 'Портфель',
                    info: 'Создайте портфель'
                //}, {
                //    name: 'Денежные средства',
                //    info: 'Настройте баланс счета'
                }, {
                    name: 'Сделки',
                    info: 'Добавьте и загрузите сделки'
                //}, {
                //    name: 'Завершение',
                //    info: 'Отчет о добавлении сделок'
                }],
                currentStep: 1,
                typeImportDeals: null,
                importResultStatus: null,
                importResultStatusDetail: '',
                importResultData: [],
                showModalAgreeApi: false,
                ModalPageSlug: null,
                showModalPage: false,
                showLoaderSending: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            disabledStepPrev() {
                if (this.currentStep > 1) {
                    return false;
                }
                return true;
            },
            disabledStepNext() {
                if (this.currentStep === 1) {
                    if (this.formData.broker && this.formData.name) return false;
                } else if (this.currentStep === 2) {
                    if (this.typeImportDeals === 'api') {
                        return this.formData.token ? false : true;
                    }
                    return this.typeImportDeals ? false : true;
                }
                return true;
            },
            showFinishButton() {
                if (!this.typeImportDeals && this.currentStep === 2) return true;
                //if (this.typeImportDeals === 'api' && !this.importResultStatus && this.currentStep === 3) return true;
                if (this.typeImportDeals === 'api' && !this.importResultStatus && this.currentStep === 3) return true;
                if (this.typeImportDeals === 'api' && this.importResultStatus === 'ok') return true;
                if (this.typeImportDeals === 'file' && this.importResultStatus === 'ok') return true;

                if (this.typeImportDeals === 'manually' && this.importResultStatus) return true;
                return false;
            }
        },
        created() {
        },
        mounted() {
            this.formData = {
                id: this.id || this.portfolioId
            };
            this.getPortfolioList();
        },
        methods: {
            getPortfolioList() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioMenuList(params).then(res => {
                    if (!res.error) {
                        this.portfolioList = res;
                    }
                    this.getDataStep(this.currentStep);
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                    this.portfolioList = [];
                });
            },
            getDataStep(step) {
                if (step === 1) {
                    let params = {};
                    this.showLoaderSending = true;
                    app.getBrokerList(params).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            this.brokerList = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                    if (this.formData.id) {
                        this.showLoaderSending = true;
                        let id = this.formData.id;
                        app.getPortfolio(id, this.portfolioType).then(res => {
                            this.showLoaderSending = false;
                            if (!res.error) {
                                this.formData = res;
                            } else {
                                this.$store.dispatch('showError', {err: { message: res.error }});
                            }
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    }
                } else if (step === 2) {
                    this.showLoaderSending = true;
                    app.getBroker(this.formData.broker).then(res => {
                        this.showLoaderSending = false;
                        if (!res.error) {
                            this.broker = res;
                        } else {
                            this.$store.dispatch('showError', {err: { message: res.error }});
                        }
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.$store.dispatch('showError', err);
                        console.error(err);
                    });
                } else if (step === 3) {
                    if (this.typeImportDeals === 'manually') {
                        this.importResultStatus = 'ok';
                    }
                }
            },
            setTypeImportDeals(type) {
                this.typeImportDeals = type;
            },
            showAgreeApi() {
                this.showModalAgreeApi = true;
            },
            hideAgreeApi() {
                this.showModalAgreeApi = false;
            },
            confirmApi(type) {
                this.setTypeImportDeals(type);
            },
            onSubmitApi() {
                this.importResultStatus = null;
                this.importResultStatusDetail = '';
                this.showLoaderSending = true;
                let params = {
                    portfolio: this.formData.id,
                    token: this.formData.token
                };
                if (this.formData.broker_token) {
                    app.updateBrokerAuth(params).then(res => {
                        this.showLoaderSending = false;
                        console.log(res);
                        this.importData();
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.importResultStatus = 'error';
                        this.importResultStatusDetail = err.detail;
                        console.error(err);
                    });
                } else {
                    //app.updateBrokerAuth(params).then(res => {
                    app.addBrokerAuth(params).then(res => {
                        this.showLoaderSending = false;
                        console.log(res);
                        this.importData();
                    }).catch(err => {
                        this.showLoaderSending = false;
                        this.importResultStatus = 'error';
                        this.importResultStatusDetail = err.detail;
                        console.error(err);
                    });
                }
            },
            importData() {
                this.importResultStatus = 'import';
                this.importResultStatusDetail = '';
                //this.currentStep++;
                let params = {
                    portfolio_id: this.formData.id,
                    status: 0
                };
                console.log(params);
                this.showLoaderSending = true;
                app.importBrokerData(params).then(res => {
                    this.showLoaderSending = false;
                    console.log(res);
                    this.importResultData = res.detail;
                    this.importResultStatus = 'ok';
                    if (res.errors || Object.keys(res.errors_info).length) {
                        this.importResultData.errors = res.errors || Object.keys(res.errors_info).length;
                        this.importResultData.errors_info = res.errors_info;
                    }
                    this.currentStep++;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.importResultStatus = 'error';
                    this.importResultStatusDetail = err.detail;
                    //this.currentStep--;
                    console.error(err);
                });
            },
            onSubmitFile(fileId = null) {
                this.importResultStatus = 'import';
                this.importResultStatusDetail = '';
                this.currentStep++;
                let params = {
                    portfolio_id: this.formData.id,
                    file: fileId || this.formData.file.id,
                    status: 0
                };
                this.showLoaderSending = true;
                app.importBrokerData(params).then(res => {
                    this.showLoaderSending = false;
                    console.log(res);
                    this.importResultData = res.detail;
                    this.importResultStatus = 'ok';
                    if (res.errors) {
                        //this.importResultData.errors = res.errors;
                        this.importResultData.errors_info = res.errors_info;
                    }
                    console.log('this.formData.id', this.formData.id)
                    if (this.formData.id) {
                        this.showLoaderSending = true;
                        let id = this.formData.id;
                        app.getPortfolioProfile(id, this.portfolioType).then(res => {
                            this.showLoaderSending = false;
                            if (!res.error) {
                                this.formData = res;
                            } else {
                                this.$store.dispatch('showError', {err: { message: res.error }});
                            }
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    }
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.importResultStatus = 'error';
                    this.importResultStatusDetail = err.detail;
                    this.currentStep--;
                    delete this.formData.file;
                    console.error(err);
                });

            },
            onClearFile() {
                this.importResultStatus = null;
                this.importResultStatusDetail = '';
                this.importResultData = [];
                delete this.formData.file;
                this.currentStep--;
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            /*
            calcDealPayment() {
                console.log('calcDealPayment');
                this.formData[event.target.name] = event.target.dataset.maskRawValue;
                this.$nextTick(() => {
                    this.formData.payment = (1 * this.formData.price) * (1 * this.formData.quantity);
                    this.formData.commission = 0;
                });
            },
            */
            onClickUploadFile(id) {
                let input;
                if (id) { // for file edit
                    input = document.getElementById(id);
                } else {
                    input = document.getElementById(id);
                }
                let click = new MouseEvent('click');
                input.dispatchEvent(click);
            },
            uploadFileComplete(event) {
                this.importResultStatus = null;
                this.importResultStatusDetail = '';
                for (let file of event.target.files) {
                    file.id = event.target.id;

                    const formData = new FormData();
                    formData.append('file', file);
                    app.addPortfolioFile(this.formData.id, formData, this.portfolioType).then(res => {
                        console.log(res);
                        this.formData.file = res;
                    }).catch(err => {
                        console.error(err.detail);
                        this.importResultStatus = 'error';
                        this.importResultStatusDetail = err.detail;
                    })
                }
            },
            dragFile(event) {
                console.log('dragFile', event);
                for (let file of event.dataTransfer.files) {
                    file.id = event.target.id;

                    const formData = new FormData();
                    formData.append('file', file);
                    app.addPortfolioFile(this.formData.id, formData, this.portfolioType).then(res => {
                        console.log(res);
                        this.formData.file = res;
                    }).catch(err => {
                        console.error(err);
                    })
                }
            },
            prevStep() {
                this.setTypeImportDeals(null);
                this.importResultStatus = null;
                this.currentStep--;
                this.getDataStep(this.currentStep);
            },
            nextStep() {
                if (this.currentStep === 1) {
                    if (this.formData.id) {
                        let params = this.formData;
                        this.showLoaderSending = true;
                        app.updatePortfolio(params, this.portfolioType).then(res => {
                            this.showLoaderSending = false;
                            this.formData = res;
                            this.currentStep++;
                            this.getDataStep(this.currentStep);
                            this.$store.dispatch('refreshSidebarKey');
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    } else {
                        let params = this.formData;
                        this.showLoaderSending = true;
                        app.addPortfolio(params).then(res => {
                            this.showLoaderSending = false;
                            this.formData = res;
                            this.currentStep++;
                            this.getDataStep(this.currentStep);
                            this.$store.dispatch('refreshSidebarKey');
                        }).catch(err => {
                            this.showLoaderSending = false;
                            this.$store.dispatch('showError', err);
                            console.error(err);
                        });
                    }
                } else {
                    this.importResultStatus = null;
                    this.currentStep++;
                    this.getDataStep(this.currentStep);
                }
            },
            refreshAccountCash() {
                this.keyBlockAccountCash = +(new Date());
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
