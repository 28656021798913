const blocksNames = {
    // Портфель - Метрика
    accountInfo: {
        account_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        account_current_value: {
            name: 'Текущая оценка брокерского счета',
            helper: '',
        },
        account_free_cash: {
            name: 'Свободные денежные средства',
            helper: '',
        },
        account_total_value: {
            name: 'Текущая оценка ЦБ на БС',
            helper: '',
        },
        account_security_payments: {
            name: 'Доход 1. Дивиденды // купоны за всю историю',
            helper: '',
        },
        account_security_payments_full_aci: {
            name: 'Доход 1. Дивиденды // купоны за всю историю с учетом тек. НКД',
            helper: '',
        },
        account_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            helper: '',
        },
        account_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            helper: '',
        },
        account_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            helper: '',
        },
        account_total_profit: {
            name: 'Общий доход БС за всю историю',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
        account_amortization_sum: {
            name: 'Амортизация за всю историю',
            helper: '',
        },
        account_plan_security_payments: {
            name: 'Доход 1. ПЛАН. Купоны за 12 мес. вперед',
            helper: '',
        },
        account_planed_bond_close_income: {
            name: 'Доход 2. ПЛАН при погашении облигации',
            helper: '',
        },
        account_planed_bond_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        account_info: {
            name: 'Дата последней сделки и комментарий к счету',
            helper: '',
        },
        account_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            //synonim: 'instrument_ticker',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
    },
    // Все портфели - Метрика
    accountsInfo: {
        accounts_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        accounts_current_value: {
            name: 'Текущая оценка всех брокерских счетов',
            helper: '',
        },
        accounts_free_cash: {
            name: 'Свободные денежные средства',
            helper: '',
        },
        accounts_total_value: {
            name: 'Текущая суммарная оценка ЦБ на всех БС',
            helper: '',
        },
        accounts_security_payments: {
            name: 'Доход 1. Дивиденды // купоны за всю историю',
            helper: '',
        },
        accounts_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            helper: '',
        },
        accounts_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            helper: '',
        },
        accounts_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            helper: '',
        },
        accounts_total_profit: {
            name: 'Общий доход БС за всю историю',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
        accounts_amortization_sum: {
            name: 'Амортизация за всю историю',
            helper: '',
        },
        accounts_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            helper: '',
            disabled: true,
            hide: true,
            hideDefault: true,
        },
    },
    // Аналитика портфеля. План продаж стратегии - Метрика
    accountAnalyticSalePlan: {
        actual_income: {
            name: 'СТАРЫЕ. Доход 2, если продать сегодня',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        actual_total_income: {
            name: 'СТАРЫЕ. Общий доход. Д1+Д2, если продать сегодня',
            helper: '',
        },
        actual_sale_sum: {
            name: 'СТАРЫЕ. Общая сумма продаж с тек. НКД',
            helper: '',
        },
        actual_future_total_income: {
            name: 'СТАРЫЕ. Общий доход. Д1+Д2, если держим до погашения (с текущей даты)',
            helper: '',
        },
    },
    // Аналитика портфеля. План покупок стратегии - Метрика
    accountAnalyticBuyPlan: {
        future_total_income: {
            name: 'Общий доход Д1+Д2 по всем облигациям',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        actual_future_total_income: {
            name: 'СТАРЫЕ. Общий доход. Д1+Д2, если держим до погашения (с текущей даты)',
            helper: '',
        },
        /*
        total_income: {
            name: 'ПУ после замены облигаций',
            helper: '',
        },
        */
        actual_sale_sum: {
            name: 'СТАРЫЕ. Общая сумма продаж с тек. НКД',
            helper: '',
        },
        future_buy_sum: {
            name: 'НОВЫЕ. Общая сумма покупок с тек. НКД',
            helper: '',
        },
    },
    // Аналитика портфеля. План покупок стратегии - Метрика
    accountAnalyticResults: {
        actual_future_total_income: {
            name: 'СТАРЫЕ. Общий доход. Д1+Д2, если держим до погашения (с текущей даты)',
            helper: '',
        },
        future_total_income: {
            name: 'НОВЫЕ. Общий доход Д1+Д2 по всем НОВЫМ облигациям',
            helper: '',
        },
        actual_income: {
            name: 'СТАРЫЕ. Доход 2, если продать сегодня',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        actual_total_income: {
            name: 'СТАРЫЕ. Общий доход. Д1+Д2, если продать сегодня',
            helper: '',
        },
        /*
        total_income: {
            name: 'ПУ после замены облигаций',
            helper: '',
        },
        */
        actual_sale_sum: {
            name: 'СТАРЫЕ. Общая сумма продаж с тек. НКД',
            helper: '',
        },
        future_buy_sum: {
            name: 'НОВЫЕ. Общая сумма покупок с тек. НКД',
            helper: '',
        },
    },
    // Вотчлист - Метрика
    couponsFutureInfo: {
        coupons_future_coupon_income: {
            name: 'Доход 1. Купоны за 12 мес. вперед. ПЛАН',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        coupons_future_close_income: {
            name: 'Доход 2. ПЛАН при погашении облигации',
            helper: '',
        },
        coupons_future_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        coupons_future_amortization_amount: {
            name: 'Амортизации // Погашения',
            helper: '',
        },
        coupons_future_average_year_income_percent: {
            name: 'Доход 1. Ср. % год. ПЛАН',
            helper: '',
        },
    },
    // Вотчлист - Метрика
    watchlistInfo: {
        watchlist_coupon_income: {
            name: 'Доход 1. ПЛАН. Купоны / Амортизация',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
            //hideDefault: true,
        },
        watchlist_average_year_income_percent: {
            name: 'Доход 1. ПЛАН. Средний % год',
            helper: '',
        },
        watchlist_close_income: {
            name: 'Доход 2. ПЛАН',
            helper: '',
        },
        watchlist_purchase_amount: {
            name: 'Сумма покупки',
            helper: '',
        },
        watchlist_repayment_sum: {
            name: 'Сумма при погашении',
            helper: '',
        },
        watchlist_average_ytm_percent: {
            name: 'Доход к погашению (YTM), %',
            helper: '',
        },
        watchlist_resident_tax_amount: {
            name: 'Налог с купонов на конец года',
            helper: '',
        },
    }
};

export {
    blocksNames,
};