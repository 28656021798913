<template>
    <div class="account__history">
        <div class="filters account__history-filters form mb-4 d-flex flex-wrap">
            <b-form-group
                id="input-group-period"
                label="Период"
                class="col m--date-range me-3"
            >
                <b-input-group
                    class="m--with-icon"
                >
                    <template #append>
                        <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                    </template>
                    <VueDatePicker
                        ref="dateRange"
                        v-model="dateRange"
                        model-type="yyyy-MM-dd"
                        format="dd.MM.yyyy"
                        locale="ru"
                        select-text="Выбрать"
                        cancel-text="Отменить"
                        range
                        :auto-apply="true"
                        :close-on-auto-apply="false"
                        :year-range="[2000, 2050]"
                        :min-date="portfolio.first_deal_date || new Date()"
                        :max-date="new Date()"
                        :prevent-min-max-navigation="true"
                        text-input
                        :enable-time-picker="false"
                        :month-change-on-scroll="true"
                        :clearable="false"
                        :hide-input-icon="false"
                        allow-prevent-default
                    >
                        <template #dp-input="{ value, onInput, onEnter, onTab }">
                            <input
                                v-maska
                                data-maska="##.##.####  -  ##.##.####"
                                data-maska-eager
                                class="form-control border-transparent shadow m--no-pill"
                                type="text"
                                :value="value"
                                @keyup="onInput"
                                @keydown.enter="onEnter"
                                @keydown.tab="onTab"
                            />
                        </template>
                    </VueDatePicker>
                </b-input-group>
            </b-form-group>
            <div class="mb-3 me-3 mt-auto">
                <b-card
                    class="filters__item shadow"
                >
                    <b-input-group>
                        <b-form-input
                            v-model="tableDataFilters.ticker_isin"
                            placeholder="Название, тикер, ISIN"
                            size="sm"
                            class="border-transparent py-0"
                        >
                        </b-form-input>
                        <b-input-group-append>
                            <a
                                v-if="tableDataFilters.ticker_isin"
                                href="#"
                                class="btn-icon"
                                @click.prevent="clearInputQ"
                            >
                                <b-icon-x-lg />
                            </a>
                            <div
                                v-else
                                class="btn-icon"
                            >
                                <b-icon-search />
                            </div>
                        </b-input-group-append>
                    </b-input-group>
                </b-card>
            </div>
            <!--div class="mb-3 me-3 mt-auto me-auto">
                <b-card
                    class="filters__item shadow"
                >
                    <b-form-checkbox variant="success" v-model="tableDataFilters.current_assets" name="check-button" switch>
                        Текущие активы на БС
                    </b-form-checkbox>
                </b-card>
            </div-->
        </div>
        <div class="account__filters d-flex mb-4">
            <div class="d-flex flex-wrap">
                <BlockFilterTableRows
                    title="Фильтр по операциям"
                    classButton="filters__btn my-2"
                    :tableConfig="tableConfig"
                    :accountId="portfolioId"
                    :tableConfigName="tableConfigName"
                    :tableConfigCurrent="tableConfigCurrent"
                    @changeConfigTable="changeConfigTable"
                />
                <template
                    v-for="item in dataFilters"
                    :key="item.name"
                >
                    <div
                        :id="`popover-block-${item.name}`"
                        class="py-2"
                    >
                        <b-button
                            :variant="(item.name === currentFilter) ? 'success' : 'light'"
                            class="text-nowrap me-3 py-2 shadow"
                            :class="(item.name === currentFilter) ? 'active' : ''"
                            :disabled="tableConfigCurrent.indexOf(item.name) !== -1 || item.name === 'all' ? false : true"
                            pill
                            @click="changeFilter(item.name)"
                        >
                            {{ item.title }}
                        </b-button>
                        <b-popover
                            v-if="item.name !== 'all'"
                            :target="`popover-block-${item.name}`"
                            triggers="hover"
                            placement="top"
                        >
                            <div>
                                <template
                                    v-if="tableConfigCurrent.indexOf(item.name) !== -1"
                                >
                                    <strong>Типы операций:</strong>
                                    <ul
                                        class="ps-3"
                                    >
                                        <template
                                            v-for="conf in tableConfig.filter(group => { return group.name === item.name })[0].items"
                                            :key="`group-${conf.name}`"
                                        >
                                            <li
                                                v-if="tableConfigCurrent.indexOf(`${item.name}-${conf.name}`) !== -1"
                                            >
                                                {{ conf.title }}
                                            </li>
                                        </template>
                                    </ul>
                                </template>
                                <template
                                    v-else
                                >
                                    Используйте настройки фильтра по операциям для активации фильтра <strong>{{ item.title }}</strong>
                                </template>
                            </div>
                        </b-popover>
                    </div>
                </template>
            </div>
            <BlockFilterTable
                classButton="ms-auto my-2"
                :tableHeader="tableHeader"
                :accountId="portfolioId"
                :tableHeaderName="tableHeaderName"
                :tableFilters="tableFilters"
                :minCols="2"
                @changeFilterTable="changeFilterTable"
            />
        </div>
        <b-card
            :class="['account__history-table col-12 shadow mb-5', showLoaderSending ? 'py-4' : '']"
            bodyClass="p-1"
        >
            <b-table
                :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                :items="prepareTableData()"
                sticky-header
                responsive
                hover
                class="table__data b-table-sticky-header m--table-account-history"
            >
                <template #head()="data">
                    <TableHeaderSlot
                        :data="data"
                        :currentSorting="currentSorting"
                        @changeSorting="changeSorting"
                    />
                </template>
                <template #cell(date)="data">
                    <div class="small">
                        {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}<br>
                        {{ $helpers.formatDate(new Date(data.value), 'HH:mm') !== '00:00' ? $helpers.formatDate(new Date(data.value), 'HH:mm') : '' }}
                    </div>
                </template>
                <template #cell(currency)="data">
                    {{ data.value ? data.value.toUpperCase() : '' }}
                </template>
                <template #cell(instrument_name)="data">
                    <div class="small">
                        {{ data.value ? data.value : '—' }}
                    </div>
                </template>
                <template #cell(instrument_type)="data">
                    <div class="small">
                        {{ data.item.instrument_type_detail }}
                        <template v-if="$store.state.mode === 'all'">
                            <br>{{ data.item.instrument_type }}
                        </template>
                    </div>
                </template>
                <template #cell(operation_type)="data">
                    <div class="small">
                        {{ data.item.operation_type_detail }}
                        <template v-if="$store.state.mode === 'all'">
                            <br>{{ data.item.operation_type }}
                        </template>
                    </div>
                </template>
                <template #cell(comment)="data">
                    <div class="small text-muted">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(ticker_isin)="data">
                    <template v-if="data.item.instrument_ticker || data.item.instrument_isin">
                        <router-link
                            v-if="data.item.instrument_slug"
                            :to="{ name: `${portfolioType}-assets`, params: { ticker: data.item.instrument_slug } }"
                            class="d-block small text-muted"
                        >
                            {{ data.item.instrument_ticker }}<br>
                            {{ data.item.instrument_isin }}
                        </router-link>
                        <template v-else>
                            <div class="small text-muted">
                                {{ data.item.instrument_ticker }}<br>
                                {{ data.item.instrument_isin }}
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="small text-muted">
                            —
                        </div>
                    </template>
                </template>
                <template #cell(quantity)="data">
                    <div class="text-end text-nowrap">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(aci)="data">
                    <div class="text-end text-nowrap">
                        {{ (data.value) ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                    </div>
                </template>
                <template #cell(commission)="data">
                    <div class="text-end text-nowrap">
                        {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
                <template #cell(price)="data">
                    <div class="text-end text-nowrap">
                        {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
                <template #cell(price_commission)="data">
                    <div class="text-end text-nowrap">
                        {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
                <template #cell(payment_sum_commission)="data">
                    <div :class="[data.item.payment_sum_commission_color, 'text-end text-nowrap']">
                        {{ data.value !== null ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                    </div>
                </template>
                <template #cell(payment_sum)="data">
                    <div :class="['text-end text-nowrap', data.item.payment_sum_color]">
                        {{ data.item.payment_sum_sign }}{{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                    </div>
                </template>
                <template #cell(action)="data">
                    <!-- v-if="(data.item.is_user_created && !this.portfolio.access) || this.portfolio?.access === 'edit'" -->
                    <BlockActionsDeal
                        v-if="accessActions"
                        :deal="data.item"
                        :portfolioType="portfolioType"
                        @getPortfolioDealsList="getPortfolioDealsList"
                    />
                </template>
            </b-table>
            <BlockNavigateTable
                :totalRows="currentTotalRows"
                :currentPage="currentPage"
                :currentPerPage="currentPerPage"
                @changeNavigateTable="changeNavigateTable"
            />
            <b-overlay
                :show="showLoaderSending"
                variant="transparent"
                no-wrap
                spinner-variant="success"
                z-index="1010"
            />
        </b-card>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import BlockActionsDeal from "@/components/block-actions-deal";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockFilterTableRows from "@/components/block-filter-table-rows";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    export default {
        name: 'accountHistory',
        components: {
            BlockActionsDeal,
            BlockFilterTable,
            BlockFilterTableRows,
            BlockNavigateTable,
            TableHeaderSlot,
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                /*
                masks: {
                    input: 'DD.MM.YYYY',
                },
                calendarConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                },
                */
                dateRange: [],
                /*
                dateRange: {
                    start: null,
                    end: null
                },
                */
                dataFilters: [{
                        name: 'all',
                        title: 'Все операции'
                    }, {
                        name: 'currency',
                        title: 'Денежные средства'
                    }, {
                        name: 'bond',
                        title: 'Облигации // купоны'
                    }, {
                        name: 'share',
                        title: 'Акции // дивиденды'
                    }, {
                        name: 'tax',
                        title: 'Налоги'
                    }, {
                        name: 'commission',
                        title: 'Комиссии'
                    }, {
                        name: 'other',
                        title: 'Возврат // прочее'
                }],
                instrumentType: {
                    bond: 'облигация',
                    share: 'акция',
                    currency: 'валюта',
                    etf: 'фонд',
                    futures: 'фьючерс',
                    tax: 'налог'
                },
                operationType: {
                    amortization: 'амортизация',
                    buy: 'покупка',
                    commission: 'комиссия',
                    coupon: 'купон',
                    deposit: 'пополнение',
                    dividend: 'дивиденды',
                    refund: 'возмещение',
                    repayment: 'погашение',
                    sale: 'продажа',
                    withdrawal: 'вывод',
                    withholding: 'удержание',
                    other_income: 'прочий доход',
                    other_expense: 'прочий расход',
                    currency_sale: 'продажа валюты',
                    currency_buy: 'покупка валюты',
                    none: 'не определена'
                },
                tableConfigName: 'historyConfig',
                tableConfig: fieldNames.historyConfig,
                tableConfigCurrent: [],
                tableFilters: [],
                tableDataFilters: {
                    ticker_isin: null,
                    current_assets: false,
                },
                tableHeaderName: 'historyItem',
                tableHeader: fieldNames.historyItem,
                currentFilter: this.$route.query?.dataFiltersProps || 'all',
                currentSorting: {
                    key: 'date',
                    direction: 'desc'
                },
                history: [],
                currentPerPage: 50,
                currentPage: 1,
                showLoaderSending: false,
            };
        },
        computed: {
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
            accessActions() {
                const rules = this.$store.state.user?.tariff?.rules || {};
                if (!this.portfolio.access || this.portfolio.access === 'edit') {
                    if (rules && rules.deal_add) {
                        if (rules.deal_add.limit === 0 || rules.deal_add.limit >= this.portfolio.ordering) return true;
                        return false;
                    }
                    return false;
                }
                return false;
            }
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);

            //const now = new Date();
            //const firstDay = new Date(now.getFullYear() - 1, now.getMonth(), 1);
            const firstDay = new Date(this.portfolio.first_deal_date || new Date());
            //const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            const lastDay = new Date();
            this.dateRange = [
                this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                this.$helpers.formatDate(lastDay, 'YYYY-MM-DD')
            ];
            /*
            this.$nextTick(() => {
                this.$refs.dateRange.selectDate();
                this.$refs.dateRange.CloseMenu();
            });
            */

            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.accounts?.[this.portfolioId]?.[this.tableHeaderName]) {
                    if (this.userSettings.accounts[this.portfolioId][this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            this.tableConfig.forEach(group => {

                if (this.userSettings.accounts?.[this.portfolioId]?.[this.tableConfigName]) {
                    if (!group.disabled) {
                        if (this.userSettings.accounts[this.portfolioId][this.tableConfigName].indexOf(group.name) !== -1) this.tableConfigCurrent.push(group.name);
                    }
                    if (group.items && !group.disabled) {
                        group.items.forEach(item => {
                            if (!item.disabled) {
                                if (this.userSettings.accounts[this.portfolioId][this.tableConfigName].indexOf(`${group.name}-${item.name}`) !== -1) this.tableConfigCurrent.push(`${group.name}-${item.name}`);
                                if (item.alias) {
                                    item.alias.forEach(alias => {
                                        if (this.userSettings.accounts[this.portfolioId][this.tableConfigName].indexOf(`${group.name}-${alias}`) !== -1) this.tableConfigCurrent.push(`${group.name}-${alias}`);
                                    });
                                }
                            }
                        });
                    }
                } else if (this.userSettings.tableConfig?.[this.tableConfigName]) {
                    if (!group.disabled) {
                        if (this.userSettings.tableConfig[this.tableConfigName].indexOf(group.name) !== -1) this.tableConfigCurrent.push(group.name);
                    }
                    if (group.items && !group.disabled) {
                        group.items.forEach(item => {
                            if (!item.disabled) {
                                if (this.userSettings.tableConfig[this.tableConfigName].indexOf(`${group.name}-${item.name}`) !== -1) this.tableConfigCurrent.push(`${group.name}-${item.name}`);
                                if (item.alias) {
                                    item.alias.forEach(alias => {
                                        if (this.userSettings.tableConfig[this.tableConfigName].indexOf(`${group.name}-${alias}`) !== -1) this.tableConfigCurrent.push(`${group.name}-${alias}`);
                                    });
                                }
                            }
                        });
                    }
                } else {
                    if (!group.disabled) {
                        this.tableConfigCurrent.push(group.name);
                    }
                    if (group.items && !group.disabled) {
                        group.items.forEach(item => {
                            if (!item.disabled) {
                                this.tableConfigCurrent.push(`${group.name}-${item.name}`);
                                if (item.alias) {
                                    item.alias.forEach(alias => {
                                        this.tableConfigCurrent.push(`${group.name}-${alias}`);
                                    });
                                }
                            }
                        });
                    }
                }
            });
            this.getPortfolioDealsList();
        },
        methods: {
            getPortfolioDealsList() {
                this.showLoaderSending = true;
                app.getPortfolioDealsList(this.portfolioId, this.portfolioType).then(res => {
                    if (!res.error) {
                        /* Принудительно убираю записи с коммиссией */
                        /*
                        res = res.filter(item => {
                            if (item.operation_type === 'commission') {
                                return false;
                            }
                            return true;
                        });
                        */

                        res = res.map(item => {
                            /* Теперь считаем на беке. Параметр - aci
                            if (item.instrument_type === 'bond' && (item.operation_type === 'buy' || item.operation_type === 'sale')) {
                                //item.nkd = Math.abs(item.payment_sum_no_commission) - Math.abs(item.price * item.quantity);
                                if (item.operation_type === 'buy') {
                                    item.nkd = Math.abs(item.payment_sum) - Math.abs(item.payment_sum_commission);
                                } else {
                                    item.nkd = Math.abs(item.payment_sum) - Math.abs(item.price * item.quantity);
                                }
                                item.nkd = Math.abs(item.nkd);
                            } else {
                                item.nkd = null;
                            }
                            */
                            item.commission = Math.abs(item.commission);
                            item.payment_sum_commission = Math.abs(item.payment_sum_commission);
                            item.payment_sum = Math.abs(item.payment_sum);
                            //item.payment_sum = item.payment_sum_no_commission + item.commission;
                            if (['commission', 'withdrawal', 'withholding'].indexOf(item.operation_type) !== -1) {
                                item.payment_sum_commission = -1 * item.payment_sum_commission;
                                item.payment_sum = -1 * item.payment_sum;
                                item.payment_sum_color = 'text-danger';
                            } else if (['coupon', 'deposit', 'dividend', 'refund'].indexOf(item.operation_type) !== -1) {
                                //item.payment_sum_commission_sign = '+';
                                item.payment_sum_sign = '+';
                                item.payment_sum_color = 'text-success';
                            }

                            if (Math.abs(item.payment_sum_commission) === Math.abs(item.payment_sum)) {
                                item.payment_sum_commission = null;
                            }
                            /*
                            if (['commission', 'tax', 'other'].indexOf(item.instrument_type) !== -1) {
                                item.payment_sum_no_commission = null;
                            }
                            if (item.instrument_type === 'currency' && ['deposit', 'withdrawal'].indexOf(item.operation_type) !== -1) {
                                item.payment_sum_no_commission = null;
                            }
                            */
                            return item;
                        });
                        this.history = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.history];
                if (list.length) {
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(list) {
                //let list = [...data];
                //console.log('History data:', list);
                list = list.filter(item => {
                    if (this.$store.state.mode === 'all') {
                        delete item._rowVariant;
                    }
                    if (this.currentFilter === 'all') {
                        // На кнопку - "Все операции" вешаем фильтрацию в зависимости от значений конфигуратора
                        if (this.tableConfigCurrent.indexOf(item.instrument_type) !== -1) {
                            if (this.tableConfigCurrent.indexOf(`${item.instrument_type}-${item.operation_type}`) !== -1) {
                                return true;
                            }
                        } else if (!item.instrument_type) {
                            return true;
                        }
                        if (this.$store.state.mode === 'all') {
                            item._rowVariant = 'danger';
                            return true;
                        }
                    } else {
                        if (this.currentFilter === item.instrument_type) {
                            if (this.tableConfigCurrent.indexOf(`${this.currentFilter}-${item.operation_type}`) !== -1) {
                                return true;
                            }
                        } else if (this.currentFilter === 'other' && !item.instrument_type) {
                            return true;
                        }
                    }
                    return false;
                });

                list = list.filter(item => {
                    if (this.tableDataFilters.ticker_isin) {
                        let value = this.tableDataFilters.ticker_isin.toLowerCase();
                        if ((item.instrument_ticker && item.instrument_ticker.toLowerCase().indexOf(value) !== -1)
                            || (item.instrument_isin && item.instrument_isin.toLowerCase().indexOf(value) !== -1)
                            || (item.instrument_name && item.instrument_name.toLowerCase().indexOf(value) !== -1)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });

                //const firstDay = new Date(this.dateRange.start + 'T00:00:00');
                //const lastDay = new Date(this.dateRange.end + 'T00:00:00');
                const firstDay = new Date(this.dateRange[0] + 'T00:00:00');
                const lastDay = new Date(this.dateRange[1] + 'T00:00:00');
                lastDay.setDate(lastDay.getDate() + 1);
                list = list.filter(item => {
                    if (new Date(item.date) >= firstDay && new Date(item.date) < lastDay) {
                        return true;
                    }
                    return false;
                });
                return list;
            },
            clearInputQ() {
                this.tableDataFilters.ticker_isin = null;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilter(name) {
                this.currentFilter = name;
                this.currentPage = 1;
            },
            changeConfigTable(filter) {
                this.tableConfigCurrent = filter;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            setDataRange() {
                const now = new Date();
                let list = [...this.history];
                const firstDay = this.portfolio.first_deal_date ? new Date(this.portfolio.first_deal_date) : list.length ? new Date(list.reduce((prev, curr) => prev.date < curr.date ? prev : curr).date) : new Date(now.getFullYear(), now.getMonth(), now.getDate());
                const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                //this.dateRange = {
                //    start: this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                //    end:  this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
                //};

                this.dateRange = [
                    this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                    this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
                ];
            }
        }
    };
</script>
