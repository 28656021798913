<template>
    <div v-if="$route.name === 'account-analytics'" class="account__analytic strategies">
        <template v-if="!portfolio.hidden">
            <div class="strategies__buttons d-flex pt-4 mb-4">
                <div class="d-flex flex-wrap">
                    <b-button
                        :variant="'light'"
                        class="strategies__buttons-item me-3 mb-2 shadow"
                        size=""
                        pill
                        @click.prevent="showAddStrategy"
                    >
                        Добавить новый расчет
                        <b-icon-plus-lg class="ms-2" />
                    </b-button>
                </div>
            </div>
            <template v-if="!showLoaderSending">
                <div class="strategies__block row">
                    <div
                        v-for="(item, index) in lists"
                        :key="`list-${index}`"
                        class="col-12 col-xl-6 col-xxl-4 mb-4"
                    >
                        <b-card
                            class="strategies__block-item"
                            @click.prevent=""
                        >
                            <div class="strategies__block-item-top">
                                Название списка
                                <div class="strategies__block-item-currency badge rounded-pill ms-auto mb-auto">{{ item.currency_iso_name }}, {{ item.currency_symbol }}</div>
                            </div>
                            <router-link
                                :to="{ name: 'account-analytic', params: { strategyId: item.id } }"
                                class="strategies__block-item-title mb-1"
                            >
                                «{{ item.name }}»
                            </router-link>
                            <div class="strategies__block-item-param">
                                <div class="strategies__block-item-param-name">Сумма от продажи</div>
                                <div class="strategies__block-item-param-value">
                                    {{ item.actual_sale_sum ? $helpers.toPrice(item.actual_sale_sum) : '—' }}
                                </div>
                            </div>
                            <div class="strategies__block-item-param">
                                <div class="strategies__block-item-param-name">Д2, если продавать сегодня</div>
                                <div class="strategies__block-item-param-value">
                                    {{ item.actual_income ? $helpers.toPrice(item.actual_income) : '—' }}
                                </div>
                            </div>
                            <div class="strategies__block-item-param">
                                <div class="strategies__block-item-param-name">Сумма Д1+Д2 после всех продаж по всем продаваемым облигациям</div>
                                <div class="strategies__block-item-param-value">
                                    {{ item.actual_total_income ? $helpers.toPrice(item.actual_total_income) : '—' }}
                                </div>
                            </div>
                            <div class="strategies__block-item-param">
                                <div class="strategies__block-item-param-name">Сумма новых покупок</div>
                                <div class="strategies__block-item-param-value">
                                    {{ item.future_buy_sum ? $helpers.toPrice(item.future_buy_sum) : '—' }}
                                </div>
                            </div>
                            <div class="strategies__block-item-param">
                                <div class="strategies__block-item-param-name">Общий доход (Д1 + Д2) до погашения по новым покупкам</div>
                                <div class="strategies__block-item-param-value">
                                    {{ item.future_total_income ? $helpers.toPrice(item.future_total_income) : '—' }}
                                </div>
                            </div>
                            <div class="strategies__block-item-bottom mt-auto">
                                <div class="strategies__block-item-date">Изменен {{ $helpers.formatDate(new Date(item.updated), 'DD.MM.YYYY') }}</div>
                                <BlockActionsStrategy
                                    :portfolioId="portfolio.id"
                                    :strategy="item"
                                    @getPortfolioStrategies="getPortfolioStrategies"
                                />
                            </div>
                        </b-card>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="app__loader">
                    <b-spinner
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
            <routerView
                :portfolioId="portfolioId"
                :portfolio="portfolio"
                :currentFilter="currentFilter"
                :portfolioType="portfolioType"
                :routeSuffix="routeSuffix"
                @refreshPortfolio="refreshPortfolio"
                @refreshPortfolioAll="refreshPortfolioAll"
            />
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
        <ModalStrategyAdd
            v-if="showModalAddStrategy"
            :portfolioId="portfolio.id"
            :show="showModalAddStrategy"
            @hideModal="hideAddStrategy"
        />
        <ModalPage
            :slug="ModalPageSlug"
            :show="showModalPage"
            @hideModal="hidePage"
        />
    </div>
    <RouterView v-else />
</template>

<script>
    import { app } from "@/services";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import BlockActionsStrategy from "@/components/block-actions-strategy";
    import ModalStrategyAdd from "@/components/modals/modal-strategy-add";
    import ModalPage from "@/components/modals/modal-page";
    export default {
        name: 'accountAnalitics',
        emits: ['refreshPortfolioAll'],
        components: {
            BlockTariffLimited,
            BlockActionsStrategy,
            ModalStrategyAdd,
            ModalPage
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
            currentFilter: {
                type: Boolean,
                default() { return false; }
            },
            routeSuffix: {
                type: String,
                default() { return ''; }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        watch: {
            'currencyCurrent': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (oldVal === undefined) return;
                    this.getPortfolio();
                }
            },
        },
        data() {
            return {
                lists: [],
                currentPerPage: 50,
                currentPage: 1,
                ModalPageSlug: null,
                showModalPage: false,
                showModalAddStrategy: false,
                showLoaderSending: false
            };
        },
        created() {
        },
        mounted() {
            this.$breadcrumbs.value.at(-2).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            this.getPortfolioStrategies();
        },
        methods: {
            getPortfolioStrategies() {
                let params = {
                    id: this.portfolioId,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                };
                this.showLoaderSending = true;
                app.getPortfolioStrategies(params).then(res => {
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        this.lists = res.results;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            refreshPortfolio() {
                this.$emit('refreshPortfolio');
            },
            refreshPortfolioAll() {
                this.$emit('refreshPortfolioAll');
            },
            showAddStrategy() {
                this.showModalAddStrategy = true;
            },
            hideAddStrategy(refreshData = false) {
                console.log('hideAddStrategy', refreshData);
                this.showModalAddStrategy = false;
                if (refreshData) {
                    console.log('refreshData');
                    this.getPortfolioStrategies();
                }
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            changeTabMenuItem(item) {
                this.currentTabMenuItem = item;
                if (item.name) this.next(item.name);
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
