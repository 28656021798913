<template>
    <div class="app__block bond-screener d-flex h-100 flex-column px-4 pt-3 pb-4">
        <BlockUserCurrencies/>

        <template v-if="rules.bond_screener">
            <template v-if="$route.name === 'bond-screener'">
                <div class="app__block-title m--help">
                    <h1 class="mb-5">{{ $route.meta.title }}</h1>
                    <router-link
                        :to="{ name: 'page', params: { slug: 'help_screener' }}"
                        custom
                        v-slot="{ href }"
                    >
                        <a
                            :href="href"
                            class="app__block-help"
                            @click.prevent="showPage('help_screener')"
                        >
                            <b-icon-question-circle-fill />
                        </a>
                    </router-link>
                </div>
                <div class="filters bond-screener__filters d-flex">
                    <div class="row mb-4 me-1 w-100">
                        <div class="col-4 mb-3">
                            <b-card
                                class="filters__item shadow"
                            >
                                <b-input-group>
                                    <b-form-input
                                        v-model="tableDataFilters.q"
                                        placeholder="Поиск по тикеру"
                                        size="sm"
                                        class="border-transparent py-0"
                                        @keyup.enter="changeFilterTableDataSimple"
                                    >
                                    </b-form-input>
                                    <b-input-group-append>
                                        <a
                                            v-if="tableDataFilters.q"
                                            href="#"
                                            class="btn-icon"
                                            @click.prevent="clearInputQ"
                                        >
                                            <b-icon-x-lg />
                                        </a>
                                        <div
                                            v-else
                                            class="btn-icon"
                                        >
                                            <b-icon-search />
                                        </div>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-card>
                        </div>
                        <b-button
                            variant="primary"
                            class="filters__item col-auto me-3 mb-3 shadow m--mw-120"
                            size=""
                            pill
                            @click.prevent="changeFilterTableDataSimple"
                        >
                            Искать
                        </b-button>
                        <!--b-button
                            variant="light"
                            class="filters__item col-auto me-3 mb-3 shadow"
                            size=""
                            pill
                            @click.prevent="toggleShowFilters"
                        >
                            {{ showFilters ? 'Скрыть параметры поиска' : 'Показать параметры поиска' }}
                        </b-button-->
                        <b-button
                            variant="primary"
                            :class="['filters__item col-auto ms-auto me-2 mb-3']"
                            :disabled="showLoaderExport"
                            size=""
                            pill
                            @click.prevent="exportBondListXLS"
                        >
                            <template
                                v-if="showLoaderExport"
                            >
                                <b-spinner
                                    class="me-3"
                                    small
                                />
                                <span>Генерация ...</span>
                            </template>
                            <template
                                v-else
                            >
                                Выгрузить в Excel
                            </template>
                        </b-button>
                        <a ref="download" href="#" download />
                    </div>
                    <BlockFilterTable
                        :tableHeader="tableHeader"
                        :tableHeaderName="tableHeaderName"
                        :tableFilters="tableFilters"
                        :minCols="2"
                        @changeFilterTable="changeFilterTable"
                    />
                </div>
                <BlockFilterBondScreener
                    :currentFilterKey="userSettings.filters?.bondScreener?.current"
                    :tableDataFilters="tableDataFilters"
                    :showExtendedFilters="showFilters"
                    :useFilters="useFilters"
                    @changeFilterTableData="changeFilterTableData"
                    @clearFilterTableData="clearFilterTableData"
                    @changeShowFilters="changeShowFilters"
                />
                <b-card
                    :class="['bond-screener__table col-12 shadow mb-5', (showLoaderSending && !bonds.length) && 'py-4']"
                    bodyClass="p-1"
                >
                    <template v-if="currentTotalRows">
                        <b-table
                            :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                            :items="prepareTableData()"
                            sticky-header
                            responsive
                            hover
                            class="table__data b-table-sticky-header m--table-bond-screener"
                            @row-selected="onRowSelected"
                        >
                            <template #head()="data">
                                <TableHeaderSlot
                                    :data="data"
                                    :currentSorting="currentSorting"
                                    @changeSorting="changeSorting"
                                />
                            </template>
                            <template #cell(action)="data">
                                <BlockActionsBond
                                    :key="`bond-item-${data.item.id}`"
                                    :bond="data.item"
                                    :lists="lists"
                                    @getWatchLists="getWatchLists"
                                />
                            </template>

                            <template #cell(name)="data">
                                <div :class="this.$store.state.mode === 'blur' && 'm--blur'">
                                    <router-link
                                        v-if="data.item.slug"
                                        :to="{ name: 'bond', params: { slug: data.item.slug } }"
                                        class="d-block"
                                        target="_blank"
                                    >
                                        <strong>{{ data.item.name }}</strong>
                                        <br>
                                        <small class="text-muted">{{ data.item.ticker }}</small>
                                    </router-link>
                                    <template
                                        v-else
                                    >
                                        <strong>{{ data.item.name }}</strong>
                                        <br>
                                        <small class="text-muted">{{ data.item.ticker }}</small>
                                    </template>
                                </div>
                            </template>
                            <template #cell(currency)="data">
                                {{ data.item.currency_iso_name }}
                            </template>
                            <template #cell(nominal)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(price)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(price_percent)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(aci_value)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(coupon_amount)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(coupon_amount_tax)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value || data.value === 0 ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(coupon_frequency)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(year_income)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(close_income)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(total_income)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(yield_to_maturity)="data">
                                <div class="text-end text-nowrap">
                                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                                </div>
                            </template>
                            <template #cell(coupon_date)="data">
                                {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                            </template>
                            <template #cell(coupon_days_after)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(coupon_days_until_next)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(issue_date)="data">
                                {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                            </template>
                            <template #cell(maturity_date)="data">
                                {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                            </template>
                            <template #cell(maturity_years)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(valume_trading)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.item.value || data.item.value === 0 ? $helpers.toPrice(data.item.value) : '—' }}
                                </div>
                            </template>
                            <template #cell(duration)="data">
                                <div class="text-end text-nowrap">
                                    {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                                </div>
                            </template>
                            <template #cell(nominal_feature)="data">
                                <div class="small">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(coupon_feature)="data">
                                <div class="small">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell(minimum_coupon_rate)="data">
                                <div class="text-end text-nowrap">
                                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                                </div>
                            </template>
                            <template #cell(minimum_coupon_rate_price)="data">
                                <div class="text-end text-nowrap">
                                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                                </div>
                            </template>
                            <template #cell(spread_amount)="data">
                                <div class="text-end text-nowrap">
                                    {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                                </div>
                            </template>
                            <template #cell(security_type)="data">
                                <div class="small">
                                    {{ data.value }}
                                </div>
                            </template>

                            <template #cell(qualified)="data">
                                {{ data.value ? 'Да' : 'Нет' }}
                            </template>
                            <template #cell(offer_date)="data">
                                {{ data.value ? 'Да' : 'Нет' }}
                            </template>
                            <template #cell(rating)="data">
                                {{ data.value ||  '—' }}
                            </template>
                            <template #cell(has_default)="data">
                                {{ data.value !== 'not' ? data.item.has_default_detail : '—' }}
                            </template>
                            <template #cell(portfolio_shares)="data">
                                <div v-if="!data.item.portfolio_shares.length">
                                    —
                                </div>
                                <BlockPortfolioShares
                                    v-else
                                    :id="data.item.id"
                                    :shares="data.item.portfolio_shares"
                                    :sign="$store.state.currencyCurrent.symbol"
                                />
                            </template>
                            <template #cell(emitter_portfolio_shares)="data">
                                <div v-if="!data.item.emitter_portfolio_shares">
                                    —
                                </div>
                                <BlockEmitterPortfolioShares
                                    v-else
                                    :id="data.item.id"
                                    :inn="data.item.emitter_inn"
                                    :shares="data.item.emitter_portfolio_shares"
                                    :sign="$store.state.currencyCurrent.symbol"
                                    showEmitter
                                />
                            </template>
                        </b-table>
                        <BlockNavigateTable
                            :totalRows="currentTotalRows"
                            :currentPage="currentPage"
                            :currentPerPage="currentPerPage"
                            @changeNavigateTable="changeNavigateTable"
                        />
                    </template>
                    <template v-else-if="!showLoaderSending">
                        <b-alert
                            class="mb-0"
                            variant="warning"
                            :model-value="true"
                        >
                            По вашему запросу ничего не найдено. Измените параметра поиска.
                        </b-alert>
                    </template>
                    <b-overlay
                        :show="showLoaderSending"
                        variant="transparent"
                        no-wrap
                        spinner-variant="success"
                    />
                </b-card>
            </template>
            <routerView v-else />
            <ModalPage
                :slug="ModalPageSlug"
                :show="showModalPage"
                @hideModal="hidePage"
            />
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";
    import { fieldNames, defaultBondScreenerFilter } from "@/settings/tables";

    import BlockUserCurrencies from "@/components/block-user-currencies";
    import BlockActionsBond from "@/components/block-actions-bond";
    import BlockFilterBondScreener from "@/components/block-filter-bond-screener";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import BlockPortfolioShares from "@/components/block-portfolio-shares";
    import BlockEmitterPortfolioShares from "@/components/block-emitter-portfolio-shares";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import TableHeaderSlot from "@/components/table-header-slot";
    import ModalPage from "@/components/modals/modal-page";

    export default {
        name: 'bondScreener',
        components: {
            BlockUserCurrencies,
            BlockActionsBond,
            BlockFilterBondScreener,
            BlockFilterTable,
            BlockNavigateTable,
            BlockPortfolioShares,
            BlockEmitterPortfolioShares,
            BlockTariffLimited,
            TableHeaderSlot,
            ModalPage
        },
        props: {
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        data() {
            return {
                //dateRange: [],
                menu: [{
                    name: `bond-screener`,
                    title: 'Скринер',
                    icon: null,
                    show: true
                }, {
                    name: `bond-screener-lists`,
                    title: 'Мои списки',
                    icon: null,
                    show: true
                }],
                interfaceName: 'screener',
                currentFilter: false,
                currentSorting: {
                    key: 'isin',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: Object.assign({}, defaultBondScreenerFilter),
                tableHeaderName: 'bondScreenerItem',
                tableHeader: fieldNames.bondScreenerItem,
                //fields: [],
                bonds: [],
                lists: [],
                currentPerPage: 25,
                currentPage: 1,
                //currentTotalRows: 0,
                useFilters: false,
                useSimpleFilters: false,
                ModalPageSlug: null,
                showModalPage: false,
                showFilters: false,
                showModalAddBondGroup: false,
                showLoaderExport: false,
                showLoaderSending: false
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            });
            let settings = this.$store.state.user?.user_settings || {};
            let key = settings.filters?.bondScreener?.current;
            if (key) {
                this.tableDataFilters = Object.assign({}, settings.filters.bondScreener[key].filters);
            }
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1 || this.userSettings.tableHeader[this.tableHeaderName].indexOf(this.tableHeader[key].synonim) !== -1) this.tableFilters.push(key);
                } else {
                    if (!this.tableHeader[key].hideDefault) this.tableFilters.push(key);
                }
            });
            this.getBonds();
        },
        methods: {
            getWatchLists() {
                let params = {
                    instrument_type: 'bond',
                    currency: this.tableDataFilters.currency
                };
                this.showLoaderSending = true;
                app.getWatchLists(params).then(res => {
                    if (!res.error) {
                        this.lists = res;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            getBonds() {
                this.showLoaderSending = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                if (params.qualified === -1) delete params.qualified;
                if (params.has_offer === -1) delete params.has_offer;
                //if (params.currency_all) delete params.currency;
                //if (params.feature_all) delete params.feature;
                if (params.security_type_all) delete params.security_type;
                if (params.has_default_all) delete params.has_default;
                //params.currency?.length ? params.currency = JSON.stringify(params.currency) : delete params.currency;
                params.nominal_feature?.length ? params.nominal_feature = JSON.stringify(params.nominal_feature) : delete params.nominal_feature;
                params.coupon_feature?.length ? params.coupon_feature = JSON.stringify(params.coupon_feature) : delete params.coupon_feature;
                if (!params.floater_type?.length || params.floater_type?.indexOf('known') === -1) delete params.minimum_coupon_rate;
                if (!params.floater_type?.length || params.floater_type?.indexOf('spreading') === -1) {
                    delete params.spread_amount_min;
                    delete params.spread_amount_max;
                }
                params.floater_type?.length ? params.floater_type = JSON.stringify(params.floater_type) : delete params.floater_type;
                params.security_type?.length ? params.security_type = JSON.stringify(params.security_type) : delete params.security_type;
                params.has_default?.length ? params.has_default = JSON.stringify(params.has_default) : delete params.has_default;
                params.rating?.length ? params.rating = JSON.stringify(params.rating) : delete params.rating;
                if (this.useSimpleFilters) {
                    params = {
                        offset: (this.currentPage - 1) * this.currentPerPage,
                        limit: this.currentPerPage,
                        q: this.tableDataFilters.q,
                        ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                    };
                }
                app.getBondList(params).then(res => {
                    console.log(res);
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        /*
                        res.results = res.results.map(item => {
                            let obj = Object.assign({}, item.info, item.currency, item.actual_price, item.next_coupon, item);
                            delete obj.info;
                            delete obj.currency;
                            delete obj.actual_price;
                            delete obj.next_coupon;
                            return obj;
                        });
                        */
                        this.bonds = res.results;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    //this.showLoaderSending = false;
                    this.getWatchLists();
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.bonds];
                if (list.length) {
                    console.log('prepareTableData');
                    list.forEach((item, index) => {
                        item._index = index + 1;
                    });
                    /*
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? a[this.currentSorting.key] : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? b[this.currentSorting.key] : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                    */
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                console.log('Data:', list);
                list = list.filter(item => {
                    if (this.tableDataFilters.isin) {
                        if (item.ticker_isin.toLowerCase().indexOf(this.tableDataFilters.ticker_isin.toLowerCase()) !== -1 || item.ticker_name.toLowerCase().indexOf(this.tableDataFilters.ticker_isin.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                console.log('Data:', list);
                return list;
            },
            changeFilterTableData(data = null) {
                console.log('changeFilterTableData');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.useFilters = true;
                this.useSimpleFilters = false;
                this.getBonds();
            },
            changeFilterTableDataSimple(data = null) {
                console.log('changeFilterTableDataSimple');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.showFilters = false;
                this.useSimpleFilters = true;
                this.getBonds();
            },
            clearInputQ() {
                this.tableDataFilters.q = null;
            },
            clearFilterTableData() {
                this.tableDataFilters = Object.assign({}, defaultBondScreenerFilter);
                this.currentPage = 1;
                this.useFilters = false;
                this.getBonds();
            },
            changeShowFilters(show) {
                this.showFilters = show;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
                this.getBonds();
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
                //this.fields = this.$helpers.prepareTableFields(this.tableHeader, this.tableFilters);
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getBonds();
            },
            onRowSelected(item) {
                console.log(item);
            },
            /*
            toggleShowFilters() {
                this.showFilters = !this.showFilters;
            },
            */
            changeInterface(name) {
                this.interfaceName = name;
            },
            showAddBondGroup() {
                this.showModalAddBondGroup = true;
            },
            hideAddBondGroup(refreshData = false) {
                this.showModalAddBondGroup = false;
                if (refreshData) {
                    console.log('refreshData');
                }
            },
            exportBondListXLS() {
                this.showLoaderExport = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    //offset: (this.currentPage - 1) * this.currentPerPage,
                    //limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                if (params.qualified === -1) delete params.qualified;
                if (params.has_offer === -1) delete params.has_offer;
                //if (params.currency_all) delete params.currency;
                //if (params.feature_all) delete params.feature;
                if (params.security_type_all) delete params.security_type;
                if (params.has_default_all) delete params.has_default;
                //params.currency?.length ? params.currency = JSON.stringify(params.currency) : delete params.currency;
                params.nominal_feature?.length ? params.nominal_feature = JSON.stringify(params.nominal_feature) : delete params.nominal_feature;
                params.coupon_feature?.length ? params.coupon_feature = JSON.stringify(params.coupon_feature) : delete params.coupon_feature;
                if (!params.floater_type?.length || params.floater_type?.indexOf('known') === -1) delete params.minimum_coupon_rate;
                if (!params.floater_type?.length || params.floater_type?.indexOf('spreading') === -1) {
                    delete params.spread_amount_min;
                    delete params.spread_amount_max;
                }
                params.floater_type?.length ? params.floater_type = JSON.stringify(params.floater_type) : delete params.floater_type;
                params.security_type?.length ? params.security_type = JSON.stringify(params.security_type) : delete params.security_type;
                params.has_default?.length ? params.has_default = JSON.stringify(params.has_default) : delete params.has_default;
                params.rating?.length ? params.rating = JSON.stringify(params.rating) : delete params.rating;
                app.exportBondListXLS(params).then(res => {
                    //console.log(res.headers['content-disposition'].split('filename=')[1]);
                    let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' });
                    //let link = document.createElement('a');
                    this.$refs.download.href = window.URL.createObjectURL(blob);
                    this.$refs.download.download = decodeURI(res.headers['content-disposition'].split('filename=')[1]);
                    this.$refs.download.click();
                    //let url = window.URL.createObjectURL(blob);
                    //window.open(url);
                    /*
                    if (!res.error) {
                        console.log(res);
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    */
                    this.showLoaderExport = false;
                }).catch(err => {
                    this.showLoaderExport = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            showPage(slug) {
                this.ModalPageSlug = slug;
                this.showModalPage = true;
            },
            hidePage() {
                this.showModalPage = false;
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
