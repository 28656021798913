<template>
    <div 
        class="app__block d-flex h-100 flex-column px-4 pt-3 pb-4"
        :class="[$route.name === 'public-accounts' ? 'accounts' : 'account', $route.meta.requiresAuth ? '' : 'mx-auto']"
    >
        <BlockUserCurrencies/>
        <template
            v-if="$route.name === 'public-accounts' || $route.name === 'publics-accounts'"
        >
            <div class="app__block-title">
                <h1 class="mb-5">Публичные брокерские счета</h1>
            </div>
            <div class="accounts__filters filters mb-4">
                <div class="mb-3 me-3 mt-auto">
                    <b-card
                        class="filters__item shadow"
                        bodyClass="py-1"
                    >
                        <b-input-group>
                            <b-form-input
                                v-model="tableDataFilters.query"
                                placeholder="Поиск по имени инвестора, названию брокерского счета"
                                size="sm"
                                class="border-transparent py-0"
                            >
                            </b-form-input>
                            <b-input-group-append>
                                <div
                                    class="btn-icon"
                                >
                                    <b-icon-search></b-icon-search>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </b-card>
                </div>
            </div>
            <template
                v-if="!showLoaderSending"
            >
                <div class="public__list row">
                    <div
                        v-for="portfolio in portfolioList"
                        :key="`portfolio-${portfolio.public_url}`"
                        class="col-12 col-xl-6 col-xxl-4 mb-3"
                    >
                        <b-card
                            class="public__item h-100 shadow"
                            bodyClass="public__item-inner"
                            @click.prevent="$router.push({ name: $route.meta.requiresAuth ? 'public' : 'publics', params: { portfolioId: portfolio.public_url } })"
                        >
                            <div class="public__item-top">
                                <div class="public__item-top-info">
                                    <div class="public__item-top-name">
                                        {{ portfolio.user_public_name }}
                                    </div>
                                    <div class="h4 public__item-top-portfolio">
                                        {{ portfolio.trust_name }}
                                    </div>
                                </div>
                                <div class="public__item-top-broker">
                                    <img :src="portfolio.broker_logo" alt="">
                                </div>
                            </div>
                            <div class="public__item-comment">
                                {{ portfolio.trust_comment }}
                            </div>
                            <div class="public__item-bottom mt-auto">
                                <div class="public__item-summary row">
                                    <div class="col-12">
                                        <div class="text-muted mb-1">Текущая оценка брокерского счета</div>
                                        <div class="h5">
                                            {{ $helpers.toPrice(portfolio.summary?.portfolio_aci_sum || 0, { sign: currencyCurrent.symbol, pointer: ',' }) }}
                                        </div>
                                    </div>
                                    <!--div class="col-5">
                                        <div class="text-muted mb-1">Стоимость</div>
                                        <div class="h5">
                                            {{ $helpers.toPrice(portfolio.summary?.payment_sum || 0, { sign: currencyCurrent.symbol, pointer: ',' }) }}
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="text-muted mb-1">Прибыль</div>
                                        <div :class="['h5', portfolio.summary?.income >= 0 ? 'text-success' : 'text-danger']">
                                            {{ portfolio.summary?.income >= 0 ? '+' : '' }}{{ $helpers.toPrice(portfolio.summary?.income || 0, { sign: currencyCurrent.symbol, pointer: ',' }) }}
                                            <span>·</span>
                                            {{ $helpers.toPrice(portfolio.summary?.income_percent || 0, { sign: '%', pointer: ',' }) }}
                                        </div>
                                    </div-->
                                    <div class="public__item-date pt-2">
                                        Последняя сделка: {{ portfolio.last_deals ? $helpers.formatDate(new Date(portfolio.last_deals), 'DD.MM.YYYY') : '—' }}
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
            </template>
            <template
                v-else
            >
                <div class="app__loader">
                    <b-spinner 
                        variant="success"
                        class="me-3"
                    />
                    <span class="text-success">Загрузка данных ...</span>
                </div>
            </template>
        </template>
        <template
            v-else
        >
            <routerView />
        </template>
    </div>
</template>

<script>
    import { app } from "@/services";
    import BlockUserCurrencies from "@/components/block-user-currencies";
    //import BlockNavigateTable from "@/components/block-navigate-table";
    export default {
        name: 'trustManagment',
        components: {
            BlockUserCurrencies,
            //BlockNavigateTable,
        },
        watch: {
            'currencyCurrent': {
                immediate: true,
                handler() {
                    this.getPortfolioList();
                }
            },
        },
        computed: {
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
        },
        data() {
            return {
                portfolioType: 'public',
                tableFilters: [],
                tableDataFilters: {
                    query: null,
                },
                currentSorting: {
                    key: 'owner',
                    direction: 'asc'
                },
                portfolioList: [],
                currentPerPage: 50,
                currentPage: 1,
                showLoaderSending: false
            };
        },
        created() {
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
            this.getPortfolioList();
        },
        mounted() {
            if (this.$route.name === 'publics-accounts') {
                this.$breadcrumbs.value.unshift({
                    label: 'Главная',
                    link: '/'
                });
            }

        },
        methods: {
            getPortfolioList() {
                let params = {};
                this.showLoaderSending = true;
                app.getPortfolioList(params, this.portfolioType, false, false).then(res => {
                    if (!res.error) {
                        this.portfolioList = res.detail;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.portfolios];
                if (list.length) {
                    //console.log('prepareTableData', this.currentFilter);
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                console.log('Actvives data:', list);
                list = list.filter(item => {
                    if (this.tableDataFilters.query) {
                        if (item.user_public_name.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1 || 
                            item.user_id.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1 ||
                            item.trust_name.toLowerCase().indexOf(this.tableDataFilters.query.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            next(name) {
                this.$router.push({ name: name });
            }
        }
    };
</script>
